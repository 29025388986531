import { Container } from "inversify";
import "reflect-metadata";
import { IMAGE_PORT, ImagePort } from "../domain/port/inbound/ImagePort";
import { ImageAdapter } from "../infraestructure/adapters/ImageAdapter";
import { LOGIN_PORT, LoginPort } from "../domain/port/inbound/LoginPort";
import { LoginAdapter } from "../infraestructure/adapters/LoginAdapter";
import { GTM_PORT, GTMPort } from "../domain/port/inbound/GTMPort";
import { GTMAdapter } from "../infraestructure/adapters/GTMAdapter";

let container = new Container();

//Port to Image
const bindPorts = () => {
  container.bind<ImagePort>(IMAGE_PORT).to(ImageAdapter);
  container.bind<LoginPort>(LOGIN_PORT).to(LoginAdapter);
  container.bind<GTMPort>(GTM_PORT).to(GTMAdapter);
};

bindPorts();

export { container };
