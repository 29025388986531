import remove from "lodash/remove";
import { DEVICES } from "./v2/Data";

export const ITEMS = [
  {
    id: 1,
    icon: "ic_feature_1.svg",
    items: ["app.Landing.Banner.Instructions.Image"],
    title: "app.Landing.Banner.Instructions.Image.Title",
  },
  {
    id: 2,
    icon: "ic_feature_2.svg",
    items: [
      "app.Landing.Banner.Instructions.Video",
      "app.Landing.Banner.Instructions.Video_2",
    ],
    title: "app.Landing.Banner.Instructions.Video.Title",
  },
  {
    id: 3,
    icon: "ic_feature_3.svg",
    items: [
      "app.Landing.Banner.Instructions.Text",
      "app.Landing.Banner.Instructions.Text2",
    ],
    title: "app.Landing.Banner.Instructions.Text.Title",
  },
];

export const STATUS_FILE = {
  finished: "finished",
  rejected: "rejected",
  loading: "loading",
  initial: "initial",
  uploadted: "uploadted",
};

/**
 * Format Accept Files
 * Max Size Files
 */
export const RequirementsFile = {
  accept: {
    "image/jpeg": [".jpg", ".jpeg"],
    "image/png": [".png"],
    "image/mp4": [".mp4"],
    "image/mov": [".mov"],
  },
  maxSize: 40048576, // 50 MB
  maxFiles: 1,
  multiple: false,
};

/**
 * Format Accept Files
 * Max Size Files
 */
export const RequirementsFile_v2 = (deviceSelected = DEVICES.image) => {
  if (deviceSelected === DEVICES.image) return RequirementsFile_v2_image;
  else if (deviceSelected === DEVICES.video) return RequirementsFile_v2_video;
  else if (deviceSelected === DEVICES.audio) return RequirementsFile_v2_audio;
};
export const RequirementsFile_v2_video = {
  accept: {
    "video/mp4": [],
    "video/mov": [],
  },
  maxSize: 300572800, // 900 MB
  maxFiles: 3,
  multiple: true,
};
export const RequirementsFile_v2_audio = {
  accept: {
    "image/wav": [".wav"],
    "image/wma": [".wma"],
    "image/mp3": [".mp3"],
    "image/mp4": [".mp4"],
    "image/m4a": [".m4a"],
    "image/flac": [".flac"],
    "image/aac": [".aac"],
  },
  maxSize: 31457280, // 30 MB
  maxFiles: 1,
  multiple: false,
};

export const RequirementsFile_v2_image = {
  accept: {
    "image/*": [],
  },
  maxSize: 10485760, // 50 MB
  maxFiles: 4,
  multiple: true,
};

export const __onDeleteFile = (path, files) => {
  const newFiles = [...files];
  remove(newFiles, { path });
  return newFiles;
};

export const INITIAL_ERROR = { title: "", description: "" };

export const MESSAGES_DROPZONE = {
  FILE: "app.validation.file",
  TEXT_LENGTH: "app.validation.textLength",
};
