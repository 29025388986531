import React from "react";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";
import "./style.scss";
import { getOptionsChartBar } from "./Constants";

export const data_doughnut = {
  labels: ["3", "1"],
  datasets: [
    {
      data: [3, 1],
      backgroundColor: ["#FF4D4F", "#EFE384"],
    },
  ],
};

// Define your props here
interface TChartProps {
  className?: string;
  type?: string;
  data?: any;
  width?: string;
  height?: string;
  withLegend?: boolean;
  index?: number;
  images?: any;
}

export const CHART_TYPES = {
  bar: "bar",
  lineal: "lineal",
  doughnut: "doughnut",
};

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
);

const TChart = (
  props: TChartProps = {
    type: CHART_TYPES.bar,
    withLegend: true,
  }
) => {
  if (!props.withLegend) {
    ChartJS.unregister(Legend);
  }
  return (
    <div className={`TChart ${props.type} ${props.className}`}>
      {props.type === CHART_TYPES.doughnut ? (
        <Doughnut
          width={props.width || "150px"}
          height={props.height || "150px"}
          data={props.data}
          options={{
            rotation: -90,
            circumference: 180,
            plugins: {
              legend: {
                labels: {
                  boxWidth: 15,
                  font: {
                    size: 12, // Adjust the size as needed
                  },
                },
              },
            },
          }}
        />
      ) : (
        //@ts-ignore
        <Bar
          options={getOptionsChartBar(props.data, props.index)}
          data={props.data}
          width={"100%"}
          height={"170px"}
        />
      )}
    </div>
  );
};

TChart.displayName = "TChart";

TChart.defaultProps = {
  className: "",
};

export default TChart;
