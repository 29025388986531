import React, { useContext, useEffect } from "react";
import { withLanding } from "../../hocs/withLanding";

import TNavigator from "../../components/Navigator";

import { page_view } from "../../hooks/usePageVisit";
import { EVENTS_TGM } from "../../utilities/Events";
import "./styles.scss";
import { LanguageContext } from "../../context/i18n/LanguageProvider";

const PrivacyPage = () => {
  const { locale } = useContext(LanguageContext);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    page_view({ page_type: EVENTS_TGM.policy, locale });
  }, []);

  return (
    <div className="how-it-works">
      <TNavigator page="Política de Privacidad" withSearch={false} />

      <div className="max-width how_content">
        <p className="mt-2">
          Telef&oacute;nica Innovaci&oacute;n Digital, S.L.U., como titular de
          este sitio web, te informa de que las empresas del Grupo
          Telef&oacute;nica tienen el compromiso de respetar la privacidad de
          los usuarios y el secreto y seguridad de los datos personales, en
          cumplimiento de lo establecido en el Reglamento (UE) 2016/679,
          Reglamento General de Protecci&oacute;n de Datos.
        </p>

        <p>Nuestros principios</p>

        <p>Transparencia</p>

        <p>
          No vamos a tratar tus datos de una forma inesperada, oscura o abusiva.
        </p>

        <p>
          Cuando recabemos tus datos personales a trav&eacute;s de nuestra web
          ser&aacute;s informado convenientemente sobre: qui&eacute;n trata tus
          datos, cu&aacute;l es la finalidad del tratamiento, en qu&eacute; se
          basa la legitimidad del tratamiento, la posibilidad de ejercer tus
          derechos y otra informaci&oacute;n de inter&eacute;s. Adem&aacute;s,
          tus datos ser&aacute;n cancelados seg&uacute;n la pol&iacute;tica de
          retenci&oacute;n definida sobre la cu&aacute;l ser&aacute;s informado
          y en todo caso, cuando lo solicites ejerciendo el derecho de
          cancelaci&oacute;n.
        </p>

        <p>Control</p>

        <p>
          Recabaremos tu consentimiento siempre que sea necesario y pondremos a
          tu disposici&oacute;n las herramientas necesarias para que puedas
          acceder y actualizar tu informaci&oacute;n personal, as&iacute; como
          decidir c&oacute;mo gestionar tus datos, seleccionando las finalidades
          para las que los vamos a tratar.
        </p>

        <p>Seguridad</p>

        <p>
          Nos preocupamos por garantizar la seguridad, el secreto y la
          confidencialidad de tus datos. Por eso, como parte de nuestro
          compromiso y en cumplimiento de la legislaci&oacute;n vigente, hemos
          adoptado las m&aacute;s exigentes y robustas medidas de seguridad y
          medios t&eacute;cnicos para evitar su p&eacute;rdida, mal uso o su
          acceso sin tu autorizaci&oacute;n, comprometi&eacute;ndonos a guardar
          secreto respecto de los mismos y garantizando el deber de guardarlos
          adoptando todas las medidas necesarias y razonables que eviten su
          alteraci&oacute;n, p&eacute;rdida y tratamiento o acceso no
          autorizado, de acuerdo con lo establecido en la legislaci&oacute;n
          aplicable. Cualquier duda que pueda surgirte de esta Pol&iacute;tica
          de Privacidad, cont&aacute;ctanos en: autoverifai@telefonica.com y
          estaremos encantados de poder ayudarte.
        </p>

        <p>
          1. &iquest;Qui&eacute;n es responsable del tratamiento de tus datos?
        </p>

        <p>
          La compa&ntilde;&iacute;a responsable del tratamiento de tus datos es:
        </p>

        <p>
          - Denominaci&oacute;n social: Telef&oacute;nica Innovaci&oacute;n
          Digital, S.L.U. (en adelante, &ldquo;Telef&oacute;nica
          Innovaci&oacute;n Digital&rdquo; o &ldquo;nosotros&rdquo;).
        </p>

        <p>- NIF: B83188953</p>

        <p>
          - Domicilio social: Ronda de la Comunicaci&oacute;n, s/n, Edificio
          Central, 28050, Madrid. - Contacto Delegado de Protecci&oacute;n de
          Datos: DPO_telefonicasa@telefonica.com
        </p>

        <p>2. &iquest;Por qu&eacute; tratamos tus datos?</p>

        <p>
          Trataremos los datos personales de los usuarios del servicio VerifAI
          (los &ldquo;Usuarios&rdquo;) necesarios para la prestaci&oacute;n del
          servicio, en los t&eacute;rminos de las Condiciones Generales del
          Servicio, disponibles en la herramienta.
        </p>

        <p>
          El uso del servicio por los Usuarios y por lo tanto el tratamiento de
          sus datos personales por TELEF&Oacute;NICA, se hace en el marco de la
          prestaci&oacute;n del Servicio VerifAI por Telef&oacute;nica al
          cliente que contrate este servicio (en adelante, el
          &ldquo;Cliente&rdquo;), conforme se definen en las Condiciones
          Generales del Servicio. Tu acceso como Usuario del Servicio se enmarca
          en la relaci&oacute;n que mantengas directamente con el Cliente.
        </p>

        <p>3. &iquest;Qu&eacute; datos tratamos de los Usuarios de VerifAI?</p>

        <p>
          1. Datos b&aacute;sicos de contacto profesional proporcionados por el
          Cliente de VerifAI, como, por ejemplo, nombre y apellidos y la cuenta
          de correo electr&oacute;nico, con la finalidad de crear un usuario y
          contrase&ntilde;a de acceso a VerifAI.
        </p>

        <p>
          2. Datos de uso del Servicio: TELEF&Oacute;NICA tratar&aacute;
          adem&aacute;s informaci&oacute;n obtenida del uso del Sistema por
          parte del USUARIO como logs de acceso al Sistema, as&iacute; como de
          las consultas realizadas y de los resultados obtenidos.
        </p>

        <p>
          A efectos aclaratorios, se informa que el Contenido consultado por el
          usuario de VerifAI, as&iacute; como el informe de resultado
          correspondiente, solamente se sujetar&aacute;n a la presente
          Pol&iacute;tica de Privacidad si incluyan un dato de car&aacute;cter
          personal.
        </p>

        <p>
          3. Datos relativos a preguntas, consultas, soporte, sugerencias y/u
          otros contactos.
        </p>

        <p>4.</p>

        <p>
          Datos estad&iacute;sticos de uso e interacciones del usuario,
          as&iacute; como datos relativos al dispositivo y/o conexi&oacute;n con
          la que se accede a la web: metadatos de conexi&oacute;n, as&iacute;
          como informaci&oacute;n que hayamos podido obtener a trav&eacute;s de
          la utilizaci&oacute;n de cookies y tecnolog&iacute;as similares (SDKs,
          pixels, tags, beacons, etc.).
        </p>

        <p>
          4. &iquest;C&oacute;mo obtenemos y de d&oacute;nde proceden tus datos?
        </p>

        <p>
          Del Cliente para registro del Usuario y directamente del Usuario
          cuando (1) utilices el servicio de VerifAI; y/o (2) te pongas en
          contacto con nosotros a trav&eacute;s de los medios disponibles en el
          Servicio.
        </p>

        <p>5. &iquest;Para qu&eacute; y por qu&eacute; utilizamos tus datos?</p>

        <p>
          Telef&oacute;nica Innovaci&oacute;n Digital tratar&aacute; los datos
          para las siguientes finalidades:
        </p>

        <p>i. Operar y prestar el servicio de VerifAI al Cliente.</p>

        <p>
          ii. Registrar la aceptaci&oacute;n de las Condiciones Generales de
          VerifAI, para el ejercicio o defensa de reclamaciones, cuando
          corresponda.
        </p>

        <p>
          iii. Responder a las consultas recibidas a trav&eacute;s de nuestros
          canales de contacto: en el caso de que nos contactes, trataremos tus
          datos de contacto y aquellos datos personales que proporciones, con la
          finalidad de gestionar y contestar a tu consulta.
        </p>

        <p>6. &iquest;Durante cu&aacute;nto tiempo conservamos tus datos?</p>

        <p>
          Trataremos tus datos mientras sean necesarios para las finalidades
          descritas:
        </p>

        <p>
          i. Respecto a operaci&oacute;n y prestaci&oacute;n del servicio de
          VerifAI: trataremos los datos mientras dure la prestaci&oacute;n del
          Servicio al Cliente y/o por el plazo establecido por el Cliente.
        </p>

        <p>
          ii. Respecto al registro de la aceptaci&oacute;n de las Condiciones
          Generales de VerifAI: dichos registros se conservar&aacute;n hasta
          finalizar el plazo de caducidad de las acciones legales que, en su
          caso, se puedan llevar a cabo por o contra Telef&oacute;nica.
        </p>

        <p>
          iii. Respecto a las consultas recibidas a trav&eacute;s de canales de
          contacto: aquellos datos recabados o tratados a trav&eacute;s de esos
          canales se conservar&aacute;n s&oacute;lo durante el tiempo necesario
          para responder la consulta, y, en su caso, finalizar el plazo de
          caducidad de las acciones legales que se puedan llevar a cabo por o
          contra Telef&oacute;nica, relacionadas con dichas consultas.
        </p>

        <p>
          Transcurridos estos plazos, los datos podr&aacute;n ser destruidos,
          bloqueados y/o anonimizados, seg&uacute;n proceda, y de conformidad
          con lo indicado por la legislaci&oacute;n.
        </p>

        <p>&iquest;Qui&eacute;n tiene acceso a tus datos?</p>

        <p>
          Solo accede a tus datos Telef&oacute;nica Innovaci&oacute;n Digital y
          aquellos subcontratistas que act&uacute;en por nuestra cuenta y
          nombre, en calidad de encargados de tratamiento, sujetos
          contractualmente a nuestras instrucciones, &uacute;nicamente con las
          finalidades l&iacute;citas informadas y durante el periodo de tiempo
          estrictamente necesario para ello.
        </p>

        <p>
          En caso de que vayamos a compartir tu informaci&oacute;n con terceros
          en calidad de responsables del tratamiento (p.ej. otras empresas del
          Grupo al que pertenecemos que vayan a tratar tus datos con finalidades
          propias diferentes de las informadas) te informaremos adecuadamente y,
          en el caso de ser necesario, recogeremos las autorizaciones necesarias
          para hacerlo.
        </p>

        <p>
          7. &iquest;Cu&aacute;les son tus derechos y c&oacute;mo puedes
          controlar tus datos?
        </p>

        <p>
          En el caso de que contactes con nosotros a trav&eacute;s de los medios
          disponibles y/o rellenes un formulario de comunicaciones en nuestra
          p&aacute;gina web, podr&aacute;s ejercer los siguientes derechos de
          protecci&oacute;n de datos frente a Telef&oacute;nica
          Innovaci&oacute;n Digital seg&uacute;n sean de aplicaci&oacute;n:
        </p>

        <p>
          - Derecho de acceso: derecho a solicitar el detalle de los datos que
          tenemos sobre ti y c&oacute;mo los tratamos, as&iacute; como la
          obtenci&oacute;n de una copia de los mismos.
        </p>

        <p>
          - Derecho de rectificaci&oacute;n: derecho a obtener la
          rectificaci&oacute;n de tus datos inexactos o err&oacute;neos,
          as&iacute; como a completar aquellos otros que sean incompletos.
        </p>

        <p>
          - Derecho de supresi&oacute;n: derecho a solicitar la
          eliminaci&oacute;n o supresi&oacute;n de tus datos e
          informaci&oacute;n en determinadas circunstancias. No obstante, ten en
          cuenta de que hay determinadas ocasiones en las que estamos legalmente
          legitimados para continuar conservando y tratando tus datos (p.ej.
          para el cumplimiento de una obligaci&oacute;n legal de
          conservaci&oacute;n de datos).
        </p>

        <p>
          - Derecho de limitaci&oacute;n: derecho a restringir o limitar el
          tratamiento de tus datos en determinadas circunstancias (p.ej. si
          aplica la supresi&oacute;n de datos pero, en vez de eliminarlos,
          prefieres que los bloqueemos y los tratemos &uacute;nicamente a los
          efectos de su conservaci&oacute;n ya que vas a necesitarlos
          posteriormente para interponer una reclamaci&oacute;n). De nuevo, ten
          en cuenta que puede haber ocasiones en que estemos legalmente
          legitimados para rechazar tu solicitud de limitaci&oacute;n.
        </p>

        <p>
          - Derecho de oposici&oacute;n: derecho a oponerte a que tratemos tus
          datos para una finalidad concreta, en determinadas circunstancias
          previstas en la normativa y relacionadas con tu situaci&oacute;n
          personal. En algunos casos te facilitamos opciones sencillas para
          oponerte directamente a trav&eacute;s de la web, app o e-mail (p.ej.
          oposici&oacute;n a recibir comunicaciones comerciales remitidas) y en
          otros casos deber&aacute;s contactar con nosotros a trav&eacute;s del
          e-mail para que nuestro equipo de privacidad pueda evaluar tu
          solicitud con m&aacute;s detalle.
        </p>

        <p>
          - Derecho de portabilidad de datos: derecho a recibir tus datos en un
          formato de uso com&uacute;n que te permita portarlos a otra empresa,
          as&iacute; como, en su caso, a solicitarnos la transmisi&oacute;n
          directa de los mismos a dicha otra empresa cuando sea
          t&eacute;cnicamente posible. Recuerda que el derecho a la portabilidad
          de datos es distinto al derecho a la portabilidad de tu n&uacute;mero
          de tel&eacute;fono.
        </p>

        <p>
          - Derecho a no ser objeto a decisiones automatizadas: derecho a
          oponerte a ser objeto de una decisi&oacute;n basada &uacute;nicamente
          en el tratamiento automatizado de datos (sin intervenci&oacute;n
          humana), incluida la elaboraci&oacute;n de perfiles, que produzca
          efectos jur&iacute;dicos en ti o te afecte de una forma significativa.
        </p>

        <p>
          - Derecho a retirar el consentimiento en cualquier momento: derecho a
          retirar tu consentimiento en cualquier momento al tratamiento de tus
          datos para una finalidad concreta, cuando est&eacute; basada en dicha
          base legitimadora
        </p>

        <p>
          Con car&aacute;cter general, podr&aacute;s ejercitar estos derechos
          (en cualquier momento y de forma gratuita) a trav&eacute;s de
          autoverifai@telefonica.com.
        </p>

        <p>
          Para ello es importante tener en cuenta que cuando ejercites un
          derecho, en la mayor&iacute;a de los casos, deber&aacute;s especificar
          con claridad cu&aacute;l ejercitas y aportar una copia de tu documento
          oficial
        </p>

        <p>
          de identidad (p.ej. DNI, NIE o Pasaporte) o cualquier otra
          informaci&oacute;n que nos permita identificarte, as&iacute; como, si
          es posible, env&iacute;es tu solicitud desde el mismo correo
          electr&oacute;nico que nos facilitaste inicialmente.
        </p>

        <p>
          Por &uacute;ltimo, en caso de que no estar de acuerdo en la manera de
          tratar tus datos tendr&aacute;s derecho a presentar reclamaci&oacute;n
          ante la autoridad nacional de control, dirigi&eacute;ndote a la
          Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos, cuyos datos de
          contacto son los siguientes:
        </p>

        <p>Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos</p>

        <p>C/ Jorge Juan, 6 &ndash; 28001 Madrid www.aepd.es</p>

        <p>
          Con car&aacute;cter previo, y para cualquier duda, queja o
          reclamaci&oacute;n sobre el tratamiento de tus datos por
          Telef&oacute;nica Innovaci&oacute;n Digital, podr&aacute;s ponerte en
          contacto con nuestro Delegado de Protecci&oacute;n de Datos
          escribiendo a DPO_telefonicasa@telefonica.com.
        </p>

        <p>8. &iquest;C&oacute;mo protegemos tus datos?</p>

        <p>
          Telef&oacute;nica Innovaci&oacute;n Digital garantiza la seguridad, el
          secreto y la confidencialidad de tus datos, comunicaciones e
          informaci&oacute;n personal adoptado las m&aacute;s exigentes y
          robustas medidas de seguridad, y poniendo a tu disposici&oacute;n
          herramientas y funcionalidades que te permiten controlarlos.
        </p>
      </div>
    </div>
  );
};

export default withLanding(PrivacyPage);
