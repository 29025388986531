import React, { useState } from "react";

import { Button, Dropdown, Switch } from "antd";
import { ReactSVG } from "react-svg";
import { pathServer } from "../../utilities/Function";
import { useStats } from "../../hooks/useStats";
import { useSelector } from "react-redux";
import TLatchModal from "../LatchModal";
import { useLatch } from "../../hooks/useLatch";
import "./style.scss";
import { useDispatch } from "react-redux";
import { setMessagePage } from "../../configuration/reduxStore/Page/actions";
import TForgotPasswordModal from "../ForgotPasswordModal";
import { useForgotPassword } from "../../hooks/useForgotPassword";
import { API_STATUS } from "../../utilities/Constant";
interface UserPanelProps {
  className?: string;
  onLogout: () => void;
}

const UserLabel = ({ tag, value, status = 1, style = {} }) => {
  return (
    <div
      className={
        "UserPanel__content-label " + (status === 1 ? "item-blue" : " item-red")
      }
      style={style}
    >
      <p className="font-medium-2">{tag}</p>
      <small className="font-medium-2">{value}</small>
    </div>
  );
};

const TUserPanel = (props: UserPanelProps) => {
  const [stats] = useStats();
  const [loading, pairLatch, unPairLatch] = useLatch();
  const [loadingPassword, forgotPassword] = useForgotPassword();
  const { user } = useSelector((state: any) => state.login);
  const [settings, setShowSettings] = useState({
    latch: false,
    forgotPassword: false,
  });
  const isLatch = localStorage.getItem("latch");
  const dispatch = useDispatch();
  const onHandleCheck = async (checked) => {
    if (!checked) {
      const response = await unPairLatch();
      if (response.code === 200) {
        localStorage.removeItem("latch");
        dispatch(
          setMessagePage({
            type: "success",
            content: response.message,
          })
        );
      } else {
        dispatch(
          setMessagePage({
            type: "error",
            content: "Error al desvincular Latch",
          })
        );
      }
    }
  };

  const handleSubmit = async (values) => {
    const response = await forgotPassword(values);
    if (response === API_STATUS.noContent) {
      dispatch(
        setMessagePage({
          type: "success",
          content: "Contraseña actualizada correctamente",
        })
      );
    } else {
      dispatch(
        setMessagePage({
          type: "error",
          content: "Error al cambiar de contraseña",
        })
      );
    }
    setShowSettings({ ...settings, forgotPassword: false });
  };

  return (
    <>
      <Dropdown
        placement="bottom"
        arrow
        dropdownRender={(menu) => (
          <div className="UserPanel">
            <div className="UserPanel__header">
              <p className="text-center font-bold d-flex">
                {" "}
                <ReactSVG
                  src={pathServer.PATH_ICONS + "ic_user-filled.svg"}
                  className="icon mr-0"
                />{" "}
                {user?.name}
              </p>
            </div>
            <div className="UserPanel__body">
              <p className="mt-0 mb-0">Contenido analizado:</p>
              <div className="UserPanel__content">
                <UserLabel
                  tag={"Semana"}
                  value={stats?.last_month_analyzed_content}
                />
                <UserLabel
                  tag={"Mes"}
                  value={stats?.last_week_analyzed_content}
                />
                <UserLabel tag="Total" value={stats?.total_analyzed_content} />
                <UserLabel
                  tag="Algoritmos ejecutados"
                  value={stats?.engine_jobs_launched}
                  status={2}
                  style={{ gridColumn: "1/span 2" }}
                />
                <UserLabel
                  tag="Fakes detectados"
                  value={stats?.engine_results_with_high_ai_score}
                  status={2}
                />
              </div>
            </div>
            <div className="UserPanel__footer mt-1">
              {isLatch ? (
                <Button type="link" className="d-block mx-auto ">
                  Protección con Latch{" "}
                  <Switch
                    loading={loading}
                    onChange={onHandleCheck}
                    defaultChecked
                    className="ml-0"
                  />
                </Button>
              ) : (
                <Button
                  className="UserPanel__footer-latch mx-auto  text-center"
                  type="link"
                  onClick={() => setShowSettings({ ...settings, latch: true })}
                >
                  Proteger con Latch{" "}
                  <ReactSVG
                    src={pathServer.PATH_ICONS + "ic_closed.svg"}
                    className="icon-semi-medium"
                  />
                </Button>
              )}
              <Button
                className="d-block"
                type="primary"
                onClick={() =>
                  setShowSettings({ ...settings, forgotPassword: true })
                }
              >
                Cambiar contraseña
              </Button>
              <Button
                className="d-block"
                type="primary"
                onClick={props.onLogout}
              >
                Cerrar sesión
              </Button>
              {/* <Button type="link" className="d-block mt-0">
                Eliminar cuenta (Coming soon)
              </Button> */}
            </div>
          </div>
        )}
      >
        <ReactSVG
          src={pathServer.PATH_ICONS + "ic_user-filled.svg"}
          className="icon d-hover p-1"
        />
      </Dropdown>
      {settings.latch && (
        <TLatchModal
          loading={loading}
          pairLatch={pairLatch}
          visible={settings.latch}
          onCancel={() => setShowSettings({ ...settings, latch: false })}
        />
      )}
      {settings.forgotPassword && (
        <TForgotPasswordModal
          loading={loadingPassword}
          onSubmit={handleSubmit}
          visible={settings.forgotPassword}
          onCancel={() =>
            setShowSettings({ ...settings, forgotPassword: false })
          }
          userEmail={user?.email}
        />
      )}
    </>
  );
};

TUserPanel.displayName = "TUserPanel";

TUserPanel.defaultProps = {
  className: "",
};

export default TUserPanel;
