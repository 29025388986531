import { useState, useEffect } from "react";
import { groupByField } from "../../infraestructure/utils/helpers";
import { useDispatch } from "react-redux";
import { container } from "../../inject_container/injection";
import { LOGIN_PORT, LoginPort } from "../../domain/port/inbound/LoginPort";
import {
  setInitialPage,
  setMessagePage,
} from "../configuration/reduxStore/Page/actions";
import { setInitialStateLogin } from "../configuration/reduxStore/Login/actions";
import { useHistory } from "react-router-dom";
import {
  API_STATUS,
  API_STATUS_MESSAGE,
  ROUTES_GENERAL,
  TENANT_ID_GENERAL,
} from "../utilities/Constant";

const publicUrl = `${process.env.REACT_APP_BASE_URL}/api/pub`;

export const useGetModels = () => {
  const [dataModels, setDataModels] = useState([]);
  const [loadingModels, setLoading] = useState(false);
  const loginRequest = container.get<LoginPort>(LOGIN_PORT);
  const history = useHistory();
  const dispatch = useDispatch();
  const getTenantModels = async () => {
    let myHeaders;

    myHeaders = {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    };

    try {
      setLoading(true);
      let response = await fetch(publicUrl + "/tenant/" + TENANT_ID_GENERAL, {
        method: "GET",
        headers: myHeaders,
      });

      if (
        response.status !== API_STATUS.success ||
        response.statusText === API_STATUS_MESSAGE.unauthorized
      ) {
        const response = await loginRequest.refreshToken();
        if (response.status === 200) {
          getTenantModels();
        } else {
          onLogout();
        }
      } else {
        let responseData = await response.json();
        const data = responseData.algorithms;
        setDataModels(groupByField(data, "content_type"));
      }
    } catch (e) {
      console.log("Error", e);
    } finally {
      setLoading(false);
    }
  };

  const onLogout = () => {
    loginRequest.logout();
    dispatch(setInitialPage());
    dispatch(setInitialStateLogin());
    history.push(ROUTES_GENERAL.LOGIN);
  };

  useEffect(() => {
    getTenantModels();
  }, []);

  return [dataModels, loadingModels] as const;
};
