import React, { useEffect, useState } from "react";
import THeader from "../components/Header";
import { Button } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";

import TFooter from "../components/Footer";
import "./styles.scss";

export default function WLandingHome({ children }) {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 200) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <THeader />
      <main>{children}</main>
      <TFooter />
      <div
        style={{ marginTop: "-80px", marginLeft: "20px" }}
        className="scroll"
      >
        {showButton && (
          <Button
            shape="circle"
            className=""
            type="primary"
            size="large"
            onClick={scrollToTop}
            icon={<ArrowUpOutlined />}
          ></Button>
        )}
      </div>
    </>
  );
}

WLandingHome.propTypes = {};

export const withLanding = (Component) =>
  function (props) {
    return (
      <WLandingHome>
        <Component {...props} />
      </WLandingHome>
    );
  };
