import React from "react";
import { withLanding } from "../../hocs/withLanding";
import TNavigator from "../../components/Navigator";
import TContent from "../../components/Content";
import { CONTENT_PAGE } from "./Constants";

import "./styles.scss";
import { t } from "../../utilities/Message";
import { scrollToElementById } from "../../../infraestructure/utils/helpers";

const HelpPage = () => {
  const titles = CONTENT_PAGE.map((c) => c.title);

  const onClick = (id: string) => {
    scrollToElementById(id, 0);
  };

  return (
    <div className="help-page">
      <TNavigator
        page="Help"
        withSearch={false}
        content={
          <>
            {titles.map((title) => (
              <p onClick={() => onClick(title)}> {t(title)}</p>
            ))}
          </>
        }
      />
      <div className="max-width help_content">
        {CONTENT_PAGE.map((content) => (
          <div className="mt-2 mb-1" id={content.title}>
            <h4 className="font-bold mb-1">{t(content.title)}</h4>
            <TContent {...content} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default withLanding(HelpPage);
