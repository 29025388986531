export default {
  tokenStorage: "token",
  routesStorage: "routes",
  emailRegex:
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\.(com|pe)$/,
  numberRegex: /^[0-9]*$/,
  phoneRegex: /^9[0-9]{8}$/,
  rucRegex: /^[0-9]{12}$/,
  dniRegex: /^[0-9]{8}$/,
  cciNumberRegex: /^[0-9]{20}$/,
  accountNumberRegex: /^[0-9]{20}$/,
  cardNumberRegex: /^[0][1-9]\d{13,15}$|^[1-9]\d{13,15}$/,
  monthRegex: /^0[1-9]{1}|1[0-9]{1}$/,
  yearRegex: /^[0-9]{4}$/,
  licensePlateRegex: /^[a-zA-Z0-9]{3}-[a-zA-Z0-9]{3}$/,
  policyRegex: /^[0-9]+$/,
  padronRegex: /^[0-9]{3}$/,
  cvvRegex: /^[0][1-9]\d{1,2}$|^[1-9]\d{2,3}$/,
  nameRegex:
    /^[a-zA-ZñÑáéíóúÁÉÍÓÚ]{1}[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]{1}[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]*$/,
  addressRegex:
    /^[a-zA-ZñÑáéíóúÁÉÍÓÚ]{1}[a-zA-Z0-9.-ñÑáéíóúÁÉÍÓÚ\s]{4}[a-zA-Z0-9.-ñÑáéíóúÁÉÍÓÚ\s]*$/,
  addressCityRegex:
    /^[a-zA-Z]{1}[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\s]{1}[a-zA-Z0-9.-ñÑáéíóúÁÉÍÓÚ\s\s]*$/,
  alphaNumericRegex: /^[a-zA-Z0-9]*$/,
  alphaRegex: /^[a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/,
  decimalNumber: /^([1-9]+(\.\d{0,2})?|[1-9]\.?\d{1,2})$/,
  visaStartRegex: /^4.*/,
  masterCardStartRegex: /^(51|55|2221|2720).*/,
  americanExpressStartRegex: /^(34|37).*/,
  dinnersStartRegex: /^(300|305|36|38).*/,
};

export const colorStatusPending = "#E5AF17";
export const bgStatusPending = "#FCF5DE";
export const colorStatusAsignee = "#0DBF64";
export const bgStatusAsignee = "#D2FFEB";
export const colorStatusFinalize = "#5E636A";
export const bgStatusFinalize = "#E0E0E0";
export const colorStatusCanceled = "#ED4A66";
export const bgStatusCanceled = "#FFE1E6";

//actions
export const remisseAceptRequest = "acceptRemisse";
export const remisseCancelRequest = "cancelRemisse";

//fomatDate
export const formatDatePicker = "DD MMM";
export const formatDateApi = "YYYY-MM-DD"; //2020-12-09

export const ROUTES_GENERAL = {
  HOME_PAGE: "/",
  CONTACT_PAGE: "/contact",
  HOW_WORKS: "/how-it-works",
  HELP: "/help",
  RESULT: "/result",
  LEGAL: "/aviso-legal",
  CONDITIONS: "/condiciones-generales",
  PRIVACY: "/politica-de-privacidad",
  COOKIES: "/cookies",
  REPORT: "/report",
  LOGIN: "/login",
};

export const MESSAGES = {
  like: "Estoy de acuerdo",
  dislike: "No estoy de acuerdo",
  started: "Empezar",
};

export const API_STATUS_MESSAGE = {
  authorized: "Authorized",
  unauthorized: "Unauthorized",
  notFound: "Not Found",
};

export const API_STATUS = {
  success: 200,
  created: 201,
  accepted: 202,
  noContent: 204,
  failed: 400,
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  internalServerError: 500,
  badGateway: 502,
  serviceUnavailable: 503,
  gatewayTimeout: 504,
};

export const TENANT_ID_GENERAL = process.env.REACT_APP_TENANT || 7;
