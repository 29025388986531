

export const CONTENT_DATA_FILTER = [
    {
      label: "Imagen",
      key: "image",
    },
    {
      label: "Video",
      key: "video",
    },
    {
      label: "Audio",
      key: "audio",
    },
];