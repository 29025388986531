import React, { useContext } from "react";
import { Row, Col } from "antd";

import { pathServer } from "../../utilities/Function";
import TDropZone from "../DropZone";

import { t } from "../../utilities/Message";
import { ThemeContext } from "../../context/theme/ThemeProvider";
import { VERIFAI_CLIENTS } from "../../context/theme/Constants";
import TDropZoneV2 from "../DropZone/v2";
import "./style.scss";
interface BannerProps {
  className?: string;
  uploadFile?: (params) => Promise<any>;
  uploadText?: (params: string) => void;
  loading: boolean;
}

const TBanner = (props: BannerProps) => {
  const { mode } = useContext(ThemeContext);
  const isPolice = mode === VERIFAI_CLIENTS.police;
  return (
    <div className={`banner ${props.className} max-padding`}>
      <Row gutter={[16, 16]} className="max-width">
        <Col className="w-100">
          <h1 className="banner__title">
            <img
              src={pathServer.PATH_LOGO + "logo_white_pro.svg"}
              alt="logo"
              width={350}
              className="img-white"
            />
            <img
              src={pathServer.PATH_LOGO + "logo_pro.svg"}
              alt="logo"
              width={250}
              className="img-full"
            />
          </h1>
          <p className="banner__subtitle font-medium-2">
            {t("app.Landing.Banner.Title", "")}
          </p>
          {isPolice ? (
            <TDropZoneV2
              loading={props.loading}
              uploadFile={props.uploadFile}
            />
          ) : (
            <TDropZone
              loading={props.loading}
              uploadFile={props.uploadFile}
              uploadText={props.uploadText}
            />
          )}
        </Col>
      </Row>
      <img
        src={pathServer.PATH_IMG + "main/background.gif"}
        alt="banner-mobile"
        className="to-md w-100"
      />
    </div>
  );
};

TBanner.displayName = "TBanner";

TBanner.defaultProps = {
  className: "",
};

export default TBanner;
