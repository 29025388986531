import { container } from "../../inject_container/injection";
import { EVENTS_TGM } from "../utilities/Events";
import { GTMPort, GTM_PORT } from "../../domain/port/inbound/GTMPort";

const BUSINESS_MODEL_DEFAULT = "B2B";

const getBasePath = () => {
  const { pathname, search, hash } = window.location;
  const path = `${window.location.origin}${pathname}${search}${hash}`;
  const previousPagePath = localStorage.getItem("previousPagePath") || "/";

  localStorage.setItem("previousPagePath", path);
  return {
    page_name: path,
    previous_page_path: previousPagePath,
    clean_url: `${window.location.origin}${pathname}`,
    business_model: BUSINESS_MODEL_DEFAULT,
  };
};
/**
Custom hook to detect page visits and navigations
and send a PageView analytic events when url changes
*/
export const page_view = (props) => {
  const gtm = container.get<GTMPort>(GTM_PORT);
  const { page_type, locale = "es" } = props;

  gtm.emitEvent("PageDataLayer", {
    event: "page_view",
    page_type: page_type,
    locale: locale === "es" ? "ES" : "EN",
    ...getBasePath(),
  });
};

export const file_uploaded = (props) => {
  const gtm = container.get<GTMPort>(GTM_PORT);
  const { page_type, data, name, locale = "es" } = props;

  gtm.emitEvent("PageDataLayer", {
    event: "file_uploaded",
    page_type: page_type,
    locale: locale === "es" ? "ES" : "EN",
    ...getBasePath(),
    content_type: data?.deviceSelected,
    tag: data.tags,
    file_name: name,
    algorithm_list: data.algorithm_list.join(","),
  });
};

export const delete_task = (props) => {
  const gtm = container.get<GTMPort>(GTM_PORT);
  const { page_type, taskId } = props;

  gtm.emitEvent("PageDataLayer", {
    event: "delete_task",
    page_type: page_type,
    task_id: taskId,
    ...getBasePath(),
  });
};

export const rating_subtask = (props) => {
  const { page_type, review, subTaskId } = props;
  const gtm = container.get<GTMPort>(GTM_PORT);

  gtm.emitEvent("PageDataLayer", {
    event: "rating_subtask",
    page_type: page_type,
    subTaskId: subTaskId,
    review: review,
    ...getBasePath(),
  });
};

export const frame_selected = (props) => {
  const { time, reference, image } = props;
  const gtm = container.get<GTMPort>(GTM_PORT);

  gtm.emitEvent("PageDataLayer", {
    event: "frame_selected",
    page_type: EVENTS_TGM.report_detail,
    motor: "vitt",
    reference: reference,
    time: time,
    image: image,
    ...getBasePath(),
  });
};
