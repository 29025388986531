import React, { useEffect, useState } from "react";

import { DEVICES } from "../DropZone/v2/Data";
import TChart from "../Chart";
import TAudio from "../Audio";
import {
  formattedData,
  formattedDataAudio,
  roundToTwoDecimals,
} from "../../../infraestructure/utils/helpers";
import TSpinner from "../Spinner";
import TCarouselImage from "../CarouselImage";
import { onLoadThumbnail } from "../../utilities/Function";
import { STATUS_TAKS_NUMBER } from "../MyReport/Constants";

import "./style.scss";
import { Image } from "antd";

// Define your props here
interface CollapseInfoProps {
  className?: string;
  device?: string;
  duration?: number;
  resource?: string;
  detail?: any;
  loading?: boolean;
  description?: string;
  status?: number;
  index: number;
  taskId?: number;
  subTaskId?: number;
  thumbnail_uri?: string;
  thumbnail_width?: number;
  thumbnail_height?: number;
  algorithm?: string;
  onHandleFrame: (number) => void;
}
const VIDEO_PLACEHOLDER_DETAIL = "/images/placeholder/detail.png";

const TCollapseInfo = (props: CollapseInfoProps) => {
  console.log("🚀 ~ TCollapseInfo ~ props:", props);
  const [images, setImages] = useState([]);
  const isThumbnailAvailable =
    props.algorithm === "vit" && props.status === STATUS_TAKS_NUMBER.finished;
  useEffect(() => {
    if (isThumbnailAvailable) {
      if (props.thumbnail_uri) {
        onLoadThumbnail(
          props.thumbnail_uri,
          props.thumbnail_width,
          props.thumbnail_height,
          setImages,
          props.detail?.frame_references?.length
        );
      }
    }
  }, [props.index, props.thumbnail_uri]);
  return (
    <div className={`CollapseInfo ${props.className}`}>
      {props.device === DEVICES.image ? (
        <div className="CollapseInfo__image">
          <p>{props.description}</p>
          <p className="mt-0">
            Duración del análisis{" "}
            <strong className="font-medium-2 ml-3">
              {props.duration} segundos
            </strong>
          </p>
        </div>
      ) : props.device === DEVICES.video ? (
        <div className="CollapseInfo__video">
          {!props.resource ? (
            <Image
              className={"MyReportDetailBody__body__img-preview"}
              fallback={VIDEO_PLACEHOLDER_DETAIL}
            />
          ) : (
            <video
              id={"video_ai-" + props.index}
              controls
              width="320"
              height="240"
              autoPlay={false}
            >
              <source src={props.resource} type="video/mp4" />
            </video>
          )}
          {props.detail?.frame_result && (
            <TChart
              data={formattedData(
                props.detail?.frame_result?.sort(
                  (a, b) => a?.timestamp - b?.timestamp
                )
              )}
              images={images}
              index={props.index}
              type={DEVICES.video}
            />
          )}
          {isThumbnailAvailable && props.resource && (
            <>
              <div id={"thumbnail_" + props.index} />
              <TCarouselImage
                index={props.index}
                className="mt-1"
                images={images}
                thumbnails={props.detail?.frame_references}
                results={props.detail?.frame_result}
                onHandleFrame={props.onHandleFrame}
              />
            </>
          )}
        </div>
      ) : props.loading ? (
        <TSpinner />
      ) : (
        <div>
          <p>{props.description}</p>
          <p>
            <b> Duración del análisis </b>:{" "}
            {roundToTwoDecimals(props.detail?.metadata?.duration)} segundos
          </p>
          <TAudio index={props.index} src={props.resource} />
          {props.detail?.frame_result && (
            <TChart
              data={formattedDataAudio(props.detail.frame_result)}
              type={DEVICES.video}
              index={props.index}
            />
          )}
        </div>
      )}
    </div>
  );
};

TCollapseInfo.displayName = "TCollapseInfo";

TCollapseInfo.defaultProps = {
  className: "",
};

export default TCollapseInfo;
