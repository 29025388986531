import React from "react";

import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  focusVideo,
  getFramesFormatted,
} from "../../../infraestructure/utils/helpers";
import "./style.scss";
import { formatSecondsToMMSS } from "../../utilities/Function";
import { frame_selected } from "../../hooks/usePageVisit";

// Define your props here
interface CarouselImageProps {
  className?: string;
  images: any;
  thumbnails: any;
  results: any;
  index: number;
  onHandleFrame: (number) => void;
}

const TCarouselImage = (props: CarouselImageProps) => {
  const framesAvailables = getFramesFormatted(props.thumbnails, props.results);

  const onPositionVideo = (time, reference, image) => {
    if (time) {
      focusVideo("video_ai-" + props.index, time);
      props.onHandleFrame(reference);
      frame_selected({ time, image, reference });
    }
  };

  return (
    <div className={`CarouselImage ${props.className}`}>
      <p className="font-medium-2">
        Fotogramas destacados{" "}
        <Tooltip
          placement="right"
          title={
            "Los fotogramas destacados están ordenados cronológicamente según la barra del tiempo señalando las cinco barras más altas."
          }
        >
          <InfoCircleOutlined />
        </Tooltip>
      </p>
      <div className="CarouselImage-container">
        {props.images.map((image, index) => {
          const { timestamp, ai_score, reference } = framesAvailables[index];
          return (
            <div key={index}>
              <Tooltip
                title={
                  formatSecondsToMMSS(timestamp) +
                  " seg  - " +
                  ai_score.toFixed(2) +
                  " IA %"
                }
              >
                <img
                  className="d-hover"
                  alt={"img-" + index}
                  src={image}
                  onClick={() =>
                    onPositionVideo(timestamp.toFixed(3), reference, image)
                  }
                />
              </Tooltip>
            </div>
          );
        })}
      </div>
    </div>
  );
};

TCarouselImage.displayName = "TCarouselImage";

TCarouselImage.defaultProps = {
  className: "",
};

export default TCarouselImage;
