import React from "react";

import { t } from "../../utilities/Message";

import "./styles.scss";

const TRangeInfo = ({ withDescription = true, type = "full" }) => {
  if (type !== "full") {
    return (
      <div className="tooltip_info_2">
        <ul className="mt-1 mb-1" style={{ listStyle: "none" }}>
          <li style={{ color: "#4343FF" }} className="font-medium-2">
            <div className="square mr-0" style={{ background: "#4343FF" }} />{" "}
            PROBABLEMENTE NO IA
          </li>
          <li style={{ color: "#EFE384" }} className="font-medium-2">
            <div className="square mr-0" style={{ background: "#EFE384" }} /> NO
            CONCLUYENTE
          </li>
          <li style={{ color: "#FF4D4F" }} className="font-medium-2">
            <div className="square mr-0" style={{ background: "#FF4D4F" }} />{" "}
            MUY PROBABLEMENTE IA
          </li>
        </ul>
      </div>
    );
  }
  return (
    <div className="tooltip_info">
      <ul className="mt-1 mb-1" style={{ listStyle: "none" }}>
        <li style={{ color: "#0096EB" }}>{t("app.toolTipScore.low")}</li>
        <li style={{ color: "#FAAD14" }}>{t("app.toolTipScore.medium")}</li>
        <li style={{ color: "#FF4D4F" }}>{t("app.toolTipScore.big")}</li>
      </ul>
      {withDescription && (
        <p className="text-black">{t("app.toolTipScore.description")}</p>
      )}
    </div>
  );
};

export default TRangeInfo;
