export enum ApiCode {
  CODE_OK = "200",
  CODE_ERROR_PIN_TIMER = "403",
  CODE_ERROR_NOT_REGISTERED = "208",
}

export class ErrorResponse {
  code?: string;
  message?: string | any;
  constructor(
    code?: string,
    message: string = "Ocurrió un error en el sistema. Vuelve a intentarlo más tarde."
  ) {
    this.code = code;
    this.message = message;
  }
}

export interface ApiRespone {
  results?: any;
  file?: "image" | "video" | "other" | string;
}

export const FilesAvailable = {
  image: "image",
  video: "video",
  text: "text",
};
