import React from "react";

import { Skeleton, Tag, Tooltip } from "antd";

import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";

import "./style.scss";
import TProgressInfo from "../../../ProgressInfo";
import { getResults } from "../../../Result/Constants";
import { isTaskLoading, pathServer } from "../../../../utilities/Function";
import { ReactSVG } from "react-svg";
import { MESSAGES } from "../../../../utilities/Constant";

// Define your props here
interface MyReportProps {
  id: number;
  className?: string;
  name: string;
  tokens: number;
  content_type: string;
  percentage?: number;
  status?: number;
  message?: string;
  motorTags?: string;
  review?: number;
  onHandleReview?: (review: number, subTaskId: number) => void;
}

const STATUS_TASK = {
  started: -1,
  like: 1,
  dislike: 0,
};

const TRating = ({ review, onHandleReview, id }) => {
  const onHandleRating = (e, rating) => {
    e.stopPropagation();
    onHandleReview(rating, id);
  };
  if (review === STATUS_TASK.started) {
    return (
      <div className="ResultItem__rating">
        <Tooltip title={MESSAGES.like}>
          <LikeOutlined
            className="icon-medium"
            onClick={(e) => onHandleRating(e, STATUS_TASK.like)}
          />
        </Tooltip>

        <ReactSVG src={pathServer.PATH_ICONS + "ic_line.svg"} />
        <Tooltip title={MESSAGES.dislike}>
          <DislikeOutlined
            className="icon-medium"
            onClick={(e) => onHandleRating(e, STATUS_TASK.dislike)}
          />
        </Tooltip>
      </div>
    );
  }
  if (review === STATUS_TASK.like) {
    return (
      <div className="ResultItem__rating">
        <LikeOutlined
          onClick={(e) => onHandleRating(e, STATUS_TASK.started)}
          className="ResultItem__rating-selected icon-medium"
        />

        <ReactSVG src={pathServer.PATH_ICONS + "ic_line.svg"} />
        <Tooltip title={MESSAGES.dislike}>
          <DislikeOutlined
            className="icon-medium"
            onClick={(e) => onHandleRating(e, STATUS_TASK.dislike)}
          />
        </Tooltip>
      </div>
    );
  }

  if (review === STATUS_TASK.dislike) {
    return (
      <div className="ResultItem__rating">
        <Tooltip title={MESSAGES.like}>
          <LikeOutlined
            onClick={(e) => onHandleRating(e, STATUS_TASK.like)}
            className="icon-medium"
          />
        </Tooltip>

        <ReactSVG src={pathServer.PATH_ICONS + "ic_line.svg"} />

        <DislikeOutlined
          onClick={(e) => onHandleRating(e, STATUS_TASK.started)}
          className="ResultItem__rating-selected icon-medium"
        />
      </div>
    );
  }
};

const TResultItem = (props: MyReportProps) => {
  const { color, className, ...rest } = getResults(
    props.percentage,
    props.content_type
  );

  return (
    <div
      className={`ResultItem  ${props.className} ${
        isTaskLoading(props.status) ? " Result__loading" : {}
      } `}
    >
      <div className="d-flex">
        <div>
          <p style={{ fontSize: ".7rem" }}>Resultado del motor</p>
          <p style={{ fontSize: "1.2rem" }} className="font-medium-2">
            {props.name}
          </p>
        </div>
        <div className="ResultItem__tags">
          {props.motorTags &&
            props.motorTags.split(",").map((tag) => <Tag>{tag}</Tag>)}
        </div>
      </div>
      {isTaskLoading(props.status) ? (
        <Skeleton avatar paragraph={{ rows: 1 }} active />
      ) : (
        <>
          <div className="text-right">
            <p>Tokens utilizados</p>
            <p className="text-right">
              <strong>{props.tokens}</strong>
            </p>
          </div>
          <TProgressInfo
            size={60}
            strokeWidth={5}
            color={color}
            className={"ResultItem__progress " + className}
            percentage={props.percentage}
            withLink={false}
            status={props.status}
            message={props.message}
            {...rest}
          />
          <TRating
            review={props.review}
            onHandleReview={props.onHandleReview}
            id={props.id}
          />
        </>
      )}
    </div>
  );
};

TResultItem.displayName = "TResultItem";

TResultItem.defaultProps = {
  className: "",
};

export default TResultItem;
