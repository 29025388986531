import React from "react";

import "./style.scss";
import { Spin } from "antd";

// Define your props here
interface SpinnerProps {
  className?: string;
}

const TSpinner = (props: SpinnerProps) => {
  return (
    <div className={`Spinner ${props.className}`}>
      <Spin size="large" />{" "}
    </div>
  );
};

TSpinner.displayName = "TSpinner";

TSpinner.defaultProps = {
  className: "",
};

export default TSpinner;
