export const getRules = (userEmail) => [
  { pattern: /.{8,}/, message: "Al menos 8 caracteres", valid: false },
  { pattern: /[A-Z]/, message: "Al menos una mayúscula", valid: false },
  { pattern: /[a-z]/, message: "Al menos una minúscula", valid: false },
  { pattern: /[0-9]/, message: "Al menos un número", valid: false },
  {
    pattern: /[^A-Za-z0-9]/,
    message: "Al menos un carácter especial",
    valid: false,
  },
  {
    validator: (value) => !value.includes(userEmail),
    message: "No debe contener el email del usuario",
    valid: false,
  },
];
