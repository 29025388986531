import React from "react";
import { useState } from "react";
import { Modal, Button, Input } from "antd";
import { STEP_LATCH_CONTENT } from "./Constants";
import "./style.scss";
import { container } from "../../../inject_container/injection";
import { LOGIN_PORT, LoginPort } from "../../../domain/port/inbound/LoginPort";
import { useDispatch } from "react-redux";
import { setInitialPage } from "../../configuration/reduxStore/Page/actions";
import { setInitialStateLogin } from "../../configuration/reduxStore/Login/actions";
import { useHistory } from "react-router-dom";
import { ROUTES_GENERAL } from "../../utilities/Constant";

// Define your props here
interface LatchModalProps {
  className?: string;
  visible: boolean;
  loading: boolean;
  pairLatch: (code: string) => Promise<any>;
  onCancel: () => void;
}

const TLatchModal = (props: LatchModalProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [code, setCode] = useState();
  const { visible, onCancel } = props;
  const [error, setError] = useState(null);
  const loginRequest = container.get<LoginPort>(LOGIN_PORT);
  const dispatch = useDispatch();
  const history = useHistory();
  const onHandleInput = (e) => {
    setCode(e.target.value);
  };

  const next = async () => {
    setError(null);
    if (currentStep === 2) {
      const data = await props.pairLatch(code);
      if (data.code !== 200) {
        setError(data.message);
      } else {
        setCurrentStep(currentStep + 1);
      }
      return;
    } else if (currentStep === 3) {
      onCancel();
      localStorage.setItem("latch", "true");
      onLogout();
    }
    setCurrentStep(currentStep + 1);
  };

  const onLogout = () => {
    loginRequest.logout();
    dispatch(setInitialPage());
    dispatch(setInitialStateLogin());
    history.push(ROUTES_GENERAL.LOGIN);
  };

  const prev = () => {
    setError(null);
    setCurrentStep(currentStep - 1);
  };

  const { image, subtitle, description } = STEP_LATCH_CONTENT[currentStep];

  return (
    <div className={`LatchModal ${props.className}`}>
      <Modal
        title="Vincular Latch"
        open={visible}
        onCancel={onCancel}
        className="LatchModal__modal"
        footer={[
          <Button key="back" onClick={prev} disabled={currentStep === 0}>
            Anterior
          </Button>,
          <Button
            key="next"
            type="primary"
            onClick={next}
            loading={props.loading}
          >
            {currentStep === 3 ? "Finalizar" : "Siguiente"}
          </Button>,
        ]}
      >
        <div className="LatchModal__modal-body">
          <img src={image} alt={`step-${currentStep}`} className=" mt-1" />
          <h3>{subtitle}</h3>
          <p>{description}</p>
          {currentStep === 2 && (
            <Input
              onChange={onHandleInput}
              placeholder=""
              style={{ width: "200px" }}
              className="text-center d-flex mt-2 m-auto"
            />
          )}{" "}
          {error && (
            <p className="LatchModal__modal-error text-center mt-0">
              Código incorrecto
            </p>
          )}
        </div>
      </Modal>
    </div>
  );
};

TLatchModal.displayName = "TLatchModal";

TLatchModal.defaultProps = {
  className: "",
};

export default TLatchModal;
