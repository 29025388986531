import React from "react";
import { DEVICES_TAB } from "../Data";
import { Checkbox, Spin, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ReactSVG } from "react-svg";
import { pathServer } from "../../../../utilities/Function";

export const TMotors = ({
  device,
  dataModels,
  onChange,
  values,
  loadingModels,
  onCheckAllChange,
}) => {
  const deviceSelected = DEVICES_TAB.find((d) => d.key === device);
  const motorsEnabled = dataModels[deviceSelected.key];
  return (
    <div className="motors mt-0">
      {loadingModels ? (
        <div className="ml-1 mt-1">
          {" "}
          <Spin size="large" />{" "}
        </div>
      ) : (
        <>
          {motorsEnabled?.map((motor) => {
            const supportAudioandVideo =
              motor.content_type === "audio,video" && device === "video";
            return (
              <div key={motor.id}>
                <Checkbox
                  onChange={(e) => onChange(e.target.checked, motor)}
                  checked={values.includes(motor.id)}
                >
                  <p>
                    {motor?.description}
                    {supportAudioandVideo && (
                      <ReactSVG
                        src={pathServer.PATH_ICONS + "ic_device_audio.svg"}
                        className="item-icon icon-small"
                      />
                    )}
                    <Tooltip title={motor.description_long}>
                      <InfoCircleOutlined className="ml-1" />
                    </Tooltip>
                  </p>{" "}
                </Checkbox>
                <br />
              </div>
            );
          })}
          <p
            className="font-medium-2 text-blue mt-0 mb-1 d-hover"
            onClick={onCheckAllChange}
          >
            Seleccionar todos
          </p>
        </>
      )}
    </div>
  );
};

export default TMotors;
