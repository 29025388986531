import { injectable } from "inversify";
import { ImagePort } from "../../domain/port/inbound/ImagePort";
// import io from "socket.io-client";
import { sendMessageReceived } from "../../presentation/services/eventManager";
import { ImageResponse } from "../../domain/entities/Photo";
import {
  API_STATUS,
  API_STATUS_MESSAGE,
  TENANT_ID_GENERAL,
} from "../../presentation/utilities/Constant";

// var SOCKET_URL = "https://api.autoverifai.com";
const publickURL = `${process.env.REACT_APP_BASE_URL}/api/pub`;

var socket;
// var accessToken = undefined;
var currentHeaders = {};

@injectable()
export class ImageAdapter implements ImagePort {
  /**
   * Open the ws connection
   * @returns
   */
  async connected(): Promise<any> {
    // try {
    //   socket = io(SOCKET_URL, {
    //     transports: ["websocket", "polling"],
    //     secure: false,
    //     reconnection: true,
    //     //@ts-ignore
    //     cors: {
    //       origin: SOCKET_URL,
    //       methods: ["GET", "POST"],
    //       credentials: true,
    //     },
    //     allowEIO3: true,
    //   });
    //   const response = await this.checkWebSocketConnection();
    //   return response;
    // } catch (error) {
    //   throw new Error("Failed to fetch users");
    // }
  }

  /**
   *
   * @param request
   * @returns
   */
  async analyzeImage(request: File): Promise<ImageResponse> {
    try {
      return await this.uploadFile(request);
    } catch (error) {
      throw new Error("Failed to upload file");
    }
  }

  async checkResult(request: string): Promise<ImageResponse> {
    // const response = await fetch(SOCKET_URL + "/check_result?hash=" + request, {
    //   method: "GET",
    //   headers: {
    //     Authorization: "Bearer " + localStorage.getItem("accessToken"),
    //   },
    // });
    // if (response.status === 200 || response.status === 202) {
    //   const data = await response.json();
    //   return data;
    // }
    // throw new Error("Error check result" + response?.status);
    return;
  }

  /**
   *
   * @param request
   * @returns
   */
  async analyzeText(request: string): Promise<ImageResponse> {
    try {
      return;
    } catch (error) {
      throw new Error("Failed to analyze Text");
    }
  }

  getAuth = async (sid) => {
    // var formData = new FormData();
    // formData.append("sid", sid);
    // const response = await fetch(SOCKET_URL + "/auth", {
    //   method: "POST",
    //   body: formData,
    // });
    // const accessToken = await response.json();
    // socket.on("message", (data) => {
    //   console.log("DATA FROM WEBSOCKET MESSAGE => ", data);
    // });
    // socket.on("finish", (data) => {
    //   console.log("DATA FROM WEBSOCKET MESSAGE FINISH => ", data);
    //   sendMessageReceived(data);
    //   //Close the conection after receive the ws response finish
    //   // this.closeConnection();
    // });
    // currentHeaders = {
    //   Authorization: "Bearer " + accessToken.token,
    // };
    // localStorage.setItem("accessToken", accessToken.token);
  };

  checkWebSocketConnection = (): Promise<boolean> => {
    return new Promise((resolve) => {
      const isConnected = socket.connected;

      if (isConnected) {
        resolve(false);
      } else {
        socket.on("connect", async () => {
          await this.getAuth(socket.id);
          resolve(true);
          // sendMessageReceived("connected");
          socket.off("connect");
        });

        socket.on("connect_error", () => {
          resolve(false);
          // sendMessageReceived("connect_error");
          socket.off("connect_error");
        });
      }
    });
  };

  closeConnection = async () => {
    return new Promise((resolve) => {
      const isConnected = socket.connected;
      if (isConnected) {
        socket.off("connect");
        socket.disconnect();
      }
    });
  };

  uploadFile = async (file: File): Promise<ImageResponse> => {
    // //Build the payload using the ID from the current Socket
    // var formData = new FormData();
    // formData.append("file", file);

    // //Send post service
    // const response = await fetch(SOCKET_URL + "/upload_file", {
    //   method: "POST",
    //   body: formData,
    //   headers: currentHeaders,
    // });

    // const data = await response.json();

    // return data;
    return;
  };

  uploadText = async (text: string) => {
    // //Prepare your data
    // var formData = new FormData();
    // formData.append("params", text);
    // formData.append("algorithm", "text");

    // //Send post service

    // const response = await fetch(SOCKET_URL + "/upload_text", {
    //   method: "POST",
    //   body: formData,
    //   headers: currentHeaders,
    // });

    // const data = await response.json();

    // return data;
    return;
  };

  //TODO: refactor all the v2

  /**
   *
   * @param request
   * @returns
   */
  async analyzeCOPImage(request: any): Promise<any> {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    };

    try {
      var formData = new FormData();
      formData.append("content_type", request.deviceSelected);
      formData.append("file", request.file);
      formData.append("tags", request.tags);
      //@ts-ignore
      formData.append("algorithm_list", request.algorithm_list);

      //Send post service
      const response = await fetch(
        publickURL + "/verifai/analysis/file/" + TENANT_ID_GENERAL,
        {
          method: "POST",
          body: formData,
          headers: headers,
        }
      );

      const data = await response.json();
      if (
        response.status === API_STATUS.success ||
        response.status === API_STATUS.accepted
      ) {
        return { ...data, status: API_STATUS.success };
      } else {
        return { ...data, status: API_STATUS.failed, error: data.detail };
      }
    } catch (error) {
      throw new Error("Failed to upload COP file");
    }
  }
}
