import React from "react";

import "./style.scss";

// Define your props here
interface MyReportProps {
  className?: string;
  title: string;
  withBackground?: boolean;
}

const TMyReportTitleItem = (props: MyReportProps) => {
  return (
    <div
      className={`TMyReportTitleItem ${props.className} ${
        props.withBackground ? " withBackground" : " "
      }`}
    >
      <p className="font-medium-2">{props.title}</p>
    </div>
  );
};

TMyReportTitleItem.displayName = "TMyReportTitleItem";

TMyReportTitleItem.defaultProps = {
  className: "",
};

export default TMyReportTitleItem;
