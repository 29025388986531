import React, { useContext, useEffect } from "react";
import { withLanding } from "../../hocs/withLanding";
import { useHistory } from "react-router-dom";
import TNavigator from "../../components/Navigator";

import { ROUTES_GENERAL } from "../../utilities/Constant";

import TMyReport from "../../components/MyReport";
import "./styles.scss";
import { page_view } from "../../hooks/usePageVisit";
import { EVENTS_TGM } from "../../utilities/Events";
import { LanguageContext } from "../../context/i18n/LanguageProvider";

const ResultPage = () => {
  const history = useHistory();
  const { locale } = useContext(LanguageContext);

  const goDetail = (record, currentPage) => {
    history.push({
      pathname: ROUTES_GENERAL.REPORT + "/" + record.key,
      state: {
        currentPage,
      },
    });
  };

  useEffect(() => {
    page_view({ page_type: EVENTS_TGM.report, locale });
  }, []);

  return (
    <div className="result-page max-width">
      <TNavigator
        customBack={() => history.push(ROUTES_GENERAL.HOME_PAGE)}
        page="Mis Informes"
        withSearch={false}
        className="mt-2 mb-1"
      />
      <div className="max-width how_content">
        <TMyReport goDetail={goDetail} />
      </div>
    </div>
  );
};

export default withLanding(ResultPage);
