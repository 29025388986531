import React from "react";
import { UploadOutlined } from "@ant-design/icons";

import { Progress } from "antd";

import "./style.scss";

// Define your props here
interface ProgressContentProps {
  className?: string;
  progress: number;
}

const TProgressContent = (props: ProgressContentProps) => {
  if (props.progress <= 0)
    return (
      <div className="drag-overlay">
        <div className="drag-overlay_icon text-center">
          <UploadOutlined style={{ color: "#041E55", fontSize: "2rem" }} />
          <p>Upload content</p>
        </div>
        <Progress
          percent={props.progress}
          status={props.progress === 100 ? "success" : "active"}
        />
      </div>
    );
};

TProgressContent.displayName = "TProgressContent";

TProgressContent.defaultProps = {
  className: "",
};

export default TProgressContent;
