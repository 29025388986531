import { Result, Button } from "antd";
import React from "react";
import { withLanding } from "../../hocs/withLanding";
import { Link } from "react-router-dom";
import "./styles.scss";
import TFooter from "../../components/Footer";

const ErrorPage = () => {
  return (
    <main>
      <Result
        className="error_page"
        status="404"
        title="404"
        subTitle="La página que buscas no existe."
        extra={
          <Button type="primary">
            {" "}
            <Link to="/login">Go Home</Link>{" "}
          </Button>
        }
      />
      <TFooter />
    </main>
  );
};

export default ErrorPage;
