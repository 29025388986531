import React, { useEffect, useRef, useState } from "react";
import { CaretRightOutlined, PauseOutlined } from "@ant-design/icons";
import Wavesurfer from "wavesurfer.js";
import Timeline from "wavesurfer.js/dist/plugins/timeline.esm.js";
import "./style.scss";
import { ReactSVG } from "react-svg";
import { pathServer } from "../../utilities/Function";

const TAudio = ({ src, index }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const waveform = useRef(null);

  const getAudioDuration = () => {
    if (waveform.current) {
      return waveform.current.getDuration();
    }
    return 0;
  };
  if (waveform.current) {
    console.log("getAudioDuration", getAudioDuration());
  }

  useEffect(() => {
    if (!waveform.current) {
      waveform.current = Wavesurfer.create({
        container: "#waveform-" + index,
        waveColor: "#567FFF",
        barGap: 2,
        barWidth: 3,
        barRadius: 3,
        cursorWidth: 3,
        cursorColor: "#567FFF",
        plugins: [
          // Agrega el plugin de timeline
          Timeline.create({
            container: "#wave-timeline-" + index,
          }),
        ],
      });

      waveform.current.load(src);
    }
  }, []);

  const playAudio = () => {
    // Check if the audio is already playing
    if (waveform.current.isPlaying()) {
      setIsPlaying(false);
      waveform.current.pause();
    } else {
      waveform.current.play();
      setIsPlaying(true);
    }
  };

  return (
    <div className="TAudio">
      <div id={"waveform-" + index} />
      <div id={"wave-timeline-" + index} />
      <div className="TAudio__actions">
        <ReactSVG
          className=" pointer"
          src={pathServer.PATH_ICONS + "ic_10sec-down.svg"}
        />
        <ReactSVG
          className=" pointer"
          src={pathServer.PATH_ICONS + "ic_5sec-down.svg"}
        />
        {isPlaying ? (
          <PauseOutlined onClick={playAudio} className="icon-large pointer" />
        ) : (
          <CaretRightOutlined
            onClick={playAudio}
            className="icon-large pointer"
          />
        )}
        <ReactSVG
          className=" pointer"
          src={pathServer.PATH_ICONS + "ic_5sec-up.svg"}
        />
        <ReactSVG
          className=" pointer"
          src={pathServer.PATH_ICONS + "ic_10sec-up.svg"}
        />
      </div>
    </div>
  );
};

export default TAudio;
