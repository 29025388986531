import { injectable } from "inversify";
import { LoginPort } from "../../domain/port/inbound/LoginPort";
import { TENANT_ID_GENERAL } from "../../presentation/utilities/Constant";

const baseUrl = `${process.env.REACT_APP_BASE_URL}/api/auth/jwt`;
const commonUrl = `${process.env.REACT_APP_BASE_URL}/api/pub/users`;

@injectable()
export class LoginAdapter implements LoginPort {
  //TODO: remove attributes not necceary in the request
  updateUser = async (values: any) => {
    const myHeaders = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    };
    const response = await fetch(commonUrl + "/me", {
      method: "PATCH",
      headers: myHeaders,
      body: JSON.stringify(values),
    });

    let responseData = await response.json();

    return {
      data: responseData,
      status: response.status,
    };
  };
  getCurrentUser = async () => {
    const myHeaders = {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    };
    const response = await fetch(
      commonUrl + "/me_extended/" + TENANT_ID_GENERAL,
      {
        method: "GET",
        headers: myHeaders,
      }
    );

    let responseData = await response.json();

    return {
      data: responseData,
      status: response.status,
    };
  };
  login = async (values: any) => {
    const { email, password } = values;
    const formData = new FormData();
    formData.append("username", email);
    formData.append("password", password);

    const response = await fetch(baseUrl + "/login", {
      method: "POST",
      credentials: "include",
      body: formData,
    });

    let responseData = await response.json();

    //TODO: improve the way to save the token - refresh token
    if (responseData.access_token) {
      localStorage.setItem("access_token", responseData.access_token);
      responseData = { ...responseData };
    }
    return {
      data: responseData,
      status: response.status,
    };
  };

  refreshToken = async () => {
    const response = await fetch(baseUrl + "/refresh", {
      method: "POST",
      credentials: "include",
    });

    let responseData = await response.json();

    if (responseData.access_token) {
      localStorage.setItem("access_token", responseData.access_token);
      responseData = { ...responseData };
    }
    return {
      data: responseData,
      status: response.status,
    };
  };

  logout = async () => {
    let myHeaders;

    myHeaders = {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    };

    await fetch(baseUrl + "/logout", {
      method: "POST",
      headers: myHeaders,
    });

    localStorage.removeItem("access_token");
    // window.location.reload();
  };
}
