import React, { useContext, useEffect } from "react";
import { withLanding } from "../../hocs/withLanding";

import TNavigator from "../../components/Navigator";

import "./styles.scss";
import { Link } from "react-router-dom";
import { ROUTES_GENERAL } from "../../utilities/Constant";
import { page_view } from "../../hooks/usePageVisit";
import { EVENTS_TGM } from "../../utilities/Events";
import { LanguageContext } from "../../context/i18n/LanguageProvider";

const ConditionsPage = () => {
  const { locale } = useContext(LanguageContext);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    page_view({ page_type: EVENTS_TGM.how_it_works, locale });
  }, []);
  return (
    <div className="conditions-page how-it-works mb-2">
      <TNavigator page="Condiciones Generales" withSearch={false} />
      <div className="max-width  mb-3">
        <p className="mt-2 mb-2">
          1. OBJETO DE LAS CONDICIONES GENERALES Y SU ACEPTACI&Oacute;N
        </p>

        <p>1.1. Objeto</p>

        <p>
          Las Condiciones Generales descritas a continuaci&oacute;n (en
          adelante, las &quot;Condiciones Generales&quot;) regulan la
          relaci&oacute;n entre TELEF&Oacute;NICA INNOVACI&Oacute;N DIGITAL,
          S.L.U., empresa con NIF n&ordm; B83188953, domicilio social en Ronda
          de la Comunicaci&oacute;n, s/n, Edificio Central, 28050, Madrid, e
          inscrita en el Registro Mercantil de Madrid al tomo 42.773, folio 213,
          inscripci&oacute;n 79, hoja M-296237 (en adelante,
          &quot;TELEF&Oacute;NICA&quot;) y el cliente (en adelante, el
          &ldquo;CLIENTE&rdquo;) que accede y usa el servicio VerifAI (en
          adelante, el &ldquo;Servicio&rdquo; o &ldquo;VerifAI&rdquo;).
        </p>

        <p>
          Las presentes condiciones regulan los t&eacute;rminos generales
          aplicados al uso del Servicio por el Cliente (en adelante, el
          &ldquo;Cliente&rdquo;). La identificaci&oacute;n del Cliente y las
          condiciones particulares aplicables al Servicio se regular&aacute;n en
          el correspondiente contrato y/o pedido formalizado entre
          Telef&oacute;nica y el Cliente.
        </p>

        <p>
          El Cliente ser&aacute; responsable por el uso del Servicio por parte
          de sus empleados, colaboradores, terceros que accedan al Servicio por
          cuenta del Cliente (en adelante, los &ldquo;USUARIOS&rdquo;), a
          quienes les resultar&aacute; de aplicaci&oacute;n las presente
          Condiciones Generales. Por lo anterior, el Cliente debe hacer conocer
          a todos sus USUARIOS los t&eacute;rminos de las presentes Condiciones
          Generales. El Cliente ser&aacute; el &uacute;nico responsable frente a
          TELEF&Oacute;NICA por el uso del Servicio por parte de sus USUARIOS.
          Cualquier referencia a los USUARIOS en estas Condiciones Generales se
          entender&aacute; tambi&eacute;n como referencia al Cliente, y por lo
          tanto, responsabilidad de este.
        </p>

        <p>2. Aceptaci&oacute;n</p>

        <p>
          La aceptaci&oacute;n, sin reservas, de las presentes Condiciones
          Generales es indispensable para el uso del Servicio por parte del
          USUARIO. En tal sentido, el USUARIO manifiesta haber le&iacute;do,
          entendido y aceptado las presentes Condiciones Generales con
          car&aacute;cter previo al acceso y uso del Servicio a trav&eacute;s de
          los mecanismos habilitados al efecto.
        </p>

        <p>
          Estas Condiciones Generales estar&aacute;n disponibles a los USUARIO
          en el portal de acceso al Servicio.
        </p>

        <p>
          En el caso de que el USUARIO no est&eacute; conforme con estas
          Condiciones Generales debe cesar su uso de VerifAI de forma inmediata.
        </p>

        <p>
          La utilizaci&oacute;n del Servicio se encuentra sometida igualmente a
          cuantos avisos, reglamentos de uso e instrucciones t&eacute;cnicas
          fueran puestos en conocimiento del USUARIO, por cualquier medio y en
          cualquier momento, que completan lo previsto en estas Condiciones
          Generales en cuanto no se opongan a ellas.
        </p>

        <p>3. DESCRIPCI&Oacute;N DE VERIFAI</p>

        <p>
          VerifAI es una soluci&oacute;n desarrollada por TELEF&Oacute;NICA que
          tiene como objetivo detectar si un determinado contenido proporcionado
          por el Usuario puede haber sido generado o modificado de alguna forma
          por sistemas de Inteligencia Artificial (&ldquo;IA&rdquo;).
        </p>

        <p>
          De este modo. VerifAI genera un reporte acerca del contenido
          consultado proporcionando un resultado desglosado en uno o m&aacute;s
          motores de an&aacute;lisis, los cuales indican de forma
          individualizada la probabilidad de que el contenido consultado se haya
          generado o modificado mediante IA, seg&uacute;n la estimaci&oacute;n
          obtenida de cada motor.
        </p>

        <p>
          El resultado se ofrece a t&iacute;tulo estimativo. TELEF&Oacute;NICA
          NO ofrece ninguna garant&iacute;a y no proporciona ninguna conformidad
          sobre la exactitud o fiabilidad del resultado.
        </p>

        <p>
          Para utilizar el Servicio, el USUARIO podr&aacute; proporcionar el
          contenido en formato de imagen, video o audio (en adelante, el
          &ldquo;Contenido&rdquo;), sin perjuicio de que TELEF&Oacute;NICA pueda
          modificar o habilitar nuevos formatos de consulta de forma
          discrecional.
        </p>

        <p>
          VerifAI est&aacute; desarrollado sobre licencias open source de
          terceros y bases de datos p&uacute;blicas, entrenadas por
          TELEF&Oacute;NICA entre las que se encuentran:
        </p>

        <p>&middot; Reddit_eli5 de ELI5 con licencia BSD-License</p>

        <p>&middot; Open_qa de WikiQA con licencia PWC Custom</p>

        <p>&middot; Wiki_csai de Wikipedia con licencia CC-BY-SA</p>

        <p>&middot; Medicine de Medical Dialog</p>

        <p>&middot; Finance de FiQAa</p>

        <p>&middot; AI-image-detector de umm-maybe con licencia CC-BY-4.0</p>

        <p>4. NUESTRO COMPROMISO CON VERIFAI</p>

        <p>
          A trav&eacute;s de VerifAI TELEF&Oacute;NICA tiene como
          intenci&oacute;n proporcionar una herramienta que permita a los
          clientes interesados obtener un reporte estimado de an&aacute;lisis
          sobre si determinada informaci&oacute;n o contenido es originalmente
          desarrollado por una persona o si es resultado del uso de Inteligencia
          Artificial, y as&iacute; ayudar a formar una opini&oacute;n m&aacute;s
          realista acerca de dicho contenido e informaci&oacute;n relacionada
          con &eacute;ste.
        </p>

        <p>En el marco de VerifAI, TELEF&Oacute;NICA se compromete a:</p>

        <p>
          i. No compartir el Contenido y/o el resultado con terceros.Prestar el
          Servicio de conformidad con lo descrito en estas Condiciones
          Generales. Recordamos que el resultado no es conclusivo, exacto ni
          fiable, es un punto de partida a trav&eacute;s del cual el Usuario
          puede hacer, por su cuenta y riesgo, un an&aacute;lisis m&aacute;s
          profundo acerca del origen del Contenido.
        </p>

        <p>
          ii. proporcionar el Servicio conforme a los t&eacute;rminos dispuestos
          en las presentes Condiciones Generales.
        </p>

        <p>
          iii. destinar sus mejores esfuerzos para mantener el Servicio
          operativo permitiendo la continuidad de este en condiciones
          t&eacute;cnicas y de seguridad apropiadas, salvo interrupciones
          temporales por servicios de mantenimiento, problemas t&eacute;cnicos o
          inform&aacute;ticos como ca&iacute;das de internet producidas por
          cualquier causa, ataques inform&aacute;ticos y situaciones
          an&aacute;logas que hagan imposible de forma temporal la
          prestaci&oacute;n del Servicio, o que sean ajenas a TELEF&Oacute;NICA.
          Este se restaurar&aacute; cuando las incidencias se hayan solventado.
        </p>

        <p>
          iv. Mantener un canal de contacto a trav&eacute;s del cual el USUARIO
          podr&aacute; contactarnos para consultas relacionadas con el Servicio
          y/o acerca del resultado de la Consulta, en caso
        </p>

        <p>
          de que no est&eacute; de acuerdo con el mismo y/o tenga dudas al
          respecto. TELEF&Oacute;NICA se reserva el derecho (pero no la
          obligaci&oacute;n) a habilitar mecanismos para que bien
          TELEF&Oacute;NICA, terceros, y/o el propio USUARIO puedan (1)
          solicitar la revisi&oacute;n del resultado para un Contenido concreto,
          as&iacute; como (2) listar firmas digitales o hashes de Contenidos
          sobre los que el Servicio no funcionar&aacute; y sobre los que no se
          devolver&aacute; resultado o estimaci&oacute;n alguna. Estos canales
          de contacto, y los mecanismos indicados, podr&aacute;n estar sujetos a
          condiciones particulares que deber&aacute;n de ser le&iacute;das y
          aceptadas antes de su utilizaci&oacute;n.
        </p>

        <p>
          5. TUS COMPROMISOS Y OBLIGACIONES COMO CLIENTE Y USUARIO DE VERIFAI
        </p>

        <p>El USUARIO se compromete a:</p>

        <p>
          a) Hacer un uso del Servicio de una forma correcta, diligente y acorde
          con la ley y con las presentes Condiciones Generales y, en particular,
          a abstenerse de utilizarlo con fines distintos de los expresamente
          permitidos en estas Condiciones Generales, en otras condiciones
          aplicables o del aviso legal de la web de VerifAI.
        </p>

        <p>
          b) Hacerse &uacute;nico responsable del Contenido que consulta a
          trav&eacute;s de VerifAI y de la divulgaci&oacute;n que haga del
          resultado consultado, de conformidad con lo establecido en estas
          Condiciones Generales
        </p>

        <p>
          c) Poner en conocimiento de TELEF&Oacute;NICA aquellas incidencias que
          le impidan o dificulten poder acceder al Servicio. As&iacute; como,
          cualquier incidencia, que el Usuario quiera comunicar a
          TELEF&Oacute;NICA debido al resultado obtenido y/o acerca de un
          Contenido que resulte ser de titularidad del Usuario y que este
          pretenda limitar su consulta amparado en un derecho leg&iacute;timo.
        </p>

        <p>
          d) Cumplir con todo lo establecido en estas Condiciones Generales y en
          la legislaci&oacute;n aplicable.
        </p>

        <p>6. USO CORRECTO DE LOS SERVICIOS</p>

        <p>
          El USUARIO se compromete a realizar un uso razonable de las
          funcionalidades y prestaciones que comprende el Servicio.
        </p>

        <p>
          En general, el USUARIO acepta que no violar&aacute; ninguna ley,
          contrato, propiedad intelectual o industrial de TELEF&Oacute;NICA y/o
          de terceros, derechos de imagen y protecci&oacute;n de datos, entre
          otros, durante el uso del Servicio, y declara que es el &uacute;nico
          responsable por el Contenido consultado durante la utilizaci&oacute;n
          del Servicio y por cualquier divulgaci&oacute;n que haga de los
          resultados del Servicio. En l&iacute;nea con lo anterior, de forma
          particular, el USUARIO se compromete a:
        </p>

        <p>
          i No consultar y difundir contenidos que supongan una
          vulneraci&oacute;n en cualquier forma de los derechos fundamentales al
          honor, a la intimidad personal y familiar o a la propia imagen de
          terceros y, muy especialmente, de los menores de edad;
        </p>

        <p>
          ii No consultar y difundir contenidos que vulneren el secreto de las
          comunicaciones o que supongan una infracci&oacute;n de los derechos de
          propiedad intelectual e industrial o de las normas reguladoras de la
          protecci&oacute;n de datos de car&aacute;cter general;
        </p>

        <p>
          iii No usar el Servicio para cualquier prop&oacute;sito ilegal o no
          autorizado, incluida la creaci&oacute;n o exhibici&oacute;n de
          contenido ilegal, como contenido que pueda involucrar
          explotaci&oacute;n sexual infantil, o alentar o promover cualquier la
          violencia y/o actividad que viole las Condiciones Generales;
        </p>

        <p>
          iv No reproducir, copiar, distribuir, permitir el acceso del
          p&uacute;blico a trav&eacute;s de cualquier modalidad de
          comunicaci&oacute;n p&uacute;blica, transformar o modificar los
          contenidos protegidos en el Servicio, a menos que se cuente con la
          autorizaci&oacute;n del titular de los correspondientes derechos o
          ello resulte legalmente permitido; ni suprimir, eludir o manipular el
          &quot;copyright&quot; y dem&aacute;s datos identificativos de los
          derechos de los titulares incorporados en los contenidos del Servicio,
          as&iacute; como los dispositivos t&eacute;cnicos de protecci&oacute;n,
          las huellas digitales o cualesquiera mecanismos de informaci&oacute;n
          que pudieren contener los mismos;
        </p>

        <p>
          v No usar el Servicio de cualquier manera que pueda interferir,
          interrumpir, afectar negativamente o impedir a otros usuarios de
          disfrutar plenamente del Servicio.
        </p>

        <p>vi No distribuir spam mediante la subida de Contenido;</p>

        <p>
          vii No usar el Servicio, incluso mediante la difusi&oacute;n de
          cualquier software o la interacci&oacute;n con cualquier API, de tal
          forma que pueda da&ntilde;ar, deshabilitar, sobrecargar o deteriorar
          el funcionamiento del Servicio de cualquier manera;
        </p>

        <p>
          viii No omitir o ignorar las instrucciones que controlan el acceso al
          Servicio, incluido el intento de eludir cualquier sistema de
          limitaci&oacute;n de velocidad mediante el uso de m&uacute;ltiples
          claves de API, dirigiendo el tr&aacute;fico a trav&eacute;s de
          m&uacute;ltiples direcciones IP u ofuscando de otro modo la fuente de
          tr&aacute;fico que env&iacute;a a TELEF&Oacute;NICA;
        </p>

        <p>
          ix No usar cualquier miner&iacute;a de datos, robot, ara&ntilde;a,
          rastreador, raspador, script, extensi&oacute;n del navegador, lector
          fuera de l&iacute;nea u otro medio o interfaz automatizada no
          autorizada por TELEF&Oacute;NICA para acceder al Servicio, extraer
          datos o interferir o modificar la representaci&oacute;n de las
          p&aacute;ginas o funcionalidades del Servicio;
        </p>

        <p>
          x No realizar ingenier&iacute;a inversa, duplicar, descompilar,
          desensamblar o decodificar cualquier aspecto del Servicio, o hacer
          cualquier cosa que pueda descubrir el c&oacute;digo fuente o eludir o
          eludir las medidas empleadas para prevenir o limitar el acceso a
          cualquier servicio, &aacute;rea o c&oacute;digo del Servicio;
        </p>

        <p>
          xi No vender o revender el Servicio o intentar eludir cualquier
          sistema de tarifas de TELEF&Oacute;NICA, si fuera aplicable;
        </p>

        <p>
          xii No utilizar el Servicio o los datos recopilados del Servicio para
          cualquier actividad publicitaria o de marketing directo (incluidos,
          entre otros, el marketing por correo electr&oacute;nico, el marketing
          por SMS y el telemarketing);
        </p>

        <p>
          El incumplimiento por el USUARIO de cualquiera de estas condiciones de
          uso podr&aacute; llevar aparejada la adopci&oacute;n por
          TELEF&Oacute;NICA de las medidas oportunas amparadas en Derecho y en
          el ejercicio de sus derechos u obligaciones, pudiendo llegar a
          restringir el acceso del USUARIO al Servicio, sin que medie
          posibilidad de indemnizaci&oacute;n alguna por los da&ntilde;os y
          perjuicios causados.
        </p>

        <p>
          El USUARIO ser&aacute; el &uacute;nico responsable por cualquier uso
          del Servicio contrario a lo expuesto en estas Condiciones Generales
          y/o a la legislaci&oacute;n vigente, respondiendo ante
          TELEF&Oacute;NICA y/o terceros por cualquier tipo de da&ntilde;o
          derivado de un incumplimiento por parte del USUARIO, sin cualquier
          tipo de limitaci&oacute;n. En particular, el USUARIO ser&aacute;
          responsable de cualquier reproducci&oacute;n y posterior
          comunicaci&oacute;n al p&uacute;blico de los contenidos mostrados y/o
          facilitados por el Servicio en cualquier medio y/o formato.
        </p>

        <p>7. INICIO DEL SERVICIO Y DURACI&Oacute;N</p>

        <p>
          Las presentes Condiciones Generales entre TELEF&Oacute;NICA y el
          USUARIO deber&aacute;n ser aceptadas por el Cliente de VerifAI en el
          momento de la contrataci&oacute;n de VerifAI y ser&aacute;n de
          aplicaci&oacute;n a todos los USUARIOS del Cliente durante el tiempo
          que de uso del Servicio.
        </p>

        <p>
          En el caso de que el USUARIO no est&eacute; conforme con las
          Condiciones Generales del Servicio NO deber&aacute; usarlo.
        </p>

        <p>8. PROTECCI&Oacute;N DE DATOS DE CAR&Aacute;CTER PERSONAL</p>

        <p>
          Cualquier tratamiento de datos personales que realice
          TELEF&Oacute;NICA en la prestaci&oacute;n del Servicio, se
          realizar&aacute; conforme establecido en la Pol&iacute;tica de
          Privacidad y en cumplimiento de lo establecido en la
          legislaci&oacute;n aplicable y de las indicaciones del Cliente.
        </p>

        <p>9. PROPIEDAD INDUSTRIAL E INTELECTUAL</p>

        <p>a) De TELEF&Oacute;NICA</p>

        <p>
          El USUARIO acepta que todos los elementos integrados en el Servicio de
          TELEF&Oacute;NICA, incluyendo, sin limitaci&oacute;n, apariencia,
          im&aacute;genes, materiales, logos, textos, archivos y dise&ntilde;os,
          est&aacute;n protegidos por la legislaci&oacute;n sobre los Derechos
          de Propiedad Intelectual e Industrial y que los derechos sobre los
          mismos corresponden bien a TELEF&Oacute;NICA y/o, en su caso, a sus
          afiliadas, colaboradores y/o a sus licenciantes.
        </p>

        <p>
          A este respecto, TELEF&Oacute;NICA y/o, en su caso, sus afiliadas,
          colaboradores y/o licenciantes, conservar&aacute;n la plena
          titularidad de los Derechos de Propiedad Intelectual e industrial que
          les pertenezcan al tiempo de ejecutar las presentes Condiciones
          Generales y les corresponder&aacute;n, igualmente, los Derechos de
          Propiedad Intelectual e industrial relativos a cualquier contenido,
          software, tecnolog&iacute;a y/o documentaci&oacute;n, creados,
          desarrollados, modificados, mejorados o transformados como
          consecuencia de la prestaci&oacute;n del Servicio.
        </p>

        <p>
          El USUARIO no podr&aacute; copiar, imitar o usar, parcial o en su
          totalidad, los elementos sujetos a Derechos de Propiedad Intelectual e
          industrial de TELEF&Oacute;NICA sin su autorizaci&oacute;n previa por
          escrito.
        </p>

        <p>b) Del USUARIO</p>

        <p>
          El USUARIO es el &uacute;nico responsable por el Contenido consultado.
        </p>

        <p>
          c) Reclamaciones por infracciones de Derechos de Propiedad Intelectual
          e Industrial
        </p>

        <p>
          El USUARIO ser&aacute; el &uacute;nico responsable por cualquier
          infracci&oacute;n a los derechos de propiedad intelectual y/o
          industrial de TELEF&Oacute;NICA y/o de terceros derivados del
          Contenido consultado, incluida la divulgaci&oacute;n que el Usuario
          haga del resultado, respondiendo el USUARIO integralmente por los
          da&ntilde;os causados. TELEF&Oacute;NICA se exime de cualquier
          responsabilidad en este sentido.
        </p>

        <p>10. INDEMNIDAD</p>

        <p>
          El USUARIO acepta mantener indemne, defender y eximir de toda
          responsabilidad a TELEF&Oacute;NICA, as&iacute; como a sus empleados,
          consultores, proveedores, accionistas, filiales, cesionarios, etc. (en
          adelante, las &quot;Partes de TELEF&Oacute;NICA&quot;), frente a todas
          las reclamaciones, da&ntilde;os, laudos, sentencias, p&eacute;rdidas,
          responsabilidades, obligaciones, multas, intereses, honorarios, gastos
          (incluidos, entre otros, los honorarios y gastos de abogados) y los
          costes
        </p>

        <p>
          relacionados (incluidos, entre otros, las costas judiciales, tasas
          administrativas, costes de liquidaci&oacute;n y cualesquiera otros
          producidos en el curso de perseguir la indemnizaci&oacute;n o a
          consecuencia de activaci&oacute;n de primas de seguro por dicha
          causa), de todo tipo y naturaleza, ya sean conocidos o desconocidos,
          previstos o imprevistos (en adelante, los &quot;Reclamaciones&quot;),
          incluidos, entre otros, da&ntilde;os causados por, surgidos de o
          relacionados con (a) su uso o mal uso del Servicio y su Contenido (b)
          cualquier comentario o acci&oacute;n que realice en relaci&oacute;n
          con el Servicio y/o con el Contenido consultado, (c) su
          violaci&oacute;n o incumplimiento de cualquier cl&aacute;usula de
          estas Condiciones Generales o la ley aplicable, (d) la
          violaci&oacute;n de los derechos u obligaciones de un tercero, (e)
          negligencia o dolo y/o (f) cualquier otro derecho que no pueda
          limitarse por ley.
        </p>

        <p>
          El USUARIO acepta notificar de inmediato a TELEF&Oacute;NICA la
          existencia de cualquier reclamaci&oacute;n o similar de las citadas
          previamente, y cooperar con las Partes de TELEF&Oacute;NICA en la
          defensa de dichas Reclamaciones. Adem&aacute;s, acepta que las Partes
          de TELEF&Oacute;NICA tendr&aacute;n el control de la defensa o
          liquidaci&oacute;n de cualquier Reclamaci&oacute;n. Esta
          indemnizaci&oacute;n es adicional a cualquier otra
          indemnizaci&oacute;n establecida en un acuerdo escrito entre el
          USUARIO y TELEF&Oacute;NICA.
        </p>

        <p>11. DESCARGO</p>

        <p>
          Si bien TELEF&Oacute;NICA hace sus mejores esfuerzos para que el
          acceso y uso del Servicio por el USUARIO sean seguros,
          TELEF&Oacute;NICA no puede garantizar que el Servicio, as&iacute; como
          cualquier acci&oacute;n del USUARIO utilizando el Servicio o los
          servidores de los proveedores de servicios de TELEF&Oacute;NICA para
          habilitarlo, est&eacute;n libres de virus u otros componentes
          da&ntilde;inos o maliciosos.
        </p>

        <p>
          En la medida en que la ley lo permita, el USUARIO acepta que en
          ning&uacute;n caso TELEF&Oacute;NICA o sus proveedores de servicios
          y/o licenciatarios ser&aacute;n responsables ante &eacute;l o
          cualquier tercero por (a) cualquier p&eacute;rdida de ganancias o
          cualquier da&ntilde;o indirecto o consecuencial que surja de estas
          Condiciones Generales o el Servicio, productos o sitios y productos de
          terceros; (b) sucesos inevitables debidos a fuerza mayor; (c)
          cualquier da&ntilde;o relacionado con la p&eacute;rdida de ingresos,
          p&eacute;rdida de producci&oacute;n, p&eacute;rdida de beneficios,
          p&eacute;rdida de negocio, p&eacute;rdida de ahorro anticipado,
          p&eacute;rdida de oportunidad, p&eacute;rdida de fondo de comercio o
          p&eacute;rdida de datos, ya sea directa o indirecta, e incluso si
          fuera previsible o si TELEF&Oacute;NICA o sus proveedores de servicios
          hubieran sido advertidos de la posibilidad de dichos da&ntilde;os; y/o
          (d) cualquier otra Reclamaci&oacute;n, demanda o da&ntilde;o de
          cualquier tipo que resulte de o est&eacute; relacionado con estas
          Condiciones Generales que surjan de: (i) errores del USUARIO, aun
          siendo involuntarios o sin mala fe; (ii) fallo del servidor o
          interrupci&oacute;n de los procesos de datos; (iii) accesos o usos no
          autorizados; (iv) cualquier actividad no autorizada de terceros,
          incluido, entre otros, el uso de virus, phishing, fuerza bruta u otros
          medios de ataque contra el Servicio; (v) cualquier vulnerabilidad o
          cualquier tipo de fallo, comportamiento anormal del software
          involucrado o cualquier otro problema que tenga p&eacute;rdidas o
          lesiones como resultado.
        </p>

        <p>
          El Servicio que se oferta cumple con lo dispuesto con el ordenamiento
          jur&iacute;dico espa&ntilde;ol.
        </p>

        <p>12. CONFORMIDAD DEL SERVICIO</p>

        <p>
          El acceso y uso del Servicio y de los distintos elementos del Servicio
          por el USUARIO se realiza bajo su propia direcci&oacute;n y riesgo,
          siendo el USUARIO el &uacute;nico responsable de cualquier da&ntilde;o
          a su sistema inform&aacute;tico o dispositivo m&oacute;vil o
          p&eacute;rdida de datos que pueda resultar de ello.
        </p>

        <p>
          El USUARIO entiende y acepta que el Servicio se proporciona por
          TELEF&Oacute;NICA tal cual se describe en las presentes Condiciones
          Generales y en la p&aacute;gina web del Servicio. sin mayores
          garant&iacute;as o funcionalidades adicionales a las descritas.
        </p>

        <p>
          TELEF&Oacute;NICA no proporciona garant&iacute;a alguna respecto al
          resultado de la Consulta, sin limitaci&oacute;n, en lo relativo a su
          exactitud, fiabilidad, veracidad o utilidad pues, conforme mencionado
          anteriormente, se trata de una estimaci&oacute;n de uso de IA en el
          Contenido proporcionado por el Usuario, de forma que este pueda
          realizar un an&aacute;lisis m&aacute;s exhaustivo por cuenta propia
          acerca del origen del contenido consultado.
        </p>

        <p>
          El USUARIO renuncia a cualquier compensaci&oacute;n por parte de
          TELEF&Oacute;NICA con motivo de que el Servicio: (a) no se corresponda
          con las caracter&iacute;sticas de alguna vista previa del Servicio o
          las declaraciones que sobre el mismo se realicen; (b) no cumpla con la
          descripci&oacute;n y funcionalidades contenidas en estas Condiciones
          Generales o no cumplan con las expectativas del USUARIO; (c) no
          est&eacute; disponible de forma ininterrumpida, oportuna, segura o
          libre de errores; (d) no sea preciso, confiable, completo, legal o
          seguro; (e) no pueda ser usado en ning&uacute;n dispositivo en
          particular ni que est&eacute; disponible en ninguna geograf&iacute;a
          en particular o (f) el resultado de la consulta a VerifAI no sea
          correcto.
        </p>

        <p>13. CONTENIDO DE TERCEROS</p>

        <p>
          El Servicio puede contener enlaces a p&aacute;ginas web de terceros,
          as&iacute; como mostrar contenido de terceros, que en ning&uacute;n
          caso est&aacute;n bajo el control de TELEF&Oacute;NICA. Si el USUARIO
          accede a este contenido de terceros, el USUARIO estar&aacute; sujeto a
          los t&eacute;rminos que regulen dicho contenido. TELEF&Oacute;NICA no
          se hace responsable de dicho contenido ni ofrece ninguna
          garant&iacute;a sobre el mismo.
        </p>

        <p>14. INTERRUPCI&Oacute;N DEL SERVICIO</p>

        <p>
          Las presentes Condiciones Generales se extinguir&aacute;n, con la
          consiguiente interrupci&oacute;n definitiva del Servicio, por las
          causas generales admitidas en Derecho y, especialmente, por las
          siguientes:
        </p>

        <p>
          a) Por discontinuidad del Servicio por parte de TELEF&Oacute;NICA.
        </p>

        <p>
          b) Por incumplimiento del Cliente y/o de cualquiera de sus USUARIOS de
          los compromisos asumidos y las obligaciones derivadas de las presentes
          Condiciones Generales u otras condiciones aplicables.
        </p>

        <p>15. CESI&Oacute;N</p>

        <p>
          TELEF&Oacute;NICA podr&aacute; ceder el Servicio, y en consecuencia
          las presentes Condiciones Generales, a cualquier Sociedad del GRUPO
          TELEF&Oacute;NICA que pueda prestar en un futuro el Servicio.
        </p>

        <p>
          El USUARIO no puede transferir ni ceder estas Condiciones Generales ni
          los derechos y licencias otorgados por TELEF&Oacute;NICA bajo las
          mismas sin el consentimiento de TELEF&Oacute;NICA.
        </p>

        <p>16. MODIFICACI&Oacute;N DE LAS CONDICIONES GENERALES</p>

        <p>
          TELEF&Oacute;NICA realizar&aacute;, mientras lo estime necesario, a su
          solo criterio, cualquier cambio, correcci&oacute;n, mejora o
          modificaci&oacute;n en la informaci&oacute;n respecto al objeto y
          contenido de las presentes Condiciones Generales sin conceder al
          USUARIO derecho alguno de reclamaci&oacute;n o indemnizaci&oacute;n o
          reconocimiento de una obligaci&oacute;n.
        </p>

        <p>
          En el caso de que el USUARIO no acepte los cambios que se hagan a las
          presentes Condiciones Generales, deber&aacute; dejar de utilizar el
          Servicio.
        </p>

        <p>
          No obstante lo anterior, las modificaciones de las Condiciones
          Generales que libremente pueda realizar TELEF&Oacute;NICA no
          tendr&aacute;n efectos retroactivos al uso de VerifAI realizado por el
          USUARIO, a quien le aplicar&aacute; la versi&oacute;n de estas
          Condiciones Generales aceptada en el momento de uso del Servicio.
        </p>

        <p>17. MISCEL&Aacute;NEO</p>

        <p>
          En el caso de que cualquiera de las cl&aacute;usulas (o parte de
          ellas) de las presentes Condiciones Generales fuera declarada
          contraria a equidad o derecho e invalidada por parte de un
          &oacute;rgano judicial u otra autoridad competente, dicha invalidez no
          afectar&aacute; al resto de las cl&aacute;usulas, que
          permanecer&aacute;n vigentes en todos sus t&eacute;rminos.
        </p>

        <p>
          Las presentes Condiciones Generales y todos los t&eacute;rminos y
          documentaci&oacute;n a los que estas se refieren constituyen la
          totalidad del acuerdo y compromiso entre el USUARIO y
          TELEF&Oacute;NICA en lo que respecta al objeto de estas.
        </p>

        <p>
          El hecho de que TELEF&Oacute;NICA no ejercite alg&uacute;n derecho o
          disposici&oacute;n de estas Condiciones Generales no constituye una
          renuncia a dicho derecho o disposici&oacute;n.
        </p>

        <p>
          Todas las cl&aacute;usulas que, por su naturaleza, deban sobrevivir a
          la terminaci&oacute;n de las Condiciones Generales continuar&aacute;n
          en pleno vigor y efecto despu&eacute;s de cualquier terminaci&oacute;n
          de estas Condiciones Generales por parte de TELEF&Oacute;NICA o del
          USUARIO. La terminaci&oacute;n no limitar&aacute; los derechos que
          TELEF&Oacute;NICA ostente por ley.
        </p>

        <p>
          En caso de contradicci&oacute;n entre las versiones en espa&ntilde;ol
          y otros idiomas de las Condiciones Generales, prevalecer&aacute; lo
          dispuesto en las Condiciones Generales en espa&ntilde;ol. En cualquier
          caso, se entender&aacute; que la lengua de formalizaci&oacute;n
          ser&aacute; siempre el espa&ntilde;ol.
        </p>

        <p>18. CONTACTO Y RECLAMACIONES</p>

        <p>
          El USUARIO podr&aacute; comunicarse con TELEF&Oacute;NICA a
          trav&eacute;s de la siguiente direcci&oacute;n de correo
          electr&oacute;nico: autoverifai@telefonica.com.
        </p>

        <p>
          El USUARIO podr&aacute; enviar cualquier solicitud o
          reclamaci&oacute;n relacionada con el Servicio y/o con el resultado de
          la Consulta, como, por ejemplo, en el caso de que entienda que la
          estimativa proporcionada por VerifAI como resultado de la Consulta no
          es adecuada y/o en el caso de que quiera comunicar cualquier derecho
          propio y/ de tercero acerca del Contenido consultado, as&iacute; como
          solicitar limitaciones al uso de este Contenido (propio) por parte de
          VerifAI.
        </p>

        <p>
          A estos efectos, el soporte y atenci&oacute;n al cliente de los
          USUARIOS se realizar&aacute; online a trav&eacute;s de dicha
          direcci&oacute;n y ser&aacute; atendido a la mayor brevedad, en
          horario laboral de lunes a viernes, siempre que no sea festivo.
        </p>

        <p>19. LEY APLICABLE Y RESOLUCI&Oacute;N DE CONFLICTOS</p>

        <p>
          Estas Condiciones Generales se regir&aacute;n por la
          legislaci&oacute;n espa&ntilde;ola. En caso de controversia acerca de
          la interpretaci&oacute;n o ejecuci&oacute;n de estas, las partes
          acuerdan someterse a los Juzgados y Tribunales que les correspondan.
        </p>

        <p>
          Adem&aacute;s, le informamos de que la Comisi&oacute;n Europea ofrece
          una plataforma para la resoluci&oacute;n alternativa de conflictos, a
          la cual el USUARIO puede acceder aqu&iacute;:
          https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=ES.
        </p>
      </div>
    </div>
  );
};

export default withLanding(ConditionsPage);
