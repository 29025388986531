import React from "react";
import { DEVICES_TAB } from "../Data";
import { pathServer } from "../../../../utilities/Function";

export const TDropZoneItems = ({ device }) => {
  const deviceSelected = DEVICES_TAB.find((d) => d.key === device);

  return (
    <div className={"dropzone__info "}>
      <ul className="">
        {deviceSelected.items.map((it, index) => (
          <p className="mb-0" key={index}>
            <img
              src={pathServer.PATH_ICONS + "ic_list.svg"}
              className="mr-0"
              alt="icon"
              width={18}
            />{" "}
            {it}
          </p>
        ))}
      </ul>
    </div>
  );
};

export default TDropZoneItems;
