import React, { useState, FC, ReactNode } from "react";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import enUS from "antd/lib/locale/en_US";
import esES from "antd/lib/locale/es_ES";
import ENMessages from "./../../lang/en.json";
import ESMessages from "./../../lang/es.json";

type ProviderProps = {
  children: ReactNode;
};

export const LanguageContext = React.createContext<{
  locale: string;
  setLocale: (locale: string) => void;
}>({ locale: "en", setLocale: () => {} });

export const LanguageProvider: FC<ProviderProps> = ({ children }: any) => {
  const [locale, setLocale] = useState<string>("es");
  const [messages, setMessages] = useState(ESMessages);

  const selectLanguage = (value: string) => {
    const newLocale = value;
    setLocale(newLocale);

    if (newLocale === "en") {
      setMessages(ENMessages);
    } else {
      setMessages(ESMessages);
    }
  };

  return React.createElement(
    ConfigProvider,
    { locale: locale === "en" ? enUS : esES },
    React.createElement(
      IntlProvider,
      { messages: messages, locale: locale },
      React.createElement(
        LanguageContext.Provider,
        { value: { locale, setLocale: selectLanguage } },
        children
      )
    )
  );
};
