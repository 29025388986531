import { focusVideo } from "../../../infraestructure/utils/helpers";

export const getOptionsChartBar = (data, index) => ({
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: " Tiempo ( segundos )",
      },
    },

    y: {
      maxTicksLimit: 2,
      title: {
        display: true,
        text: "IA(%)",
      },
      suggestedMin: 0, // Establece el valor mínimo en el eje Y
      suggestedMax: 100, // Establece el valor máximo en el eje Y
      ticks: {
        callback: function (value) {
          return value + "%";
        },
      },
    },
  },
  onClick: (event, elements) => {
    // elements contiene la información sobre las barras clicadas
    if (elements.length > 0) {
      const clickedIndex = elements[0].index;
      const clickedSecond = data.labels[clickedIndex];
      focusVideo("video_ai-" + index, clickedSecond);
    }
  },
  onHover: (event, elements) => {
    // if (elements.length > 0) {
    //   //@ts-ignore
    //   event?.target?.style.cursor = "pointer"; // Cambia el cursor al hacer hover sobre las barras
    // } else {
    //   //@ts-ignore
    //   event?.target?.style.cursor = "default"; // Restablece el cursor cuando sale de las barras
    // }
  },
  maintainAspectRatio: false, // Desactiva el mantenimiento del aspecto para ajustar el tamaño
});
