/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Button, Form, Grid, Input, theme } from "antd";

import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { pathServer } from "../../utilities/Function";
import { RULES_LOGIN_ANTD } from "./Constants";
import "./style.scss";

const { useToken } = theme;
const { useBreakpoint } = Grid;

export default function Login({ onLogin, error = null, loading = false }) {
  const { token } = useToken();
  const screens = useBreakpoint();

  const onFinish = (values) => {
    if (onLogin) onLogin(values);
  };

  const styles = {
    container: {
      margin: "0 auto",
      padding: screens.md
        ? `${token.paddingXL}px`
        : `${token.sizeXXL}px ${token.padding}px`,
      width: "350px",
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%",
    },
    forgotPassword: {
      float: "right",
    },
    header: {
      marginBottom: "24px",
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      padding: "0 40px",
      width: "max-content",
      margin: "auto",
    },
    text: {
      color: token.colorTextSecondary,
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
    },
  };

  return (
    <div className="login-container">
      <section style={styles.section}>
        <div style={styles.container}>
          <div style={styles.header}>
            <img
              src={pathServer.PATH_LOGO + "logo.svg"}
              width={200}
              alt="verifai-logo"
            />
            <p className="w-80">
              Bienvenido a <b className="font-semibold"> VerifAI, </b> por favor
              introduce tus datos a continuación para acceder al servicio.{" "}
            </p>
          </div>
          <Form
            name="normal_login"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            layout="vertical"
            requiredMark="optional"
          >
            <Form.Item
              name="email"
              rules={[...RULES_LOGIN_ANTD.username, { type: "email" }]}
            >
              <Input prefix={<MailOutlined />} placeholder="Email" />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: "10px" }}
              name="password"
              rules={RULES_LOGIN_ANTD.password}
            >
              <Input.Password
                prefix={<LockOutlined />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            {error && (
              <p className="font-regular" style={{ color: "#ff4d4f" }}>
                {error}
              </p>
            )}
            <Form.Item style={{ margin: "auto" }} className="mt-1">
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                className="mt-1 font-bold"
              >
                Sign in
              </Button>
            </Form.Item>
          </Form>
        </div>
      </section>
    </div>
  );
}
