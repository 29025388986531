import { ActionType } from "./constants";

// Types
export type LoginState = {
  user: object;
  models?: any;
};

interface SuccessAction {
  type: ActionType.LOGIN_SUCESS_DATA;
  payload: any;
}

interface LoadingAction {
  type: ActionType.LOGIN_LOADING_DATA;
}

interface FailedAction {
  type: ActionType.LOGIN_FAILED_DATA;
}

interface InitialStateAction {
  type: ActionType.LOGIN_INITIALIZE_DATA;
}

interface UserStateAction {
  type: ActionType.USER_SUCESS_DATA;
  payload: any;
}

export type Actions =
  | SuccessAction
  | LoadingAction
  | FailedAction
  | UserStateAction
  | InitialStateAction;

// Action Creators

export const setSuccessLogin = (value): SuccessAction => ({
  type: ActionType.LOGIN_SUCESS_DATA,
  payload: value,
});

export const setDataUser = (value) => ({
  type: ActionType.USER_SUCESS_DATA,
  payload: value,
});

export const setLoadingLogin = (value): LoadingAction => ({
  type: ActionType.LOGIN_LOADING_DATA,
});

export const setFailedLogin = (): FailedAction => ({
  type: ActionType.LOGIN_FAILED_DATA,
});

export const setInitialStateLogin = (): InitialStateAction => ({
  type: ActionType.LOGIN_INITIALIZE_DATA,
});
