import React, { useContext, useEffect, useState } from "react";
import { withLanding } from "../../hocs/withLanding";
import { useHistory, useLocation, useParams } from "react-router-dom";
import TNavigator from "../../components/Navigator";

import {
  API_STATUS,
  API_STATUS_MESSAGE,
  ROUTES_GENERAL,
  TENANT_ID_GENERAL,
} from "../../utilities/Constant";
import TMyReportDetail from "../../components/MyReportDetail";
import {
  STATUS_TAKS_NUMBER,
  publickURL,
} from "../../components/MyReport/Constants";
import { useDispatch } from "react-redux";
import {
  setInitialPage,
  setLoadingPage,
} from "../../configuration/reduxStore/Page/actions";
import { useSelector } from "react-redux";
import { container } from "../../../inject_container/injection";
import { LOGIN_PORT, LoginPort } from "../../../domain/port/inbound/LoginPort";
import { setInitialStateLogin } from "../../configuration/reduxStore/Login/actions";
import { page_view, rating_subtask } from "../../hooks/usePageVisit";
import { EVENTS_TGM } from "../../utilities/Events";
import { LanguageContext } from "../../context/i18n/LanguageProvider";

const ResultPage = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { locale } = useContext(LanguageContext);

  const { user } = useSelector((state: any) => state.login);
  const [resource, setResource] = useState(null);
  const [detailJob, setDetailJob] = useState({
    id: "",
    subtasks: [],
    status: STATUS_TAKS_NUMBER.queue,
  });
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const loginRequest = container.get<LoginPort>(LOGIN_PORT);

  const setupInterval = () => {
    let interval = 4000; // Primer intervalo de 2 segundos
    const maxInterval = 32000; // Máximo intervalo de 32 segundos
    const intervalId = setInterval(() => {
      onLoadData(intervalId);
      // Duplicar el intervalo, pero no exceder el máximo
      interval = Math.min(interval * 2, maxInterval);
    }, interval);

    return intervalId;
  };

  const onHandleReview = async (rating, subTaskId) => {
    await onHandleFeedback(rating, subTaskId);
  };

  const onHandleFrame = (reference, subTaskId) => {
    if (detailJob) {
      const updatedTasks = detailJob.subtasks.map((subtask) => {
        if (subtask.id === subTaskId) {
          const updateSubTask = subtask.detailed_result.frame_result.map(
            (frame) => {
              if (frame.reference === reference) {
                return { ...frame, isFrame: true };
              }
              return { ...frame, isFrame: false };
            }
          );
          return {
            ...subtask,
            detailed_result: {
              ...subtask.detailed_result,
              frame_result: updateSubTask,
            },
          };
        }
        return subtask;
      });

      setDetailJob({ ...detailJob, subtasks: updatedTasks });
    }
  };

  const onHandleFeedback = async (review, subTaskId) => {
    //@ts-ignore
    const { id } = detailJob;
    dispatch(setLoadingPage(true));
    const response = await fetch(
      publickURL +
        "/verifai/task/feedback/" +
        TENANT_ID_GENERAL +
        "/" +
        id +
        "/" +
        subTaskId +
        "?review=" +
        review,
      {
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      }
    );
    if (response.status === API_STATUS.success) {
      const subTaskUpdated = await response.json();
      //@ts-ignore
      const subTasksUpdated = detailJob?.subtasks?.map((subTask) =>
        subTask.id === subTaskId ? subTaskUpdated : subTask
      );

      rating_subtask({
        page_type: EVENTS_TGM.report_detail,
        review: review,
        subTaskId: subTaskId,
      });

      setDetailJob({
        //@ts-ignore
        ...detailJob,
        subtasks: subTasksUpdated,
      });
    }
    dispatch(setLoadingPage(false));
  };

  const onGetTask = async (intervalId = null) => {
    //TODO: need to move to Adaptar Layout
    dispatch(setLoadingPage(true));
    const response = await fetch(
      publickURL + "/verifai/task/" + TENANT_ID_GENERAL + "/" + params.id,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      }
    );

    if (
      response.status !== API_STATUS.success ||
      response.statusText === API_STATUS_MESSAGE.unauthorized
    ) {
      const response = await loginRequest.refreshToken();
      if (response.status === 200) {
        await onGetTask();
      } else {
        onLogout();
      }
    } else if (response.status === 200) {
      const data = await response.json();
      setDetailJob(data);
      setResource(data.content_uri);

      if (data.status >= STATUS_TAKS_NUMBER.finished) {
        intervalId && clearInterval(intervalId);
        dispatch(setLoadingPage(false));
      }
    }
    dispatch(setLoadingPage(false));
  };

  const onDownloadReport = async (value: string[]) => {
    const { id } = detailJob;
    if (id) {
      const algorithms_filter = value || [];
      const response = await fetch(
        publickURL +
          "/verifai/task/pdf/" +
          TENANT_ID_GENERAL +
          "/" +
          id +
          "?algorithms_filter=" +
          algorithms_filter,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      );
      if (response.status === 200) {
        const stream = await response.blob();
        const pdfUrl = URL.createObjectURL(stream);
        window.open(pdfUrl);
      }
    }
  };

  const onLogout = () => {
    loginRequest.logout();
    dispatch(setInitialPage());
    dispatch(setInitialStateLogin());
    history.push(ROUTES_GENERAL.LOGIN);
  };

  const onLoadData = async (intervalId = null) => {
    //@ts-ignore
    if (detailJob && detailJob?.status >= STATUS_TAKS_NUMBER.finished) {
      clearInterval(intervalId);
      return;
    }
    await onGetTask(intervalId);
  };

  useEffect(() => {
    page_view({ page_type: EVENTS_TGM.report_detail, locale });
    onLoadData();
  }, []);

  useEffect(() => {
    //@ts-ignore
    if (detailJob?.status !== -1) {
      var intervalId = setupInterval();
      return () => clearInterval(intervalId);
    }
  }, [detailJob]);

  return (
    <div className="result-page max-width">
      <TNavigator
        customBack={() =>
          history.push({
            pathname: ROUTES_GENERAL.REPORT,
            state: location.state,
          })
        }
        page="Informe"
        withSearch={false}
        className="mt-2 mb-1"
      />
      <div className="max-width how_content">
        <TMyReportDetail
          onDownloadReport={onDownloadReport}
          detailJob={detailJob}
          resource={resource}
          onHandleReview={onHandleReview}
          onHandleFrame={onHandleFrame}
        />
      </div>
    </div>
  );
};

export default withLanding(ResultPage);
