import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Login from "../../components/Login";
import { container } from "../../../inject_container/injection";
import { LOGIN_PORT, LoginPort } from "../../../domain/port/inbound/LoginPort";
import { API_STATUS, ROUTES_GENERAL } from "../../utilities/Constant";
import VerifyTerms from "../../components/Login/VerifyTerms";
import { useDispatch, useSelector } from "react-redux";
import {
  setInitialPage,
  setLoadingPage,
  setMessagePage,
} from "../../configuration/reduxStore/Page/actions";
import {
  setDataUser,
  setSuccessLogin,
} from "../../configuration/reduxStore/Login/actions";
import { page_view } from "../../hooks/usePageVisit";

import "./styles.scss";
import { EVENTS_TGM } from "../../utilities/Events";
import { LanguageContext } from "../../context/i18n/LanguageProvider";

const STEP_PAGE = {
  login: "login",
  validate: "validate",
};

const LoginPage = () => {
  const { locale } = useContext(LanguageContext);

  const history = useHistory();
  const dispatch = useDispatch();
  const { loading } = useSelector((state: any) => state.page);
  const loginRequest = container.get<LoginPort>(LOGIN_PORT);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState({
    terms_accepted: false,
    privacy_accepted: false,
    step: STEP_PAGE.login,
  });

  const onSubmit = async (values) => {
    dispatch(setLoadingPage(true));
    try {
      const { status, data } = await loginRequest.login(values);

      if (status === API_STATUS.failed || status === API_STATUS.forbidden) {
        setError(data?.detail || "Intente luego");
      }

      if (status === API_STATUS.success) {
        dispatch(setSuccessLogin(data));
        const response = await loginRequest.getCurrentUser();

        if (response.status !== API_STATUS.success) {
          dispatch(
            setMessagePage({
              type: "error",
              content: response.data?.detail || "Intente luego",
            })
          );
        }
        if (response.data.terms_accepted && response.data.privacy_accepted) {
          //Store all the current user information
          dispatch(setDataUser(response.data));
          onRedirectToHome();
          return;
        }
        //Flow user dont accept terms and privacy first time
        if (response.status === API_STATUS.success) {
          setUserData({
            ...userData,
            step: STEP_PAGE.validate,
            ...response.data,
          });
        }
      }
    } catch (e) {
      console.log("error", e);
      dispatch(
        setMessagePage({
          type: "error",
          content: e.message,
        })
      );
    } finally {
      dispatch(setLoadingPage(false));
      // dispatch(setInitialPage());
    }
  };

  const onChecked = async (values) => {
    const payload = { ...userData };
    if (values) {
      payload.terms_accepted = values.terms_accepted ?? false;
      payload.privacy_accepted = values.privacy_accepted ?? false;
    }

    const response = await loginRequest.updateUser(payload);
    if (response.status === 200) {
      onRedirectToHome();
    }
  };

  const onRedirectToHome = () => {
    history.push(ROUTES_GENERAL.HOME_PAGE);
    setTimeout(() => {
      dispatch(setInitialPage());
    }, 1000);
  };

  useEffect(() => {
    dispatch(setInitialPage());
    page_view({ page_type: EVENTS_TGM.login, locale });
  }, []);

  return (
    <div className="login-page">
      {userData.step === STEP_PAGE.login ? (
        <Login onLogin={onSubmit} loading={loading} error={error} />
      ) : (
        <VerifyTerms
          values={userData}
          onSubmit={onChecked}
          loading={loading}
          onBack={() => setUserData({ ...userData, step: STEP_PAGE.login })}
        />
      )}
    </div>
  );
};

export default LoginPage;
