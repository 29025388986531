import React, { useState } from "react";
import { Modal, Button, Form, Input, List } from "antd";
import "./style.scss";
import { getRules } from "./Constants";
import { pathServer } from "../../utilities/Function";

interface LatchModalProps {
  className?: string;
  visible: boolean;
  loading: boolean;
  onCancel: () => void;
  onSubmit: (values: any) => void;
  userEmail: string;
}

const TForgotPasswordModal = (props: LatchModalProps) => {
  const { visible, onCancel, onSubmit, loading, userEmail } = props;
  const [form] = Form.useForm();

  const [passwordRules, setPasswordRules] = useState(getRules(userEmail));

  const validatePassword = async (_, value) => {
    if (value) {
      const updatedRules = passwordRules.map((rule) => {
        if (rule.pattern) {
          return { ...rule, valid: rule.pattern.test(value) };
        }
        if (rule.validator) {
          return { ...rule, valid: rule.validator(value) };
        }
        return rule;
      });

      setPasswordRules(updatedRules);

      if (updatedRules.some((rule) => !rule.valid)) {
        return Promise.reject(
          "La nueva contraseña no cumple con todas las reglas."
        );
      }

      return Promise.resolve();
    }
    return Promise.reject("Por favor, ingrese una contraseña.");
  };

  const onFinish = (values) => {
    onSubmit(values);
  };

  return (
    <div className={`LatchModal ${props.className}`}>
      <Modal
        open={visible}
        onCancel={onCancel}
        okText="Establecer Contraseña"
        confirmLoading={loading}
        onOk={() => form.submit()}
        footer={[
          <Button
            key="submit"
            type="primary"
            className=" m-auto d-flex"
            loading={loading}
            onClick={() => form.submit()}
          >
            Establecer Contraseña
          </Button>,
        ]}
      >
        {" "}
        <div className="header__logo mb-2">
          <img
            width={180}
            src={pathServer.PATH_LOGO + "logo_pro.svg"}
            className="d-flex pointer"
            alt="logo"
          />
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="currentPassword"
            label="Contraseña Actual"
            rules={[
              {
                required: true,
                message: "Por favor, ingrese su contraseña actual",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label="Nueva Contraseña"
            rules={[
              {
                required: true,
                message: "Por favor, ingrese su nueva contraseña",
              },
              { validator: validatePassword },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Repita Nueva Contraseña"
            dependencies={["newPassword"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Por favor, confirme su nueva contraseña",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Las contraseñas no coinciden");
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
        <List
          header={<div>Requisitos de la contraseña:</div>}
          bordered
          dataSource={passwordRules}
          renderItem={(rule) => (
            <List.Item style={{ color: rule.valid ? "green" : "red" }}>
              {rule.message}
            </List.Item>
          )}
        />
      </Modal>
    </div>
  );
};

TForgotPasswordModal.displayName = "TForgotPasswordModal";

TForgotPasswordModal.defaultProps = {
  className: "",
};

export default TForgotPasswordModal;
