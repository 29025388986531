export const RULES_LOGIN_ANTD = {
  username: [
    {
      required: true,
      message: "Please input your Email!",
    },
  ],
  password: [
    {
      required: true,
      message: "Please input your Password!",
    },
  ],
};
