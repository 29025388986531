import React from "react";

export const STEP_LATCH_CONTENT = [
  {
    image: "images/latch/step_0.png",
    subtitle: "Acceso seguro a tus cuentas",
    description: (
      <div>
        <p>
          Con Latch podrás crear códigos para acceder a las cuentas de tus
          servicios y así estar más protegido frente ataques maliciosos. Para
          poder disfrutar de la protección de Latch descarga la app en el link a
          continuación y crea un usuario o inicia sesión.
        </p>
        <a
          className="text-center m-auto"
          target="_blank"
          href="https://latch.tu.com"
          rel="noreferrer"
        >
          https://latch.tu.com
        </a>
      </div>
    ),
  },
  {
    image: "images/latch/step_1.png",
    subtitle: "Protege tus cuentas con solo un toque",
    description:
      "Los pestillos te permiten bloquear temporalmente funcionalidades del servicio que añadas.  Latch te da el control para “apagar” tus servicios online cuando no estés conectado.",
  },
  {
    image: "images/latch/step_2.png",
    subtitle: "Introduce el código de enlace",
    description: (
      <div>
        <p>
          Los pestillos son compatibles con ciertas apps y servicios. Copia el
          código que te proporcionamos en la sección pestillos e introdúcelo a
          continuación.
        </p>
      </div>
    ),
  },
  {
    image: "images/latch/step_3.png",
    subtitle: "¡Listo! podrás configurar los pestillos del servicio",
    description:
      "Una vez introducido el código en el servicio, te aparecerá en Latch un pestillo.",
  },
];
