import React from "react";
import { Modal, Tooltip } from "antd";

import { LoadingOutlined } from "@ant-design/icons";
import { ReactSVG } from "react-svg";
import { pathServer } from "../../utilities/Function";
import { toChartAI, unixToDate } from "../../../infraestructure/utils/helpers";
import TChart, { CHART_TYPES } from "../Chart";
import TaskTag from "../Tag";

const conicColors = { "0%": "#FF4D4F", "50%": "#EFE384", "100%": "#4343FF" };

const { confirm } = Modal;
export const publickURL = `${process.env.REACT_APP_BASE_URL}/api/pub`;

export const VERIFAI_DEVICES = {
  image: "image",
  video: "video",
  audio: "audio",
};

const showDeleteConfirm = (name, key, onDeleteTask) => {
  confirm({
    title: "Estas seguro de que deseas eliminar el informe " + name + " ?",
    icon: (
      <ReactSVG
        className="mr-0 mt-0"
        src={pathServer.PATH_ICONS + "ic_info.svg"}
      />
    ),
    content: "Una vez eliminados no los podras recuperar",
    okText: "Eliminar",
    okType: "danger",
    cancelText: "Cancelar",
    centered: true,
    onOk: async () => await onDeleteTask(key),
    onCancel() {},
  });
};

export function getIconFromDevice(device: string) {
  switch (device) {
    case VERIFAI_DEVICES.audio:
      return (
        <ReactSVG
          className="icon-table icon_device"
          src={pathServer.PATH_ICONS + "ic_device_audio.svg"}
        />
      );
    case VERIFAI_DEVICES.image:
      return (
        <ReactSVG
          className="icon-table icon_device"
          src={pathServer.PATH_ICONS + "ic_device_image.svg"}
        />
      );
    case VERIFAI_DEVICES.video:
    default:
      return (
        <ReactSVG
          className="icon-table  icon_device"
          src={pathServer.PATH_ICONS + "ic_device_video.svg"}
        />
      );
  }
}

Object.freeze(VERIFAI_DEVICES);

export const columns_TABLE = (onDeleteTask, onTagChange) => [
  {
    title: "Nombre del reporte",
    dataIndex: "report_name",
    key: "report_name",
    render: (text, record, index) => {
      const { icon, tag, id, key } = record;
      return (
        <div key={index} className="space-between">
          <div className="d-flex text-center ">
            <div> {icon}</div>
            <Tooltip title={id}>
              <p
                className="ml-0"
                style={{
                  maxWidth: "100px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {id}
              </p>
            </Tooltip>
          </div>
          <TaskTag
            defaultValue={tag}
            taskId={key}
            onChange={(taskid, value) => {
              record.tag = value;
              onTagChange(taskid, value);
            }}
          />
        </div>
      );
    },
    sorter: (a, b) => a.id.localeCompare(b.id),
    width: "25%",
  },
  {
    title: "Nombre del archivo",
    dataIndex: "field_name",
    key: "field_name",
    render: (value: string) => (
      <div>
        <p
          className="font-medium-2"
          style={{ wordWrap: "break-word", maxWidth: "180px" }}
        >
          {" "}
          {value}
        </p>
      </div>
    ),
    width: "30%",
    sorter: (a, b) => a.field_name.localeCompare(b.field_name),
  },
  {
    title: "Probabilidad de IA",
    dataIndex: "percentage",
    key: "percentage",
    render: (text, record, index) => {
      const { ai } = record;
      const { numAiDetected, numNoConclusiveDetected, numNoAiDetected } =
        toChartAI(ai);
      return (
        <TChart
          withLegend={false}
          width="20px"
          height="20px"
          type={CHART_TYPES.doughnut}
          data={{
            labels: [numNoAiDetected, numAiDetected, numNoConclusiveDetected],
            datasets: [
              {
                data: [numNoAiDetected, numAiDetected, numNoConclusiveDetected],
                backgroundColor: ["#4343FF", "#FF4D4F", "#EFE384"],
              },
            ],
          }}
        />
        // <Progress
        //   className="report_progress text-center"
        //   size={"small"}
        //   type="dashboard"
        //   percent={92}
        //   strokeColor={conicColors}
        // />
      );
    },
    width: "20%",
    sorter: (a, b) => b.percentage - a.percentage,
  },
  {
    title: "Fecha",
    dataIndex: "created_at",
    key: "created_at",
    render: (text, record, index) => {
      const { created_at } = record;
      return (
        <div className="d-flex">
          <p className="ml-0">{unixToDate(created_at)}</p>
        </div>
      );
    },
    sorter: (a, b) => b.created_at - a.created_at,
    width: "15%",
  },
  {
    title: "",
    dataIndex: "actions",
    key: "actions",
    onCell: () => {
      return {
        onClick: (event) => {
          event.stopPropagation(); // this will avoid onRow being called
          // go to placeUrl(routes.platformDetails, {})
        },
      };
    },
    render: (text, record, index) => {
      const { status, id, key } = record;
      return (
        <div className="d-flex">
          {getStatusTask(status)}
          <ReactSVG
            beforeInjection={(svg) => svg.setAttribute("aria-label", "test")}
            onClick={() => showDeleteConfirm(id, key, onDeleteTask)}
            className="icon ml-0"
            src={pathServer.PATH_ICONS + "ic_delete.svg"}
          />
        </div>
      );
    },
    width: "15%",
  },
];

export const getSortedByField = (data, field) =>
  data?.sort((a, b) => b[field] - a[field]);

function getStatusTask(status) {
  switch (status) {
    case STATUS_TAKS[0]:
      <small> 0 </small>;
    case STATUS_TAKS[1]:
      return <LoadingOutlined />;
    case STATUS_TAKS[2]:
      <ReactSVG
        className="icon "
        src={pathServer.PATH_ICONS + "ic_success.svg"}
      />;
    case STATUS_TAKS[3]:
      //TODO
      break;
  }
}

export const STATUS_TAKS = {
  0: "queue",
  1: "running",
  2: "finished",
  3: "failed",
};

export const STATUS_TAKS_NUMBER = {
  queue: 0,
  running: 1,
  finished: 2,
  failed: 3,
};
