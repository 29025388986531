import { FormattedMessage } from "react-intl";

export const CONTENT_PAGE = [
  {
    id: 1,
    title: "app.HowItWorks.Index.Link_1",
    content: [
      {
        id: 1,
        img: "images/blink.gif",
        text: (
          <>
            <h5 className="mb-0">
              <FormattedMessage id="app.HowItWorks.Link_1.Subtitle" />
            </h5>
            <p className="mt-0">
              <FormattedMessage id="app.HowItWorks.Link_1.deepfakeDetection" />
            </p>
          </>
        ),
      },
      // {
      //   id: 2,
      //   img: "images/approach.gif",
      //   text: (
      //     <>
      //       <p>
      //         <FormattedMessage id="app.HowItWorks.Link_1.firstApproach" />
      //       </p>
      //       <p className="mt-1">
      //         <FormattedMessage id="app.HowItWorks.Link_1.secondApproach" />
      //       </p>
      //     </>
      //   ),
      // },
      // {
      //   id: 3,
      //   img: "images/models.gif",
      //   text: (
      //     <>
      //       <h5 className="mb-0">
      //         <FormattedMessage id="app.HowItWorks.Link_1.2Subtitle" />
      //       </h5>
      //       <p className="mt-0">
      //         <FormattedMessage id="app.HowItWorks.Link_1.2weightingMethods" />
      //       </p>
      //       <ul className="ml-2">
      //         <li>
      //           {" "}
      //           <FormattedMessage id="app.HowItWorks.Link_1.2headPose" />
      //         </li>
      //         <li>
      //           {" "}
      //           <FormattedMessage id="app.HowItWorks.Link_1.2blinking" />
      //         </li>
      //         <li>
      //           {" "}
      //           <FormattedMessage id="app.HowItWorks.Link_1.2lrcn" />
      //         </li>
      //         <li>
      //           {" "}
      //           <FormattedMessage id="app.HowItWorks.Link_1.2vit" />
      //         </li>
      //       </ul>

      //       <p className="mt-1">
      //         <FormattedMessage id="app.HowItWorks.Link_1.2headBlinkExperimental" />
      //       </p>
      //       <p className="mt-1">
      //         <FormattedMessage id="app.HowItWorks.Link_1.2lrcnDetection" />
      //       </p>

      //       <ul className="ml-2 link">
      //         <li>
      //           {" "}
      //           <FormattedMessage id="app.HowItWorks.Link_1.2source1" />
      //         </li>
      //         <li>
      //           {" "}
      //           <FormattedMessage id="app.HowItWorks.Link_1.2source2" />
      //         </li>
      //         <li>
      //           {" "}
      //           <FormattedMessage id="app.HowItWorks.Link_1.2source3" />
      //         </li>
      //       </ul>
      //     </>
      //   ),
      // },
    ],
  },
  {
    id: 2,
    title: "app.HowItWorks.Index.Link_2",
    content: [
      {
        id: 1,
        img: "images/carousel/how_to_image.png",
        text: (
          <>
            <p>
              {" "}
              <FormattedMessage id="app.HowItWorks.Link_2.huggingFaceModel" />
            </p>
            <p className="mt-1">
              <FormattedMessage id="app.HowItWorks.Link_2.swinTransformer" />
            </p>
            <p className="mt-1">
              <FormattedMessage id="app.HowItWorks.Link_2.trainImages" />
            </p>
            <p className="mt-1">
              <FormattedMessage id="app.HowItWorks.Link_2.moreDetails" />
            </p>
            <a href="https://medium.com/@matthewmaybe/can-an-ai-learn-to-identify-ai-art-545d9d6af226">
              <FormattedMessage id="app.HowItWorks.Link_2.mediumLink" />
            </a>
          </>
        ),
      },
    ],
  },
  {
    id: 3,
    title: "app.HowItWorks.Index.Link_3",
    content: [
      {
        id: 1,
        img: "images/carousel/how_image.webp",
        text: (
          <>
            <p>
              {" "}
              <FormattedMessage id="app.HowItWorks.Link_3.modelTrained" />
            </p>
            <p className="mt-1">
              <FormattedMessage id="app.HowItWorks.Link_3.trainingDataset" />
            </p>
            <p className="mt-1">
              <FormattedMessage id="app.HowItWorks.Link_3.languageSupport" />
            </p>
          </>
        ),
      },
    ],
  },
];
