import React from "react";

import TChart, { CHART_TYPES } from "../../../Chart";
import TMyReportTitleItem from "../MyReportTitleItem";
import TRangeInfo from "../../../ToolTipInfo";
import "./style.scss";
import { Skeleton } from "antd";
import { STATUS_TAKS, STATUS_TAKS_NUMBER } from "../../../MyReport/Constants";

// Define your props here
interface MyReportProps {
  className?: string;
  device?: string;
  token: number;
  numEngines: number;
  numAiDetected?: number;
  numNoConclusiveDetected?: number;
  numNoAiDetected?: number;
  status?: number;
}

const TMyReportDetailSummary = (props: MyReportProps) => {
  return (
    <div className={`TMyReportDetailSummary ${props.className}`}>
      <TMyReportTitleItem withBackground title="Resumen" />
      {props.status === STATUS_TAKS_NUMBER.queue ||
      props.status === STATUS_TAKS_NUMBER.running ? (
        <Skeleton active className="p-2" />
      ) : (
        <div className="TMyReportDetailSummary__body">
          <div className="d-flex flex-column">
            <p>
              <strong style={{ fontSize: "1.7rem" }} className="mr-0">
                {props.numEngines}
              </strong>{" "}
              Motores utilizados
            </p>
            <p>
              <strong style={{ fontSize: "1.7rem" }} className="mr-0">
                {props.token}
              </strong>{" "}
              Tokens utilizados
            </p>
          </div>

          <TChart
            withLegend
            type={CHART_TYPES.doughnut}
            data={{
              labels: [
                props.numNoAiDetected,
                props.numAiDetected,
                props.numNoConclusiveDetected,
              ],
              datasets: [
                {
                  data: [
                    props.numNoAiDetected,
                    props.numAiDetected,
                    props.numNoConclusiveDetected,
                  ],
                  backgroundColor: ["#4343FF", "#FF4D4F", "#EFE384"],
                },
              ],
            }}
          />
          <TRangeInfo type="not-full" />
        </div>
      )}
    </div>
  );
};

TMyReportDetailSummary.displayName = "TMyReportDetailSummary";

TMyReportDetailSummary.defaultProps = {
  className: "",
};

export default TMyReportDetailSummary;
