import { useState, useEffect } from "react";

import { TENANT_ID_GENERAL } from "../utilities/Constant";

const publicUrl = `${process.env.REACT_APP_BASE_URL}/api/pub`;

export const useStats = () => {
  const [stats, setStats] = useState({
    total_analyzed_content: 0,
    last_week_analyzed_content: 0,
    last_month_analyzed_content: 0,
    engine_results_with_high_ai_score: 0,
    engine_jobs_launched: 0,
  });
  const [loadingModels, setLoading] = useState(false);
  const getStats = async () => {
    let myHeaders;
    myHeaders = {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    };

    try {
      setLoading(true);
      let response = await fetch(
        publicUrl + "/stats/usage/" + TENANT_ID_GENERAL,
        {
          method: "GET",
          headers: myHeaders,
        }
      );

      const data = await response.json();
      setStats(data);
    } catch (e) {
      console.log("Error", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  return [stats, loadingModels] as const;
};
