export const getResults = (result, typeFile = "image") => {
  const data = {
    color: "",
    text: "",
    className: "",
    title: "",
    content: "",
    result: "",
  };
  if (result >= 0 && result < 33.33) {
    data.color = "#4343ff";
    switch (typeFile) {
      case "image":
      default:
        data.title = "app.result.image_title";
        data.content = "app.result.image_low";
        data.result = "app.result.image_result";
        break;
      case "video":
        data.title = "app.result.video_title";
        data.content = "app.result.video_low";
        data.result = "app.result.video_result";
        break;
      case "text":
        data.title = "app.result.text_title";
        data.content = "app.result.text_low";
        data.result = "app.result.text_result";
        break;
      case "audio":
        data.title = "app.result.audio_title";
        data.content = "app.result.audio_low";
        data.result = "app.result.audio_result";
        break;
    }

    data.className = "not-ai";
  } else if (result >= 33.33 && result < 66.66) {
    data.color = "#efe384";
    switch (typeFile) {
      case "image":
      default:
        data.title = "app.result.image_med_title";
        data.content = "app.result.image_med";
        data.result = "";
        break;
      case "video":
        data.title = "app.result.video_med_title";
        data.content = "app.result.video_med";
        data.result = "";
        break;
      case "text":
        data.title = "app.result.text_med_title";
        data.content = "app.result.text_med";
        data.result = "";
        break;
      case "audio":
        data.title = "app.result.audio_med_title";
        data.content = "app.result.audio_med";
        data.result = "";
        break;
    }

    data.className = "potentially-ai";
  } else if (result >= 66.66) {
    data.color = "#ff4d4f";
    switch (typeFile) {
      case "image":
      default:
        data.title = "app.result.image_title";
        data.content = "app.result.image_high";
        data.result = "app.result.image_result";
        break;
      case "video":
        data.title = "app.result.video_title";
        data.content = "app.result.video_high";
        data.result = "app.result.video_result";
        break;
      case "text":
        data.title = "app.result.text_title";
        data.content = "app.result.text_high";
        data.result = "app.result.text_result";
        break;
      case "audio":
        data.title = "app.result.audio_title";
        data.content = "app.result.audio_high";
        data.result = "app.result.audio_result";
        break;
    }

    data.className = "likely-ai";
  }
  return data;
};

export const getResultsV2 = (result) => {
  const data = {
    color: "",
    title: "",
  };
  if (result >= 0 && result < 33.33) {
    data.color = "#4343ff";
    data.title = "PROBABLEMENTE NO";
  } else if (result >= 33.33 && result < 66.66) {
    data.color = "#efe384";

    data.title = "NO CONCLUYENTE";
  } else if (result >= 66.66) {
    data.color = "#ff4d4f";
    data.title = "MUY PROBABLEMENTE";
  }
  return data;
};
