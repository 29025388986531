import React, { useContext, useState } from "react";
import { Layout, Menu, Button, Drawer } from "antd";
import { MenuOutlined, UserOutlined } from "@ant-design/icons";
import TLanguageSelector from "../LanguageSelector";
import { t } from "../../utilities/Message";
import { Spin } from "antd";

import { useHistory, useLocation } from "react-router-dom";
import { pathServer } from "../../utilities/Function";
import { MENU_OPTIONS } from "./Constants";

import { ROUTES_GENERAL } from "../../utilities/Constant";
import { ThemeContext } from "../../context/theme/ThemeProvider";
import { VERIFAI_CLIENTS } from "../../context/theme/Constants";
import { container } from "../../../inject_container/injection";
import { LOGIN_PORT, LoginPort } from "../../../domain/port/inbound/LoginPort";
import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";
import { setInitialPage } from "../../configuration/reduxStore/Page/actions";
import { setInitialStateLogin } from "../../configuration/reduxStore/Login/actions";
import "./style.scss";
import TUserPanel from "../UserPanel";

const { Header } = Layout;

interface HeaderProps {
  className?: string;
}

const THeader = (props: HeaderProps) => {
  const { user } = useSelector((state: any) => state.login);
  const isAuthenticated = !!user.access_token;
  const loginRequest = container.get<LoginPort>(LOGIN_PORT);
  const { loading } = useSelector((state: any) => state.page);

  const { mode, setMode } = useContext(ThemeContext);
  const isPolice = mode === VERIFAI_CLIENTS.police;
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onNavigate = (route) => {
    history.push(route);
  };

  const onLogout = async () => {
    loginRequest.logout();
    dispatch(setInitialPage());
    dispatch(setInitialStateLogin());
    history.push(ROUTES_GENERAL.LOGIN);
  };

  return (
    <>
      <Header className="header">
        <div
          className="header__logo"
          onClick={() => onNavigate(ROUTES_GENERAL.HOME_PAGE)}
        >
          <img
            width={180}
            src={pathServer.PATH_LOGO + "logo_pro.svg"}
            className="d-flex pointer"
            alt="logo"
          />
        </div>
        <Menu
          className="header__menu"
          mode="horizontal"
          style={{ width: "400px" }}
          items={[
            {
              key: 1,
              title: "Analysis",
              className:
                "header__menu-item " + ROUTES_GENERAL.HOME_PAGE ===
                location.pathname
                  ? " header__menu-selected"
                  : "",
              onClick: () => onNavigate(ROUTES_GENERAL.HOME_PAGE),
              label: t("app.Header.Analysis"),
            },
            //@ts-ignore
            isPolice && {
              key: 2,
              title: "Mis Informes",
              className:
                "header__menu-item " + ROUTES_GENERAL.REPORT ===
                location.pathname
                  ? " header__menu-selected"
                  : "",
              onClick: () => onNavigate(ROUTES_GENERAL.REPORT),
              label: (
                <div style={{ color: "black" }}>
                  Mis Informes{" "}
                  {loading && <Spin size="small" className="ml-0" />}{" "}
                </div>
              ),
            },
            !isPolice && {
              key: 3,
              title: "How it works",
              className:
                "header__menu-item " + ROUTES_GENERAL.HOW_WORKS ===
                location.pathname
                  ? " header__menu-selected"
                  : "",
              onClick: () => onNavigate(ROUTES_GENERAL.HOW_WORKS),
              label: t("app.Header.HowWorks"),
            },
            !isPolice && {
              key: 4,
              title: "Help",
              className:
                "header__menu-item " + ROUTES_GENERAL.HELP === location.pathname
                  ? " header__menu-selected"
                  : "",
              onClick: () => onNavigate(ROUTES_GENERAL.HELP),
              label: t("app.Header.Help"),
            },
          ]}
        />

        <div className="header__cta">
          {!isPolice && <TLanguageSelector />}
          {isPolice &&
            (isAuthenticated ? (
              <TUserPanel onLogout={onLogout} />
            ) : (
              <Button
                className="header__mobile-menu-button"
                type="text"
                icon={<UserOutlined />}
                onClick={() => onNavigate(ROUTES_GENERAL.LOGIN)}
              />
            ))}
        </div>
        <div className="header__mobile-menu">
          <img
            onClick={() => onNavigate(ROUTES_GENERAL.HOME_PAGE)}
            style={location.pathname === "/" ? { visibility: "hidden" } : {}}
            width={180}
            src={pathServer.PATH_LOGO + "logo.svg"}
            className="d-flex pointer"
            alt="logo"
          />
          <Button
            className="header__mobile-menu-button"
            type="text"
            icon={
              <MenuOutlined style={{ color: "white", fontSize: "1.5rem" }} />
            }
            onClick={showDrawer}
          />
          <Drawer placement="right" onClose={onClose} open={visible}>
            <Menu className="header__drawer-menu" theme="light" mode="vertical">
              {MENU_OPTIONS.map((menu) => (
                <Menu.Item
                  key={menu.id}
                  className={
                    "header__drawer-menu-item " +
                    (menu.route === location.pathname
                      ? " header__menu-selected"
                      : "")
                  }
                  onClick={() => onNavigate(menu.route)}
                >
                  {t(menu.message, "")}
                </Menu.Item>
              ))}
            </Menu>
          </Drawer>
        </div>
      </Header>
    </>
  );
};

THeader.displayName = "THeader";

THeader.defaultProps = {
  className: "",
};

export default THeader;
