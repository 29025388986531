import React, { FC, ReactNode } from "react";
import { combineReducers, createStore } from "redux";
import { Provider } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { PersistGate } from "redux-persist/integration/react";

import { LoginReducer } from "./Login/reducer";
import PageReducer from "./Page/reducer";

type ProviderProps = {
  children: ReactNode;
};

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    login: LoginReducer,
    page: PageReducer,
  })
);

const store = createStore(persistedReducer);
const persistor = persistStore(store);

export const ReduxProvider: FC<ProviderProps> = ({ children }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
};
