import React from "react";

import { Tag, Collapse, Skeleton, Image } from "antd";

import TResultItem from "../ResultItem";
import TMyReportTitleItem from "../MyReportTitleItem";
import TMyReportDetailSummary from "../MyReportDetailSummary";
import "./style.scss";
import TRangeInfo from "../../../ToolTipInfo";
import TCollapseInfo from "../../../CollapseInfo";
import {
  toChartAI,
  unixToDate,
  fileSizeStr,
} from "../../../../../infraestructure/utils/helpers";
import { useSelector } from "react-redux";
import { isTaskLoading, pathServer } from "../../../../utilities/Function";

interface MyReportProps {
  className?: string;
  id?: string;
  data?: any;
  task?: any;
  resource?: any;
  onHandleReview?: (review: number, subTaskId: number) => void;
  onHandleFrame: (reference: number, subTaskId: number) => void;
}

interface TMyReportHeaderSummaryProps {
  loading: boolean;
  resource: string;
  task: any;
  content_type: string;
  filename?: string;
  filesize?: any;
  mimetype?: any;
  hash?: any;
}
const IMAGE_PLACEHOLDER_SUMMARY = "/images/placeholder/summary_image.png";
const VIDEO_PLACEHOLDER_SUMMARY = "/images/placeholder/summary_video.png";
const AUDIO_PLACEHOLDER_SUMMARY = "/images/placeholder/summary_audio.png";

const TMyReportHeaderSummary = React.memo(
  (props: TMyReportHeaderSummaryProps) => {
    return (
      <div
        className={
          "d-flex mt-1 MyReportDetailBody__body pb-1 " +
          (!props.resource ? " placeholder " : "")
        }
      >
        {props.content_type === "image" ? (
          <Image
            alt={props.filename}
            src={props.resource}
            className={"MyReportDetailBody__body__img-preview"}
            width={227}
            fallback={IMAGE_PLACEHOLDER_SUMMARY}
          />
        ) : props.content_type === "video" ? (
          !props.resource ? (
            <Image
              className={"MyReportDetailBody__body__img-preview"}
              width={227}
              fallback={VIDEO_PLACEHOLDER_SUMMARY}
            />
          ) : (
            <video
              controls
              className="MyReportDetailBody__body__video-preview"
              autoPlay={false}
            >
              <source src={props.resource} type="video/mp4" />
            </video>
          )
        ) : (
          <Image
            alt={"audio"}
            src={pathServer.PATH_IMG + "ic_audio.png"}
            className="MyReportDetailBody__body__img-preview"
            fallback={AUDIO_PLACEHOLDER_SUMMARY}
          />
        )}
        <div className="m-auto">
          <div className="d-flex">
            <p>Nombre del archivo: </p> <strong>{props.filename}</strong>
          </div>
          <div className="d-flex">
            <p> Tamaño :</p> <strong>{fileSizeStr(props.filesize)}</strong>
          </div>
          <div className="d-flex">
            <p> Formato : </p> <strong>{props.mimetype}</strong>
          </div>
          <div className="d-flex">
            <p> Hash : </p> <strong className="hash">{props.hash}</strong>
          </div>
        </div>
      </div>
    );
  }
);

const TMyReportDetailBody = (props: MyReportProps) => {
  const { task } = props;
  const { loading } = useSelector((state: any) => state.page);

  const subTaks =
    task &&
    task?.subtasks &&
    task?.subtasks
      ?.filter((d) => d.detailed_result.meta_engine !== true)
      .sort((a, b) => a.updated_at > b.updated_at);

  const items = subTaks.map((d, index) => {
    return {
      key: d.id,
      label: (
        <TResultItem
          motorTags={d.algorithm_tags}
          name={d.algorithm}
          tokens={d.token ? d.token : "-"}
          percentage={d.ai_score}
          content_type={task.content_type}
          status={d.status}
          message={d.message}
          review={d.review}
          onHandleReview={props.onHandleReview}
          id={d.id}
        />
      ),
      children: (
        <>
          {isTaskLoading(d.status) ? (
            <Skeleton />
          ) : (
            <TCollapseInfo
              index={index}
              loading={loading}
              device={task.content_type}
              duration={Math.floor(d?.detailed_result?.timing?.duration)}
              status={d.status}
              detail={d.detailed_result}
              resource={props.resource}
              description={d.algorithm_description_long}
              thumbnail_uri={d.thumbnail_uri}
              thumbnail_width={d?.thumbnail_width || 227}
              thumbnail_height={d?.thumbnail_height || 256}
              algorithm={d.algorithm}
              onHandleFrame={(reference) =>
                props.onHandleFrame(reference, d.id)
              }
            />
          )}
        </>
      ),
    };
  });
  return (
    <div
      className={`MyReportDetailBody mt-2 mb-1 pt-2 ${props.className}`}
      id={props.id}
    >
      <div className="d-flex border-gray pb-1">
        <div className="MyReportDetailBody__header">
          <h1 className="font-medium-2">Resultado del análisis: {task?.id}</h1>
          <Tag style={{ width: "100px" }} className="text-center">
            Tag: {task?.tags}
          </Tag>
        </div>
        <div className="ml-auto">
          <p className="font-medium-2">{task?.content_type}</p>
          <p className="font-medium-2"> {unixToDate(task?.created_at)}</p>
        </div>
      </div>
      <TMyReportHeaderSummary
        loading={loading}
        resource={props.resource}
        task={props.task}
        content_type={props.task.content_type}
        filename={props.task.filename}
        filesize={props.task.filesize}
        mimetype={props.task.mimetype}
        hash={props.task.hash}
      />
      <TMyReportDetailSummary
        status={task.status}
        device={task.content_type}
        token={task.token}
        numEngines={subTaks?.length}
        {...toChartAI(task?.subtasks)}
        className="mt-0"
      />
      <div>
        <TMyReportTitleItem withBackground title="Detalles" />
        <Collapse items={items} defaultActiveKey={["0"]} />
      </div>

      <TMyReportTitleItem className="mt-1" title="Puntuación" />

      <div className="MyReportDetailBody__footer">
        <p>
          Este veredicto se genera empleando los mejores servicios de detección
          de Inteligencia Artificial del mercado actual, pero nunca debe ser
          considerado como veredicto definitivo. Recomendamos contrastar
          nuestros resultados con opiniones de profesionales.
        </p>
        <TRangeInfo type="not-full" withDescription={false} />
      </div>
    </div>
  );
};

TMyReportDetailBody.displayName = "TMyReportDetailBody";

TMyReportDetailBody.defaultProps = {
  className: "",
};

export default TMyReportDetailBody;
