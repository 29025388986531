import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Space, Input, Alert, Checkbox } from "antd";

import { useDropzone } from "react-dropzone";

import { ReactSVG } from "react-svg";
import { pathServer } from "../../utilities/Function";
import {
  INITIAL_ERROR,
  ITEMS,
  MESSAGES_DROPZONE,
  RequirementsFile,
  STATUS_FILE,
} from "./Constants";

import { t } from "../../utilities/Message";
import { FilesAvailable } from "../../../domain/entities/Api";
import "./style.scss";
import TProgressContent from "../ProgressContent";

interface DropzoneProps {
  className?: string;
  loading?: boolean;
  uploadFile?: (params: File) => void;
  uploadText?: (params: string) => void;
}

function TDropZoneItems() {
  return ITEMS.map((item, index) => (
    <li key={index}>
      <ReactSVG src={pathServer.PATH_ICONS + item.icon} className="item-icon" />
      <h2 className="from-lg uppercase">{t(item.title, "")}</h2>
      <div>
        <h2 className="to-md uppercase">{t(item.title, "")}</h2>
        <div>
          {item.items.map((it, index) => (
            <p className="mb-0" key={index}>
              <img
                src={pathServer.PATH_ICONS + "ic_list.svg"}
                className="mr-0"
                alt="icon"
                width={28}
              />{" "}
              {t(it, "")}
            </p>
          ))}
        </div>
      </div>
    </li>
  ));
}

//In the first call we can limit the progress to 50% , the second part is in the result page
const TIME_LIMIT_FIRST_SERVICE = 100;

const TIME_MILISECONDS = 80;

const TDropZone = (props: DropzoneProps) => {
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [isForceDrop, setIsForceDrop] = useState(false);
  const [text, setText] = useState(null);
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState(STATUS_FILE.initial);
  const [error, setError] = useState(INITIAL_ERROR);
  const [file, setFile] = useState(null);

  const MESSAGES = {
    LENGTH: t(MESSAGES_DROPZONE.TEXT_LENGTH),
    FILE: t(MESSAGES_DROPZONE.FILE),
    DROP_TITLE: t("app.validation.length"),
    DROP_DESCRIPTION: t("app.validation.length.description"),
  };

  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptTerms) {
      if (rejectedFiles.length > 0) {
        setStatus(STATUS_FILE.rejected);
        setError({
          title: MESSAGES.DROP_TITLE,
          description: MESSAGES.DROP_DESCRIPTION,
        });
      } else {
        //Call inmediatly the service upload-data
        onProcess(acceptedFiles[0]);
        setFile(acceptedFiles[0]);
        setStatus(STATUS_FILE.loading);
      }
    } else {
      setIsForceDrop(true);
    }
  };

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    disabled: !!file,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    ...RequirementsFile,
  });

  const onHandleText = (value: any) => {
    if (value?.length > 200 && value?.length <= 800) {
      props.uploadText(text);
      localStorage.setItem("processedFile", text);
      localStorage.setItem("typeFile", FilesAvailable.text);
      return true;
    } else {
      setStatus(STATUS_FILE.rejected);
      setError({
        title: "Text Error",
        description: MESSAGES.LENGTH + value.length,
      });
    }
  };

  const onProcess = (file?: File) => {
    if (text) {
      //Only when the user type some text
      onHandleText(text);
      return;
    }
    if (file) {
      //Generate a source blob using the image
      const url = URL.createObjectURL(file);

      props.uploadFile(file);

      const typeFile = file.type.includes("video")
        ? FilesAvailable.video
        : FilesAvailable.image;
      localStorage.setItem("processedFile", url);
      localStorage.setItem("typeFile", typeFile);
    } else {
      setStatus(STATUS_FILE.rejected);
      setError({
        title: "File Error",
        description: MESSAGES.FILE,
      });
    }
  };

  const onAcceptTerms = (e: any) => {
    if (e.target.checked) {
      //Save into localstorage
      setAcceptTerms(e.target.checked);
      localStorage.setItem("acceptTerms", "1");
    }
  };

  const onControlFilesPrevTerms = () => {
    if (acceptTerms) {
      text ? onProcess?.() : open?.();
    } else {
      setIsForceDrop(true);
    }
  };

  useEffect(() => {
    const isEnabled = localStorage.getItem("acceptTerms");
    if (isEnabled === "1") {
      setAcceptTerms(true);
    }
  }, []);

  useEffect(() => {
    if (status === STATUS_FILE.loading) {
      let incrementProgress = 0;

      const intervalId = setInterval(() => {
        incrementProgress++;
        incrementProgress <= TIME_LIMIT_FIRST_SERVICE &&
          setProgress(incrementProgress);
        if (incrementProgress >= TIME_LIMIT_FIRST_SERVICE && !props.loading) {
          clearInterval(intervalId);
          setStatus(STATUS_FILE.finished);
          setProgress(0);
          setFile(null);
        }
      }, TIME_MILISECONDS);

      return () => clearInterval(intervalId);
    }
  }, [status, props.loading]);

  return (
    <div className={`dropzone ${props.className}`}>
      <div className="custom-upload" {...getRootProps()}>
        {isDragActive && (
          <div className="drag-overlay">
            <UploadOutlined style={{ color: "#041E55", fontSize: "2rem" }} />
            <p>Drop your file here</p>
          </div>
        )}
        {progress > 0 && <TProgressContent progress={progress} />}

        <div className="dropzone__body">
          <Space.Compact style={{ width: "100%" }} className="input-container">
            <Input
              onChange={(e) => setText(e.target.value)}
              className={error.title ? "dropzone__body-error" : ""}
              placeholder={t("app.Landing.Banner.Search", "")}
            />
            <Button
              loading={props.loading}
              onClick={onControlFilesPrevTerms}
              style={{ background: "#041e55" }}
              icon={<UploadOutlined className="mr-0" />}
              type="primary"
            >
              <input {...getInputProps()} />
              {t("app.Landing.Banner.Upload", "")}
            </Button>
          </Space.Compact>

          {status === STATUS_FILE.rejected && (
            <Alert
              description={error.description}
              message={error.title}
              type="error"
              showIcon
              closable
              onClose={() => {
                setStatus(STATUS_FILE.initial);
                setError(INITIAL_ERROR);
              }}
            />
          )}
          {!acceptTerms && (
            <Space.Compact
              className={
                isForceDrop ? "checkbox_disabled mt-1" : "terms_condition mt-1"
              }
            >
              {isForceDrop && (
                <p className="text-center m-auto font-medium-2 text-black">
                  {t("app.Landing.Conditions")}
                </p>
              )}
              <Checkbox onChange={onAcceptTerms}>
                <p>{t("app.Landing.Terms")}</p>
              </Checkbox>
            </Space.Compact>
          )}

          <div className={"dropzone__info "}>
            <ul className="mt-2">{TDropZoneItems()}</ul>
          </div>
        </div>
      </div>
    </div>
  );
};

TDropZone.displayName = "TDropZone";

TDropZone.defaultProps = {
  className: "",
};

export default TDropZone;
