import { Reducer } from "redux";
import { ActionType } from "./constants";
import { Actions, LoginState } from "./actions";

const initialState: LoginState = {
  user: {},
};

//Business rules
export const LoginReducer: Reducer<LoginState, Actions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionType.LOGIN_SUCESS_DATA:
      return { ...state, user: action.payload };
    case ActionType.USER_SUCESS_DATA:
      return { ...state, user: { ...state.user, ...action.payload } };
    case ActionType.LOGIN_FAILED_DATA:
      return { ...state, error: "Something error" };
    case ActionType.LOGIN_LOADING_DATA:
      return { ...state, loading: action };
    case ActionType.LOGIN_INITIALIZE_DATA:
      return initialState;
    default:
      return state;
  }
};
