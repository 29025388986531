import React, { useState, useEffect } from "react";

import { Button, Form, Input, Modal, Space, Table } from "antd";
import {
  STATUS_TAKS,
  columns_TABLE,
  getIconFromDevice,
  getSortedByField,
  publickURL,
} from "./Constants";
import "./style.scss";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setInitialPage,
  setMessagePage,
} from "../../configuration/reduxStore/Page/actions";
import {
  API_STATUS,
  API_STATUS_MESSAGE,
  ROUTES_GENERAL,
  TENANT_ID_GENERAL,
} from "../../utilities/Constant";
import { container } from "../../../inject_container/injection";
import { LOGIN_PORT, LoginPort } from "../../../domain/port/inbound/LoginPort";
import { useHistory, useLocation } from "react-router-dom";
import { delete_task } from "../../hooks/usePageVisit";
import { EVENTS_TGM } from "../../utilities/Events";
import FilterSelect from "../FilterSelect";

const { confirm } = Modal;
interface MyReportProps {
  className?: string;
  goDetail: (params, pagination: Number) => void;
  currentPage?: number;
}

interface LocationState {
  currentPage: number;
  from: {
    pathname: string;
  };
}

const TMyReport = (props: MyReportProps) => {
  const { user } = useSelector((state: any) => state.login);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [contentTypeFilter, setContentTypeFilter] = useState([]);
  const location = useLocation<LocationState>();
  //@ts-ignore
  const [currentPage, setCurrentPage] = useState(
    location?.state?.currentPage || 1
  );

  const [tagForm] = Form.useForm();
  const [taskSelected, setTaskSelected] = useState([]);
  const loginRequest = container.get<LoginPort>(LOGIN_PORT);
  const history = useHistory();

  const dispatch = useDispatch();
  const formattedData = (data) => {
    const sortedData = getSortedByField(data, "created_at");
    return sortedData.map((d) => ({
      key: d.id,
      id: d.filename || d.id,
      device: d.content_type,
      icon: getIconFromDevice(d.content_type),
      tag: d.tags,
      created_at: d.created_at,
      field_name: d.filename,
      status: STATUS_TAKS[d.status],
      ai: d.subtasks,
    }));
  };

  const onLoad = async (
    queryParams: URLSearchParams = new URLSearchParams()
  ) => {
    setLoading(true);
    try {
      const response = await fetch(
        publickURL + `/verifai/tasks/${TENANT_ID_GENERAL}?${queryParams}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      );

      if (
        response.status !== API_STATUS.success ||
        response.statusText === API_STATUS_MESSAGE.unauthorized
      ) {
        const response = await loginRequest.refreshToken();
        if (response.status === 200) {
          await onLoad();
        } else {
          onLogout();
        }
        return;
      }
      const data = await response.json();
      setJobs([...formattedData(data)]);
    } catch (e) {
      console.log("🚀 ~ TMyReport ~ e:", e);
    } finally {
      setLoading(false);
    }
  };

  const onLogout = () => {
    loginRequest.logout();
    dispatch(setInitialPage());
    history.push(ROUTES_GENERAL.LOGIN);
  };

  const onDeleteTask = async (taskId, isSingleTask = true) => {
    setLoading(true);
    const response = await fetch(
      publickURL + "/verifai/task/" + TENANT_ID_GENERAL + "/" + taskId,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      }
    );
    if (isSingleTask) {
      if (response.status === 200) {
        delete_task({ page_type: EVENTS_TGM.report, taskId: taskId });
        dispatch(
          setMessagePage({
            type: "success",
            content: "Tu informe " + taskId + " se ha eliminado con éxito",
          })
        );
      } else {
        dispatch(
          setMessagePage({
            type: "error",
            content: "Intente luego",
          })
        );
      }
    }
    setTimeout(() => {
      dispatch(setInitialPage());
    }, 1000);
    setLoading(false);
    onLoad();
  };

  const onTagChange = async (taskId: string, tag: string) => {
    const queryParams = new URLSearchParams();
    queryParams.set("tags", tag.trim());

    const response = await fetch(
      publickURL + `/verifai/task/7/${taskId}?${queryParams}`,
      {
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      }
    );

    if (response.status === 204) {
    }
  };

  const handleBatchTag = (values) => {
    const { tag } = values;
    taskSelected.forEach((key) => {
      onTagChange(key.toString(), tag);
    });
    setLoading(true);
    setTimeout(() => onFilter(), 2000);
    setTaskSelected([]);
  };

  const showEditTag = () => {
    confirm({
      title: "Modificar etiquetas",
      okText: "Aceptar",
      okType: "primary",
      cancelText: "Cancelar",
      onCancel() {},
      onOk: tagForm.submit,
      content: (
        <>
          <Form form={tagForm} onFinish={handleBatchTag}>
            <Form.Item name="tag">
              <Input placeholder="Nueva etiqueta" />
            </Form.Item>
          </Form>
        </>
      ),
    });
  };

  const onFilter = async () => {
    const queryParams = new URLSearchParams();
    if (contentTypeFilter.length > 0)
      queryParams.set("content_type", contentTypeFilter.join(","));
    onLoad(queryParams);
  };

  const onDeleteMultipleTask = () => {
    if (taskSelected.length > 0) {
      taskSelected.forEach((key) => {
        onDeleteTask(key, false);
      });
      dispatch(
        setMessagePage({
          type: "success",
          content: "Tu informes se han eliminado con éxito",
        })
      );
      setTaskSelected([]);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    onFilter();
  }, [contentTypeFilter]);

  return (
    <div className={`MyReport mt-2 mb-1 pt-1 ${props.className}`}>
      <div className="MyReport__filters">
        <FilterSelect onFilter={setContentTypeFilter} />
        <div className="ml-auto">
          {taskSelected.length > 0 && (
            <div className="ml-auto">
              <Space>
                <Button
                  type="dashed"
                  className="dahsed-button ml-1"
                  onClick={() => setTaskSelected([])}
                >
                  Limpiar selección
                </Button>{" "}
                <Button type="primary" onClick={() => showEditTag()}>
                  Editar etiqueta
                </Button>{" "}
                <Button
                  type="primary"
                  className="ml-0"
                  onClick={onDeleteMultipleTask}
                >
                  Eliminar
                </Button>
              </Space>
            </div>
          )}
        </div>
      </div>

      <Table
        loading={loading}
        className="mt-1"
        dataSource={jobs}
        columns={columns_TABLE(onDeleteTask, onTagChange)}
        rowSelection={{
          selectedRowKeys: taskSelected,
          type: "checkbox",
          hideSelectAll: true,
          getCheckboxProps: (record: any) => ({
            // disabled: record.status === STATUS_TAKS[1],
          }),
          onChange: (selectedRowKeys, selectedRows) =>
            setTaskSelected(selectedRowKeys),
        }}
        pagination={{
          current: currentPage,
          onChange: (page) => setCurrentPage(page),
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              props.goDetail(record, currentPage);
            },
            onContextMenu: (event) => {
              event.preventDefault();
              props.goDetail(record, currentPage);
            },
          };
        }}
      />
    </div>
  );
};

TMyReport.displayName = "TMyReport";

TMyReport.defaultProps = {
  className: "",
};

export default TMyReport;
