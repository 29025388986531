import { Reducer } from "redux";
import { ActionType } from "./constants";
import { Actions, PageState } from "./actions";

const initialState: PageState = {
  loading: false,
  error: null,
  message: null,
  user: {},
};

//Business rules
const PageReducer: Reducer<PageState, Actions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionType.Page_SUCESS_DATA:
      return { ...state, loading: false };
    case ActionType.Page_SET_MESSAGE:
      return { ...state, message: action.payload };
    case ActionType.Page_FAILED_DATA:
      return { ...state, error: "Something error" };
    case ActionType.Page_LOADING_DATA:
      return { ...state, loading: action.payload };
    case ActionType.Page_INITIAL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default PageReducer;
