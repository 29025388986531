import { ROUTES_GENERAL } from "../../utilities/Constant";

export const MENU_OPTIONS = [
  {
    id: 1,
    title: "Analysis",
    message: "app.Header.Analysis",
    route: ROUTES_GENERAL.HOME_PAGE,
  },
  {
    id: 2,
    title: "Mis Informes",
    message: "app.Header.Report",
    route: ROUTES_GENERAL.REPORT,
  },
  {
    id: 3,
    title: "How it works",
    message: "app.Header.HowWorks",
    route: ROUTES_GENERAL.HOW_WORKS,
  },
  {
    id: 4,
    title: "Help",
    message: "app.Header.Help",
    route: ROUTES_GENERAL.HELP,
  },
];
