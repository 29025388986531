/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Button, Checkbox, Form, Grid, theme } from "antd";
import { pathServer } from "../../utilities/Function";

import "./style.scss";

const { useToken } = theme;
const { useBreakpoint } = Grid;

export default function VerifyTerms({
  values,
  onSubmit,
  loading = false,
  onBack,
}) {
  const { token } = useToken();
  const screens = useBreakpoint();

  const onFinish = (values) => {
    if (onSubmit) onSubmit(values);
  };

  const styles = {
    container: {
      margin: "0 auto",
      padding: screens.md
        ? `${token.paddingXL}px`
        : `${token.sizeXXL}px ${token.padding}px`,
      width: "350px",
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%",
    },
    forgotPassword: {
      float: "right",
    },

    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      padding: "0 40px",
      width: "max-content",
      margin: "auto",
    },
    text: {
      color: token.colorTextSecondary,
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
    },
  };

  return (
    <div className="login-container">
      <section style={styles.section}>
        <div style={styles.container}>
          <div className="mb-1">
            <img
              src={pathServer.PATH_LOGO + "logo.svg"}
              width={200}
              alt="verifai-logo"
            />
            <p className="mt-1">
              Antes de comenzar, es necesario que revises y aceptes nuestra
              Política de Privacidad y Condiciones Generales. Estos documentos
              son fundamentales para garantizar tu privacidad y establecer las
              reglas de uso de nuestro servicio.
            </p>
          </div>
          <Form
            name="normal_login"
            initialValues={{
              terms_accepted: values.terms_accepted,
              privacy_accepted: values.privacy_accepted,
            }}
            onFinish={onFinish}
            layout="vertical"
            requiredMark="optional"
          >
            <Form.Item style={{ marginBottom: 0 }}>
              <Form.Item name="terms_accepted" valuePropName="checked" noStyle>
                <Checkbox
                  value={values.terms_accepted}
                  defaultChecked={values.terms_accepted}
                >
                  He leído y acepto las{" "}
                  <a
                    target="_blank"
                    href="https://autoverifai.com/condiciones-generales"
                    rel="noreferrer"
                    className="link-button"
                  >
                    {" "}
                    Condiciones Generales
                  </a>
                </Checkbox>
              </Form.Item>
            </Form.Item>
            <Form.Item>
              {" "}
              <Form.Item
                name="privacy_accepted"
                valuePropName="checked"
                noStyle
              >
                <Checkbox
                  value={values.privacy_accepted}
                  defaultChecked={values.privacy_accepted}
                >
                  He leído y acepto la{" "}
                  <a
                    href="https://c892.verifai.tu.com/politica-de-privacidad"
                    target="_blank"
                    rel="noreferrer"
                    className="link-button"
                  >
                    {" "}
                    Política de Privacidad
                  </a>{" "}
                </Checkbox>
              </Form.Item>
            </Form.Item>
            <Form.Item style={{ margin: "auto" }}>
              <Button
                type="default"
                onClick={onBack}
                className="button-terms btn-outlined"
              >
                Rechazar
              </Button>
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                className="button-terms"
              >
                Continuar
              </Button>
            </Form.Item>
          </Form>
        </div>
      </section>
    </div>
  );
}
