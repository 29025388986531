import React, { useContext, useEffect, useState } from "react";
import { withLanding } from "../../hocs/withLanding";
import TResult from "../../components/Result";
import { useHistory, useParams } from "react-router-dom";
import TNavigator from "../../components/Navigator";
import { SearchOutlined } from "@ant-design/icons";

import { WebSocketContext } from "../../context/ws/WebSocketProvider";
import { Button, Result } from "antd";
import { ROUTES_GENERAL } from "../../utilities/Constant";

import { emitter } from "../../services/eventManager";
import { t } from "../../utilities/Message";

import { ReactSVG } from "react-svg";
import { pathServer } from "../../utilities/Function";

import "./styles.scss";

export const TIMEOUT_SESSION = 5000;

const ErrorResponse = ({ error, data }) => {
  if (!error) return;

  if (error)
    return (
      <Result
        className="result-antd pt-1"
        status="500"
        title="Error"
        subTitle={error?.message}
      />
    );

  if (!data?.results?.diffusion?.sucess)
    return (
      <Result
        className="result-antd"
        icon={
          <ReactSVG src={pathServer.PATH_IMG + "result/error_prediction.svg"} />
        }
        title="Ups! Algo no ha funcionado"
        subTitle={
          "Asegúrate de que solamente aparezca una cara en tu contenido y si en un video que se entuentre en los primeros 10s del clip."
        }
      />
    );
};

const ResultPage = () => {
  const { id } = useParams<{ id: string }>();
  const [doRepeat, setDorepeat] = useState(true);
  const { loading, data, error, hasRetry, setData, onCheckResult } =
    useContext(WebSocketContext);

  const history = useHistory();

  const _onClick = () => {
    history.push(ROUTES_GENERAL.HOME_PAGE);
  };

  const onLoadData = async () => {
    if (id) {
      await onCheckResult(id);
    }
  };

  const tryAgain = async () => {
    setTimeout(async () => {
      //@ts-ignore
      // const hasResults = data.results;
      if (id) {
        //@ts-ignore
        await onCheckResult(id);
        setDorepeat(false);
      }
    }, TIMEOUT_SESSION);
  };

  useEffect(() => {
    // Suscríbete al evento "messageReceived"
    const handleReceivedMessage = (data) => {
      setData(data);
      //disable the try again
      setDorepeat(false);
    };

    emitter.on("messageReceived", handleReceivedMessage);
    onLoadData();

    // Unmoun component
    return () => {
      emitter.off("messageReceived", handleReceivedMessage);
    };
  }, []);

  useEffect(() => {
    if (hasRetry) {
      tryAgain();
    }
  }, [hasRetry]);

  return (
    <div className="result-page max-width ">
      <ErrorResponse error={error} data={data} />
      {!error && (
        <>
          <TNavigator
            customBack={() => history.push(ROUTES_GENERAL.HOME_PAGE)}
            page="Analysis"
            withSearch={false}
            className="mt-2 mb-1"
          />

          <TResult className="mt-2" loading={loading} data={data} />
        </>
      )}
      <div className="text-center mt-2">
        <p style={{ fontSize: "1.3rem" }}>{t("app.result.wantToKeep")}</p>
        <Button
          size="large"
          className="mt-2 btn-more"
          icon={<SearchOutlined />}
          type="primary"
          onClick={_onClick}
        >
          {error ? "Try again" : t("app.result.more")}
        </Button>
      </div>
    </div>
  );
};

export default withLanding(ResultPage);
