import React, { useContext, useEffect } from "react";
import { withLanding } from "../../hocs/withLanding";

import TNavigator from "../../components/Navigator";

import "./styles.scss";
import { LanguageContext } from "../../context/i18n/LanguageProvider";

const CookiesPage = () => {
  const { locale } = useContext(LanguageContext);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="how-it-works">
      <TNavigator page="Política de Cookies" withSearch={false} />
      {locale === "es" || true ? (
        <div className="max-width how_content ">
          <p className="mt-1">
            <button id="ot-sdk-btn" className="ot-sdk-show-settings">
              {" "}
              Ajustes de cookies
            </button>
          </p>

          <p className="mt-1"></p>

          <p className="mt-1">
            La/s empresa/s del Grupo Telefónica titular de este portal web,
            conforme se detalla en su Aviso Legal (en adelante, TELEFÓNICA),
            puede utilizar cookies propias y de terceros para distintas
            finalidades. Te informaremos y ofreceremos opciones de configuración
            sobre el uso de cookies tanto en la primera conexión a nuestro
            portal web como en cualquier momento posterior que lo desees
            mediante las opciones que ponemos a tu disposición.
          </p>

          <p className="mt-1"></p>

          <h2
            className="wp-block-heading"
            id="1-que-son-y-como-funcionan-las-cookies"
          >
            1. ¿Qué son y cómo funcionan las cookies?
          </h2>

          <p className="mt-1">
            Las cookies son una herramienta empleada por los servidores web para
            almacenar y recuperar información acerca de sus usuarios visitantes.
            Las cookies podrán tratarse de pequeños archivos de texto que se
            envían a tu ordenador, Tablet o cualquier otro dispositivo que te
            permita navegar por internet cuando accedes a determinadas páginas
            web, u otras tecnologías similares cuya funcionalidad sea la misma.
            Las cookies permiten, entre otras cosas, mantener un registro sobre
            tus hábitos de navegación o de tu equipo, tus preferencias o tu
            información para iniciar sesión y recordarlas a tu regreso.
          </p>

          <h2 className="wp-block-heading" id="2-que-tipos-de-cookies-existen">
            2. ¿Qué tipos de cookies existen?
          </h2>

          <p className="mt-1">
            A continuación, podrás encontrar una clasificación de las cookies
            que existen y pueden llegar a ser utilizadas en nuestras web,
            teniendo en cuenta que una misma cookie puede estar incluida en más
            de una categoría.
          </p>

          <h3
            className="wp-block-heading"
            id="2-1-segun-la-entidad-que-las-gestione"
          >
            2.1. Según la entidad que las gestione
          </h3>

          <p className="mt-1">
            Las cookies podrán ser «propias» o «de terceros».
          </p>

          <p className="mt-1">a) Cookies propias:</p>

          <p className="mt-1">
            Las cookies «propias» son aquellas que se envían al equipo terminal
            del usuario desde un equipo o dominio gestionado por TELEFÓNICA como
            editor del portal web y desde el que se prestan los servicios
            solicitados por el usuario.
          </p>

          <p className="mt-1">b) Cookies de terceros:</p>

          <p className="mt-1">
            Las cookies «de terceros» son aquellas que se envían al equipo
            terminal del usuario desde un equipo o dominio que no es gestionado
            por el editor del portal web, en este caso TELEFÓNICA, sino por otra
            entidad que trata los datos obtenidos través de las cookies.
          </p>

          <h3
            className="wp-block-heading"
            id="2-2-segun-el-tiempo-que-permanecen-activadas"
          >
            2.2. Según el tiempo que permanecen activadas
          </h3>

          <p className="mt-1">
            Las cookies podrán ser “de sesión” o ”persistentes”.
          </p>

          <p className="mt-1">a) Cookies de sesión:</p>

          <p className="mt-1">
            Estas cookies se asignan al dispositivo desde el que el usuario esté
            navegando sólo por la duración de la visita al portal web, dado que
            desaparecen automáticamente cuando el usuario cierra el navegador.
            Se suelen emplear para almacenar información que solo interesa
            conservar para la prestación del servicio solicitado por el usuario
            en una sola ocasión (por ejemplo, una lista de productos adquiridos)
            y desaparecen al terminar la sesión.
          </p>

          <p className="mt-1">b) Cookies persistentes:</p>

          <p className="mt-1">
            Las cookies persistentes implican que los datos siguen almacenados
            en el dispositivo o terminal del usuario utilizado para navegar,
            siendo su duración temporal la mínima imprescindible atendiendo a la
            finalidad de su uso. La duración temporal de las cookies
            persistentes y su fecha de expiración puede ser consultada más
            abajo.
          </p>

          <h3 className="wp-block-heading" id="2-3-segun-su-finalidad">
            2.3. Según su finalidad
          </h3>

          <p className="mt-1">
            Las cookies podrán ser técnicas, de personalización, analíticas o de
            medición, de personalización para publicidad comportamental y de
            redes sociales.
          </p>

          <p className="mt-1">
            a) Cookies estrictamente necesarias o técnicas:
          </p>
          <p className="mt-1">
            Estas cookies permiten al usuario la navegación a través de nuestro
            portal web y la utilización de las diferentes opciones o servicios
            que existen en ella, incluyendo aquellas que TELEFÓNICA utiliza para
            permitir la gestión y operativa de la página web y habilitar sus
            funciones y servicios, como, por ejemplo: controlar el tráfico y la
            comunicación de datos, identificar la sesión, recordar los elementos
            que integran la cesta de la compra, realizar el proceso de compra de
            un pedido, gestionar el pago, controlar el fraude vinculado a la
            seguridad del servicio, utilizar elementos de seguridad durante la
            navegación, almacenar contenidos para la difusión de vídeos o
            sonido, habilitar contenidos dinámicos (por ejemplo, animación de
            carga de un texto o imagen), etc. Además, entre las cookies técnicas
            se incluyen aquellas que permiten recordar información configurada
            por el usuario en el portal web para que el mismo acceda al servicio
            con determinadas características que pueden diferenciar su
            experiencia de la de otros usuarios, como por ejemplo, sección de
            particulares, de autónomos o de empresas, el tipo de navegación y
            configuración regional a través la cual accede, etc. con el fin de
            mantener la experiencia del usuario que accede a nuestra página web.
          </p>

          <p className="mt-1">
            Las cookies técnicas son estrictamente necesarias para proporcionar
            los servicios del portal web y para su correcto funcionamiento, por
            ello no es posible rechazar su uso.
          </p>

          <p className="mt-1">b) Cookies de análisis:</p>

          <p className="mt-1">
            Las cookies analíticas, bien tratadas por nosotros o por terceros,
            son aquellas que permiten a TELEFÓNICA o a dichos terceros realizar
            el seguimiento y análisis del comportamiento agregado de los
            usuarios de la página web. La información recogida mediante este
            tipo de cookies se utiliza en la medición de la actividad del portal
            web, con el fin de introducir mejoras en función del análisis de los
            datos de uso que hacen los usuarios del servicio.
          </p>

          <p className="mt-1">
            Utilizamos las cookies analíticas propias y de terceros indicadas
            más abajo para las finalidades mencionadas anteriormente.
          </p>

          <p className="mt-1">c) Cookies de personalización:</p>
          <p className="mt-1">
            Las cookies de personalización se utilizan para mostrar una
            experiencia personalizada al usuario que visita el portal web,
            utilizando parámetros no seleccionados específicamente por el
            usuario, como por ejemplo información obtenida del dispositivo desde
            el que se navega o información obtenida a través de otras
            interacciones del usuario con TELEFÓNICA (por ejemplo, si eres
            cliente, los productos que tienes contratados). Utilizamos las
            cookies de personalización propias y de terceros indicadas más
            abajo.
          </p>

          <p className="mt-1">d) Cookies de publicidad:</p>
          <p className="mt-1">
            Las cookies de publicidad son aquellas que permiten, a través de
            ciertos datos asignados u obtenidos del dispositivo de un usuario,
            almacenar o compartir con terceros, información del comportamiento
            de los usuarios, obtenida a través de la observación de sus hábitos
            de navegación, lo que permite desarrollar un perfil específico.
          </p>

          <p className="mt-1">
            Utilizamos las cookies propias y/o de terceros señaladas más abajo
            para, a través de la información que reciben de los usuarios de
            nuestro portal web, poder mejorar y optimizar nuestras campañas
            publicitarias en sus plataformas.
          </p>

          <p className="mt-1">e) Cookies de redes sociales:</p>
          <p className="mt-1">
            Estas cookies están configuradas por una serie de servicios de redes
            sociales (ver detalle más abajo) que hemos agregado al portal web
            para permitirte compartir nuestro contenido en dichas redes
            sociales. Los terceros que obtienen información a través de estas
            cookies, pueden utilizarla para hacer seguimiento de tus visitas a
            otros sitios web o crear un perfil de tus intereses para
            personalizar el contenido que se muestra en tus visitas a otras
            páginas web o a la propia red social. Si no permite estas cookies no
            podrá ver o usar estas herramientas para compartir.
          </p>

          <h2
            className="wp-block-heading"
            id="3-que-cookies-utilizamos-en-este-portal-web"
          >
            3. ¿Qué cookies utilizamos en este portal web?
          </h2>

          <div id="ot-sdk-cookie-policy"> </div>

          <h2
            className="wp-block-heading"
            id="4-como-configurar-las-cookies-o-deshabilitarlas"
          >
            4. ¿Cómo configurar las cookies o deshabilitarlas?
          </h2>

          <p className="mt-1">
            Podrás activar o desactivar el uso de cookies directamente desde el
            banner o aviso de cookies y el panel de preferencias asociado que
            aparecerá en tu primera visita a nuestro portal web, así como al
            inicio de este apartado de la Política de Cookies. Si haces clic en
            el botón “Aceptar todas y continuar” del banner, estarás permitiendo
            el uso de todas las cookies. Si no deseas que utilicemos alguna de
            las cookies, podrás seleccionarlo en el panel de configuración,
            haciendo clic en el botón “Configurar / Rechazar cookies” del
            banner.
          </p>

          <p className="mt-1">
            Este panel de configuración estará dividido según las finalidades de
            las cookies utilizadas en cada web, ya que es posible que quieras
            permitir cookies para algunas finalidades, pero no para otras:
          </p>

          <ul className="ul-cookies">
            <li>
              Cookies técnicas (son estrictamente necesarias para el
              funcionamiento o la seguridad del sitio web y no pueden ser
              desactivadas)
            </li>
            <li>Cookies analíticas</li>
            <li>Cookies de personalización</li>
            <li>Cookies publicitarias</li>
            <li>Cookies de redes sociales</li>
          </ul>

          <p className="mt-1">
            Dentro de este panel, podrás habilitar o deshabilitar las cuatro
            últimas secciones (cookies analíticas, de personalización,
            publicitarias y de redes sociales). Si las deshabilitas, dejaremos
            de recoger esos datos y/o enviarlos a los terceros mencionados.
          </p>

          <p className="mt-1">
            Además, puedes permitir, bloquear o eliminar estas cookies a través
            de las opciones de configuración de tu navegador de internet:
          </p>

          <ul className="ul-cookies">
            <li>
              <a
                rel="noreferrer noopener"
                href="https://support.google.com/chrome/answer/95647?hl=es"
                target="_blank"
              >
                Chrome
              </a>
            </li>
            <li>
              <a
                rel="noreferrer noopener"
                href="https://support.microsoft.com/gl-es/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-10"
                target="_blank"
              >
                Internet Explorer
              </a>
            </li>
            <li>
              <a
                rel="noreferrer noopener"
                href="https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-"
                target="_blank"
              >
                Firefox
              </a>
            </li>
            <li>
              <a
                rel="noreferrer noopener"
                href="https://support.apple.com/es-es/HT201265"
                target="_blank"
              >
                Safari
              </a>
            </li>
            <li>
              <a
                rel="noreferrer noopener"
                href="http://support.apple.com/kb/ht1677?viewlocale=es_es"
                target="_blank"
              >
                Safari para IOS (iPhone y iPad)
              </a>
            </li>
            <li>
              <a
                rel="noreferrer noopener"
                href="https://support.google.com/chrome/answer/2392971?hl=es"
                target="_blank"
              >
                Chrome para Android
              </a>
            </li>
          </ul>

          <h2
            className="wp-block-heading"
            id="5-cuanto-tiempo-se-conservan-las-cookies"
          >
            5. ¿Cuánto tiempo se conservan las cookies?
          </h2>

          <p className="mt-1">
            Las cookies serán utilizadas única y exclusivamente durante el
            tiempo necesario para cumplir con las finalidades para las cuales se
            ha indicado en cada cookie. Por todo lo anterior, TELEFÓNICA ha
            adaptado en relación a sus cookies propias los plazos de
            conservación de la información que podrán consultarse en el
            navegador.
          </p>

          <p className="mt-1">
            En relación a las cookies de terceros, los plazos de conservación
            podrán ser consultados en las Políticas de privacidad de dichos
            terceros, según lo recogido en el panel de configuración.
          </p>

          <p className="mt-1">
            Una vez caducada la cookie podrá ser renovada si el usuario vuelve a
            aceptar su utilización.
          </p>

          <h2
            className="wp-block-heading"
            id="6-transferencias-internacionales-de-datos"
          >
            6. Transferencias internacionales de datos
          </h2>

          <p className="mt-1">
            Puedes informarte de las transferencias a terceros países que, en su
            caso, realiza Telefónica o los terceros identificados en esta
            política de cookies en las correspondientes políticas de privacidad.
          </p>

          <h2
            className="wp-block-heading"
            id="7-actualizacion-de-la-politica-de-cookies"
          >
            7. Actualización de la Política de Cookies
          </h2>

          <p className="mt-1">
            TELEFÓNICA podrá actualizar la presente Política de Cookies en
            cualquier momento. Dicha actualización será aplicable desde su
            publicación en el portal web por lo que se aconseja a los usuarios
            que la revisen periódicamente.
          </p>

          <p className="mt-1">
            No obstante lo anterior, la inclusión de cookies nuevas o la
            utilización de cookies ya informadas para finalidades diferentes a
            las indicadas requerirá la aceptación de las mismas de forma previa
            por parte del usuario.
          </p>

          <p className="mt-1"></p>
        </div>
      ) : (
        <div className="max-width how_content ">
          <p className="mt-1">
            <button id="ot-sdk-btn" className="ot-sdk-show-settings">
              {" "}
              Configure cookies
            </button>
          </p>

          <p className="mt-1"> </p>

          <p className="mt-1">
            The Telefónica Group’s company owner of this website, as detailed in
            its Legal Notice (hereinafter, TELEFÓNICA), uses first and
            third-party cookies for different purposes. We will inform you and
            offer configuration options about the use of cookies both in the
            first connection to our website and at any later time that you might
            need through the options at your disposal.
          </p>

          <h2 className="wp-block-heading">
            1. What are cookies and how do they work?
          </h2>

          <p className="mt-1">
            Cookies are a tool used by web servers to store and retrieve
            information about their visiting users. Cookies may be small text
            files that are sent to your computer, tablet or any other device
            that allows you to browse the internet when you access certain web
            pages, or other similar technologies whose functionality is the
            same. Cookies allow, among other things, to keep a record of your
            browsing habits, your preferences or your information to log in and
            remember them when you return.
          </p>

          <h2 className="wp-block-heading">2. What types of cookies exist?</h2>

          <p className="mt-1">
            Below, you can find a classification of the cookies we might use.
            Take into account that the same cookie may be included in more than
            one category.
          </p>

          <h3 className="wp-block-heading">
            2.1. Cookie categories according to the responsible entity:
          </h3>

          <p className="mt-1">
            Cookies may be &#8220;first-party&#8221; or &#8220;third
            party&#8221;.
          </p>

          <p className="mt-1">a) First-party cookies:</p>

          <p className="mt-1">
            &#8220;First-Party&#8221; cookies are those that are sent to the
            user&#8217;s terminal equipment from a computer or domain managed by
            TELEFÓNICA as the editor of the web portal and from which the
            services requested by the user are provided.
          </p>

          <p className="mt-1">b) Third-party cookies:</p>

          <p className="mt-1">
            &#8220;Third-party&#8221; cookies are those that are sent to the
            user&#8217;s terminal equipment from a computer or domain that is
            not managed by the editor of the web portal, in this case
            TELEFÓNICA, but by another entity that processes the data obtained
            through cookies.
          </p>

          <h3 className="wp-block-heading">
            2.2. Cookie categories according to the time they remain activated:
          </h3>

          <p className="mt-1">
            Cookies may be &#8220;session&#8221; or &#8220;persistent&#8221;.
          </p>

          <p className="mt-1">a) Session cookies:</p>

          <p className="mt-1">
            These cookies are assigned to the device from which the user is
            browsing only for the duration of the visit to the web portal, since
            they disappear automatically when the user closes the browser. They
            are usually used to store information that is necessary the
            provision of the service requested by the user on a single occasion
            (for example, a list of products purchased) and disappear at the end
            of the session.
          </p>

          <p className="mt-1">b) Persistent cookies:</p>

          <p className="mt-1">
            Persistent cookies imply that the data continues to be stored on the
            device or terminal of the user, being its temporary duration the
            minimum essential depending on the purpose of its use. The temporary
            duration of persistent cookies we use and their expiration date can
            be consulted below.
          </p>

          <h3 className="wp-block-heading">
            2.3. Cookie categories according to its purpose:
          </h3>

          <p className="mt-1">a) Technical Cookies:</p>

          <p className="mt-1">
            These cookies allow the user to navigate through our website and use
            its different options and services, including those that Telefónica
            uses to manage and operate the website, such as for example,
            identifying user’s session, remembering the elements that make up
            the shopping cart, managing payments, storing content for
            broadcasting video or audio, etc. In addition, technical cookies
            include those that allow the website to remember information
            configured by the user so that he or she accesses the service with
            certain characteristics that can differentiate their experience from
            that of other users, such as the language selected by the user, the
            type of navigation and regional configuration through which it
            accesses, etc. Technical cookies are strictly necessary to provide
            the services of the website, as well as for its proper functioning,
            therefore it is not possible to reject their use.
          </p>

          <p className="mt-1">b) Analytics cookies:</p>

          <p className="mt-1">
            Analytics cookies allows Telefónica or third parties to monitor and
            analyze the aggregate behavior of users in the website. The
            information collected through this type of cookies is used to
            measure website’s activity, in order to introduce improvements based
            on the analysis of such information. We use analytical cookies as
            indicated below, mainly using Google Analytics services and tools,
            for the purposes mentioned above.
          </p>

          <p className="mt-1">c) Personalization cookies:</p>

          <p className="mt-1">
            Personalization cookies are used to show a customized experience to
            website visitors, using parameters not specifically selected by the
            user such as information obtained from the device from which the
            user is browsing, or information obtained through other interactions
            of the user with Telefónica (for example, if you are a customer, the
            products you have purchased). We use first- and third-party
            personalization cookies as indicated below.
          </p>

          <p className="mt-1">d) Advertising cookies:</p>

          <p className="mt-1">
            Advertising cookies allow us, through certain data assigned or
            obtained from a user&#8217;s device, to store or share with third
            parties, information related to user’s behavior in our website, in
            order to create an advertising profile. We use first party and
            third-party advertising cookies as indicated below.
          </p>

          <p className="mt-1">e) Social Media Cookies:</p>

          <p className="mt-1">
            These cookies are set by a series of social media services [Third
            parties e.g. Facebook, LinkedIn, Google] which we might have
            embedded to the website in order to allow you to share our content
            on these social networks. Third parties obtaining information
            through these cookies might use it to track your visits to other
            websites or create a profile of your interests to personalize the
            content that is displayed on your visits to other websites or to the
            social networks. If you do not allow these cookies, you might not be
            able to view or use social sharing tools. We use these cookies as
            indicated below.
          </p>

          <h2 className="wp-block-heading">
            3. Which specific cookies do we use in this website?
          </h2>

          <div id="ot-sdk-cookie-policy"> </div>

          <h2 className="wp-block-heading">
            4. How to configure or reject cookies?
          </h2>

          <p className="mt-1">
            You can activate or deactivate the use of cookies directly from the
            banner or cookie notice and the associated preferences panel that
            will appear on your first visit to our website, as well as at any
            time at the beginning of this Cookies Policy. If you click on
            &#8220;Accept all and continue&#8221; on the banner, you will be
            allowing the use of all cookies. If you do not want us to use any of
            the cookies, you can select it in the configuration panel, by
            clicking &#8220;Configure / Reject cookies&#8221; on the banner.
          </p>

          <p className="mt-1">
            This configuration panel will be divided according to the purposes
            of cookies used in each website, since you may want to allow cookies
            for some purposes, but not for others:
          </p>

          <ul className="ul-cookies">
            <li>
              Technical cookies (These are strictly necessary for the operation
              and security of the website and cannot be deactivated)
            </li>
            <li>Analytics cookies</li>
            <li>Personalization cookies</li>
            <li>Advertising cookies</li>
            <li>Social Media cookies</li>
          </ul>

          <p className="mt-1">
            Within this panel, you will be able to enable or disable all
            non-strictly necessary cookies (analytical, personalization,
            advertising and social media cookies). If you disable them, we will
            stop collecting this data (or we will not collect any, If you do not
            consent in the first visit) and / or sending it to the
            aforementioned third parties.
          </p>

          <p className="mt-1">
            In addition, you can allow, block or delete these cookies through
            the configuration options of your internet browser:
          </p>

          <ul className="ul-cookies">
            <li>
              <a
                href="https://support.google.com/chrome/answer/95647?hl=es"
                target="_blank"
                rel="noreferrer noopener"
              >
                Chrome
              </a>
            </li>
            <li>
              <a
                href="https://support.microsoft.com/gl-es/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-10"
                target="_blank"
                rel="noreferrer noopener"
              >
                Internet Explorer
              </a>
            </li>
            <li>
              <a
                href="https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-"
                target="_blank"
                rel="noreferrer noopener"
              >
                Firefox
              </a>
            </li>
            <li>
              <a
                href="https://support.apple.com/es-es/HT201265"
                target="_blank"
                rel="noreferrer noopener"
              >
                Safari
              </a>
            </li>
            <li>
              <a
                href="http://support.apple.com/kb/ht1677?viewlocale=es_es"
                target="_blank"
                rel="noreferrer noopener"
              >
                Safari for IOS (iPhone y iPad)
              </a>
            </li>
            <li>
              <a
                href="https://support.google.com/chrome/answer/2392971?hl=es"
                target="_blank"
                rel="noreferrer noopener"
              >
                Chrome for Android
              </a>
            </li>
          </ul>

          <h2 className="wp-block-heading">
            5. How long do cookies last on your computer?
          </h2>

          <p className="mt-1">
            Cookies will be used solely and exclusively for the time necessary
            to fulfill the purposes for which it has been indicated in each
            cookie. You can find out each cookie storage period in section 3 of
            this document{" "}
          </p>

          <p className="mt-1">
            Once the cookie has expired, it can be renewed if the user accepts
            its use again.
          </p>

          <h2 className="wp-block-heading">6. International data transfers</h2>

          <p className="mt-1">
            You can find out more about transfers to third countries that, where
            applicable, might be made by Telefónica or the third parties
            identified in this cookie policy, in their corresponding privacy
            policies.
          </p>

          <h2 className="wp-block-heading">7. Update of the Cookies Policy</h2>

          <p className="mt-1">
            TELEFÓNICA may update this Cookies Policy at any time. This update
            will be applicable from its publication on the website so users are
            advised to review it periodically.
          </p>

          <p className="M75B">
            Notwithstanding the foregoing, the inclusion of new cookies or the
            use of cookies already informed for purposes other than those
            indicated will require their prior acceptance by the user.
          </p>
        </div>
      )}
    </div>
  );
};

export default withLanding(CookiesPage);
