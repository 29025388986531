import { ActionType } from "./constants";

// Types

export type PageState = {
  loading: boolean;
  error?: any;
  message?: any;
  user?: object;
};

interface SuccessAction {
  type: ActionType.Page_SUCESS_DATA;
}

interface LoadingAction {
  type: ActionType.Page_LOADING_DATA;
  payload: any;
}

interface SetMessageAction {
  type: ActionType.Page_SET_MESSAGE;
  payload: any;
}

interface FailedAction {
  type: ActionType.Page_FAILED_DATA;
}

interface InitialAction {
  type: ActionType.Page_INITIAL_DATA;
}

export type Actions =
  | SuccessAction
  | LoadingAction
  | FailedAction
  | SetMessageAction
  | InitialAction;

// Action Creators

export const success = (): SuccessAction => ({
  type: ActionType.Page_SUCESS_DATA,
});

export const setLoadingPage = (value: any): LoadingAction => ({
  type: ActionType.Page_LOADING_DATA,
  payload: value,
});

export const setMessagePage = (value: any): SetMessageAction => ({
  type: ActionType.Page_SET_MESSAGE,
  payload: value,
});

export const failed = (): FailedAction => ({
  type: ActionType.Page_FAILED_DATA,
});

export const setInitialPage = (): InitialAction => ({
  type: ActionType.Page_INITIAL_DATA,
});
