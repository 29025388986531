import React, { useState, FC, ReactNode } from "react";

import { VERIFAI_CLIENTS } from "./Constants";

type ProviderProps = {
  children: ReactNode;
};

export const ThemeContext = React.createContext<{
  mode: string;
  setMode: (locale: string) => void;
}>({ mode: VERIFAI_CLIENTS.verifai, setMode: () => {} });

export const ThemeProvider: FC<ProviderProps> = ({ children }: any) => {
  const [mode, setMode] = useState<string>(VERIFAI_CLIENTS.police);

  const onSelectMode = (value: string) => {
    setMode(value);
  };

  return React.createElement(
    ThemeContext.Provider,
    { value: { mode, setMode: onSelectMode } },
    children
  );
};
