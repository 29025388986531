import mitt from "mitt";

export const emitter = mitt();

// export const useWebSocketEvents = (
//   eventName: string,
//   handler: (data: any) => void
// ) => {
//   useEffect(() => {
//     emitter.on(eventName, handler);

//     return () => {
//       emitter.off(eventName, handler);
//     };
//   }, [eventName, handler]);
// };

export const sendMessageReceived = (data: any) => {
  emitter.emit("messageReceived", data);
};
