import React, { useContext } from "react";
import { LanguageContext } from "../../context/i18n/LanguageProvider";

import "./style.scss";

interface LanguageSelectorProps {
  className?: string;
}

const TLanguageSelector = (props: LanguageSelectorProps) => {
  const { locale, setLocale } = useContext(LanguageContext);

  const __onHandle = (language: string) => {
    setLocale(language);
  };

  return (
    <div className={`LanguageSelector ${props.className}`}>
      <p
        onClick={() => __onHandle("en")}
        className={locale === "en" ? " selected " : " "}
      >
        EN{"  |  "}
      </p>
      <p
        onClick={() => __onHandle("es")}
        className={(locale === "es" ? " selected " : " ") + "ml-0 "}
      >
        ES
      </p>
    </div>
  );
};

TLanguageSelector.displayName = "TLanguageSelector";

TLanguageSelector.defaultProps = {
  className: "",
};

export default TLanguageSelector;
