import { useState } from "react";

const publicUrl = `${process.env.REACT_APP_BASE_URL}/api/pub`;

export const useLatch = () => {
  const [loading, setLoading] = useState(false);
  const pairLatch = async (token) => {
    let myHeaders;
    myHeaders = {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    };

    try {
      setLoading(true);
      let response = await fetch(
        publicUrl + "/latch/pair?token_pairing=" + token,
        {
          method: "GET",
          headers: myHeaders,
        }
      );

      const data = await response.json();
      return data;
    } catch (e) {
      console.log("Error", e);
    } finally {
      setLoading(false);
    }
  };

  const unPairLatch = async () => {
    let myHeaders;
    myHeaders = {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    };

    try {
      setLoading(true);
      let response = await fetch(publicUrl + "/latch/unpair", {
        method: "GET",
        headers: myHeaders,
      });

      const data = await response.json();
      return data;
    } catch (e) {
      console.log("Error", e);
    } finally {
      setLoading(false);
    }
  };

  return [loading, pairLatch, unPairLatch] as const;
};
