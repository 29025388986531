import React, { createContext, useState, ReactNode, useEffect } from "react";

import { container } from "../../../inject_container/injection";
import { IMAGE_PORT, ImagePort } from "../../../domain/port/inbound/ImagePort";
import { ImageResponse } from "../../../domain/entities/Photo";
import { ApiRespone } from "../../../domain/entities/Api";

type WebSocketContextType = {
  isConnected: any;
  uploadData: (params) => Promise<any>;
  uploadText: (params: string) => Promise<string | ImageResponse>;
  onCheckResult: (params: string) => void;
  data: ApiRespone;
  setData: React.Dispatch<React.SetStateAction<ApiRespone>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  error: any;
  setError: any;
  hasRetry: boolean;
};

type ProviderProps = {
  children: ReactNode;
};

export const WebSocketContext = createContext<WebSocketContextType | undefined>(
  undefined
);

export const WebSocketProvider: React.FC<ProviderProps> = ({
  children,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [isConnected] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const [hasRetry, setHasRetry] = useState(false);
  const [data, setData] = useState({});

  const imageRequest = container.get<ImagePort>(IMAGE_PORT);

  const uploadData = async (params) => {
    setLoading(true);
    try {
      const response = await imageRequest.analyzeCOPImage(params);
      return response;
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const uploadText = async (params: string) => {
    let url = "";
    setLoading(true);
    try {
      await onStartConnection();
      const response = await imageRequest.analyzeText(params);
      url = response?.hash;

      if (response) {
        const payload: ApiRespone = {
          results: response,
        };

        setData(payload);
        onCloseConnection();
        return url;
      }
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }

    return;
  };

  const onCheckResult = async (params: string) => {
    setLoading(true);
    try {
      try {
        await onStartConnection();
      } catch (e) {
        setError(e);
      }
      const response = await imageRequest.checkResult(params);
      //TODO: Some algorithmic is not completed
      if (response?.error) {
        // setError(response?.error);
        setHasRetry(true); // call again the service after a time
        return;
      }

      if (response) {
        const payload: ApiRespone = {
          results: response,
        };

        setData(payload);
        setLoading(false);

        return response;
      }
    } catch (e) {
      setError(e);
      setHasRetry(true);
    }
    return;
  };

  /**
   * Start the connection : receive the auth token by fetch
   */
  const onStartConnection = async () => {
    try {
      return await imageRequest.connected();
    } catch (e) {
      return;
    }
  };

  /**
   * Close the connection : receive the auth token by fetch
   */
  const onCloseConnection = async () => {
    try {
      return await imageRequest.closeConnection();
    } catch (e) {
      return;
    }
  };

  useEffect(() => {
    return () => {
      onCloseConnection();
    };
  }, []);

  return React.createElement(WebSocketContext.Provider, {
    value: {
      isConnected,
      uploadData,
      uploadText,
      data,
      setData,
      loading,
      setLoading,
      error,
      setError,
      onCheckResult,
      hasRetry,
    },
    children,
  });
};
