import React, { useContext, useEffect } from "react";
import { WebSocketContext } from "../../context/ws/WebSocketProvider";

import { withLanding } from "../../hocs/withLanding";

import TBanner from "../../components/Banner";
import TService from "../../components/Service";
import THero from "../../components/Hero";
import TCarousel from "../../components/Carousel";
import { t } from "../../utilities/Message";

import TComingSoon from "../../components/ComingSoon";

import "./styles.scss";
import { useDispatch } from "react-redux";
import {
  setInitialPage,
  setLoadingPage,
  setMessagePage,
} from "../../configuration/reduxStore/Page/actions";
import { API_STATUS, ROUTES_GENERAL } from "../../utilities/Constant";
import { Link } from "react-router-dom";
import { setLoadingLogin } from "../../configuration/reduxStore/Login/actions";
import { page_view } from "../../hooks/usePageVisit";
import { EVENTS_TGM } from "../../utilities/Events";
import { LanguageContext } from "../../context/i18n/LanguageProvider";

const HomePage = () => {
  const { uploadData, uploadText, loading, error } =
    useContext(WebSocketContext);
  const dispatch = useDispatch();
  const { locale } = useContext(LanguageContext);

  const HeroProps = {
    title: t("app.Landing.Hero.Title"),
    description: t("app.Landing.Hero.Description"),
    image: "images/elevator_pitch.png",
    footer: t("app.Landing.Hero.Footer"),
  };

  const renderMessage = (
    <div className="antd-message-txt ml-0">
      <strong className="font-bold">
        Tus archivos se ha subido con éxito. Cuando termine de analizarse podras
        verlo en{" "}
        <Link
          to={ROUTES_GENERAL.REPORT}
          className="font-bold"
          style={{ color: "#4343FF", fontWeight: "bold" }}
        >
          Mis informes.
        </Link>
      </strong>
      <strong className="d-block text-left">
        Por favor, sé paciente. Llevará solo unos minutos.
      </strong>
    </div>
  );

  const uploadMultipleFile = async (params) => {
    try {
      const { isLast } = params;
      const { error: errorMessage, status } = await uploadData(params);
      const isFailed = status === API_STATUS.failed;

      if (isLast) {
        dispatch(setLoadingLogin(false));
        dispatch(setLoadingPage(false));
        dispatch(
          setMessagePage({
            type: isFailed ? "error" : "success",
            content: isFailed ? errorMessage : renderMessage,
          })
        );
        setTimeout(() => {
          dispatch(setInitialPage());
        }, 2000);
        return Promise.resolve(API_STATUS.created);
      }
      return Promise.resolve(API_STATUS.success);
    } catch (e) {
    } finally {
    }
  };

  const uploadTextService = async (params: string) => {
    //Load the service , uploading in process
    try {
      const url = await uploadText(params);
      if (url) {
        //Redirect to results page
        // history.push(ROUTES_GENERAL.RESULT + "/" + url);
      }
    } catch (e) {}
  };

  useEffect(() => {
    page_view({ page_type: EVENTS_TGM.home, locale });
  }, []);

  return (
    <div className="home-page">
      <TBanner
        uploadFile={uploadMultipleFile}
        uploadText={uploadTextService}
        loading={loading}
      />
      <THero {...HeroProps} />
      <TCarousel />
      <TService />
      <TComingSoon />
    </div>
  );
};

export default withLanding(HomePage);
