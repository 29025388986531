import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Routes from "./Routes";
import { ROUTES_GENERAL } from "../../utilities/Constant";
import { HomePage } from "../../pages";
import { useSelector } from "react-redux";
import { message } from "antd";
import { setInitialPage } from "../reduxStore/Page/actions";
import { useDispatch } from "react-redux";
const AuthGuard = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={ROUTES_GENERAL.LOGIN} />
        )
      }
    />
  );
};

function TRouter() {
  const [messageApi, contextHolder] = message.useMessage();
  const { user } = useSelector((state: any) => state?.login);
  const { message: messageState } = useSelector((state: any) => state.page);
  const dispatch = useDispatch();
  const isAuthenticated = !!user?.access_token;
  useEffect(() => {
    if (messageState) {
      message.config({
        duration: 2,
        maxCount: 2,
      });
      messageApi.destroy();
      messageApi.open({ ...messageState, duration: 2 });
    }
  }, [messageState, messageApi]);

  //Clean old messages TODO: move to a hook
  useEffect(() => {
    dispatch(setInitialPage());
  }, []);

  return (
    <Router>
      {contextHolder}
      <Switch>
        <AuthGuard
          path={ROUTES_GENERAL.HOME_PAGE}
          component={HomePage}
          exact={true}
          isAuthenticated={isAuthenticated}
        />
        {Routes.map((route: any) => (
          <Route
            key={route.path}
            path={route.path}
            component={route.component}
            exact={route?.exact}
          />
        ))}
      </Switch>
    </Router>
  );
}

export default TRouter;
